import template from './system-template-general-form.component.html';
const styles = require('./system-template-general-form.component.scss');

import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { SystemContentTemplate } from '@proftit/crm.api.models.entities';
import { useStreams } from '@proftit/rxjs.adjunct';
import { PlatformType } from '@proftit/crm.api.models.entities/src';

export class SystemTemplateGeneralFormController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  model$ = observeShareCompChange<SystemContentTemplate>(
    this.lifecycles.onChanges$,
    'model',
  );

  brands$ = new rx.BehaviorSubject(null);

  platformType: PlatformType;

  /* @ngInject */
  constructor() {
    useStreams(
      [this.model$, this.streamModelBrand()],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamModelBrand() {
    return rx.obs.combineLatest([this.model$, this.brands$]).pipe(
      rx.filter((obs) => obs[0] && obs[1]),
      rx.tap(([model, brand]) => {
        let [currentBrand] = brand.filter(
          (brand) => brand.id === model.system.brand.id,
        );
        this.platformType = currentBrand.platformType;
      }),
    );
  }
}

export const SystemTemplateGeneralFormComponent = {
  template,
  controller: SystemTemplateGeneralFormController,
  bindings: {
    model: '<',
    platformType: '=',
  },
};
