import { IPromise } from 'angular';
import RestService from '~/source/common/services/rest';

import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { IFieldsAndModulesService } from '~/source/management/fields-and-modules/i-fields-and-modules-service';
import { id } from 'date-fns/locale';
import Promise from 'bluebird';

import { undefined } from 'zod';
import {
  ChallengeGroup,
  ChallengeGroupCreate,
} from '@proftit/crm.api.models.entities/src';

const SERVICE_RESOURCE = 'brands';
const CHALLENGE_GROUPS_RESOURCE = 'challengeGroups';

export class BrandChallengesGroupsService extends RestService
  implements IFieldsAndModulesService {
  /**
   *  Return resource challenges.service.ts
   *
   * @returns {string}
   */

  get resource() {
    return SERVICE_RESOURCE;
  }

  getBrandChallengeGroupsResource(id: number) {
    return this.resourceBuildStart()
      .getElement(id)
      .getNestedCollection(CHALLENGE_GROUPS_RESOURCE)
      .resourceBuildEnd();
  }

  getChallengeGroupsByBrandId(brandId: number) {
    return this.getBrandChallengeGroupsResource(brandId)
      .embed(['challenges', 'challenges.phases'])
      .getListWithQuery();
  }

  createChallengeGroup(brandId: number, challengeGroup: ChallengeGroupCreate) {
    return this.getBrandChallengeGroupsResource(brandId).postWithQuery(
      challengeGroup,
    );
  }

  deleteChallengeGroup(brandId: number, challengeGroupId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(CHALLENGE_GROUPS_RESOURCE, challengeGroupId)
      .resourceBuildEnd()
      .removeWithQuery();
  }

  getBrandsByPlatformType(platformTypeId: number) {
    return this.resourceBuildStart()
      .filter('platformTypeId', platformTypeId)
      .getListWithQuery();
  }

  getChallengesGroupById(id: number, challengeGroupId: number) {
    return this.resourceBuildStart()
      .getElement(id)
      .getNestedElement(CHALLENGE_GROUPS_RESOURCE, challengeGroupId)
      .embed(['challenges', 'challenges.phases'])
      .expand(['challenges.currency'])
      .resourceBuildEnd()
      .getOneWithQuery();
  }

  updateChallengeGroup(
    brandId: number,
    challengeGroupId: number,
    challengeGroup: ChallengeGroup,
  ) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(CHALLENGE_GROUPS_RESOURCE, challengeGroupId)
      .resourceBuildEnd()
      .patchWithQuery(challengeGroup);
  }

  updateIsDefault(modelId: number, isDefault: boolean): Promise<any> {
    return Promise.resolve(undefined);
  }
}

export default BrandChallengesGroupsService;
