import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { StateService, StateParams } from '@uirouter/core';
import * as rx from '@proftit/rxjs';

import template from './challenge-assets.component.html';
import { ChallengeAsset } from '@proftit/crm.api.models.entities/src';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';

const styles = require('./challenge-assets.component.scss');

export class ChallengeAssetsController {
  styles = styles;
  model: ChallengeAsset[];
  lifecycles = observeComponentLifecycles(this);
  onUpdate: () => void;
  options: ChallengeAsset[];

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  /**
   * Selects all available options.
   * This method assigns all options to the model, effectively selecting them all,
   * and then triggers the onUpdate callback to reflect the changes.
   */
  selectAll() {
    this.model = [...this.options];
    this.onUpdate();
  }

  /**
   * Clears all selections.
   * This method empties the model array, removing all selections,
   * and then triggers the onUpdate callback to reflect the changes.
   */
  clearAll() {
    this.model = [];
    this.onUpdate();
  }
}

export const ChallengeAssetsComponent = {
  controller: ChallengeAssetsController,
  template: template,
  bindings: {
    label: '<',
    model: '=',
    options: '<',
    onUpdate: '&',
  },
};
