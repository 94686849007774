import * as rx from '@proftit/rxjs';
import template from './prop-phase-stats-table.componet.html';
import {
  ChallengePhase,
  Customer,
  TradingAccount,
} from '@proftit/crm.api.models.entities';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { IScope } from 'angular';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { CalculationMethod } from '@proftit/crm.api.models.entities/src';
import popupService from '~/source/common/components/modal/popup.service';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import CustomersService from '~/source/contact/common/services/customers';

const styles = require('./prop-phase-stats-table.componet.scss');

type PhaseStats = {
  name: string;
  startedAt: string;
  startingBalance: string;
  status: string;
  profitTarget: string;
  maxLoss: string;
  maxDailyLoss: string;
  minTradingPeriod: string;
  tradingPeriod: string;
  isFunded: boolean;
  currentProfit?: string;
  profitBroker?: string;
  profitTrader?: string;
  prizeValue?: string;
  isSplitPrize?: boolean;
  tradingAccount?: IElementRestNg<TradingAccount>;
};

type ChangePhaseFields<T, R> = Omit<T, keyof R> & R;
type ReceivedChallengePhase = ChangePhaseFields<
  ChallengePhase,
  {
    staringBalance: number;
    fields: {
      profitTarget: { value: number };
      maxLoss: { value: number };
      maxDailyLoss: { value: number };
      minTradingPeriod: number;
      tradingPeriod: number;
      prizeAmount: {
        value: number;
        calculationType: CalculationMethod;
      };
      profitSplit: {
        value: string;
        calculationType: CalculationMethod;
      };
    };
    currencyName: string;
    isSplitPrize: boolean;
    tradingAccount: IElementRestNg<TradingAccount>;
  }
>;

type HistoryLogRow = {
  createdAt: string;
  brokerShare: number;
  traderShare: number;
  profit: number;
  withdrawableBalance: number;
  traderWithdrawal: number;
  brokerFee: number;
  previousBalance: number;
  newBalance: number;
};

export class PropPhaseStatsTableController {
  styles = styles;
  phases: ReceivedChallengePhase[];
  account: IElementRestNg<Customer>;
  customer: IElementRestNg<TradingAccount>;
  lifecycles = observeComponentLifecycles(this);
  phaseStats$ = this.streamStats();
  isHistoryLog: boolean = false;
  historyLog: HistoryLogRow[] = [];

  constructor(
    readonly $scope: IScope,
    readonly popupService: popupService,
    readonly customersService: () => CustomersService,
  ) {
    useStreams(
      [this.phaseStats$, this.streamProfitShareHistoryLog()],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamStats() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      rx.map(() => this.phases),
      rx.filter((phases) => !!phases),
      rx.switchMap(() => this.fetchPhaseStats()),
      shareReplayRefOne(),
    )(null);
  }

  fetchPhaseStats(): Promise<PhaseStats[]> {
    const stats = this.phases.map((phase) => this.getDemoPhaseStats(phase));
    return Promise.resolve(stats);
  }

  getDemoPhaseStats(phase: ReceivedChallengePhase): PhaseStats {
    const { currencyName = 'USD', staringBalance = 1000, fields } = phase;
    return {
      name: phase.name,
      startedAt: phase.startedAt
        ? phase.startedAt
        : new Date(phase.startedAt).toDateString(),
      status: phase.status,
      isFunded: phase.isFunded,
      startingBalance: ` ${currencyName} ${staringBalance}`,
      profitTarget: fields.profitTarget?.value
        ? ` ${currencyName} ${fields.profitTarget.value}`
        : 'N/A',
      maxLoss: fields.maxLoss?.value
        ? ` ${currencyName} ${fields.maxLoss.value}`
        : 'N/A',
      maxDailyLoss: fields.maxDailyLoss?.value
        ? ` ${currencyName} ${fields.maxDailyLoss.value}`
        : 'N/A',
      minTradingPeriod: fields.minTradingPeriod
        ? ` ${fields.minTradingPeriod} ${
            fields.minTradingPeriod === 1 ? 'day' : 'days'
          }`
        : 'N/A',
      tradingPeriod: fields.tradingPeriod
        ? ` ${fields.tradingPeriod} ${
            fields.tradingPeriod === 1 ? 'day' : 'days'
          }`
        : 'N/A',
      currentProfit: `${currencyName} 1000`,
      profitBroker: `${currencyName} 350`,
      profitTrader: `${currencyName} 650`,
      prizeValue: fields.prizeAmount?.value ? ` ${currencyName} 300` : 'N/A',
      isSplitPrize: !!fields.profitSplit,
      tradingAccount: phase.tradingAccount,
    };
  }

  /**
   * Opens the "profit share history" modal
   * @returns {void}
   */
  openAddProfitShareHistoryPopup() {
    this.popupService.open({
      component: 'prfProfitShareHistoryPopup',
      resolve: {
        profitShareHistoryLog: () => this.historyLog,
      },
    });
  }

  streamProfitShareHistoryLog() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(
        (): rx.Observable<HistoryLogRow[]> => {
          return rx.obs
            .from(this.fetchProfitShareLog())
            .pipe(rx.catchError((e) => rx.obs.NEVER));
        },
      ),
      rx.tap((data) => {
        if (data.length > 0) {
          this.isHistoryLog = true;
          this.historyLog = data;
        }
      }),
    )(null);
  }

  async fetchProfitShareLog(): Promise<[HistoryLogRow]> {
    const data = await this.customersService().getAccountProfitShareHistory(
      this.customer.id,
      this.account.id,
    );
    return data.plain();
  }
}

export const PropPhaseStatsTableComponent = {
  template,
  controller: PropPhaseStatsTableController,
  bindings: {
    phases: '<',
    customer: '<',
    account: '<',
  },
};
