import log from 'loglevel';
import {
  UserSettingsKeyCode,
  UserFilterTypeCode,
  MT4_PLATFORMS,
  PlatformTypeCode,
} from '@proftit/crm.api.models.enums';
import template from './portfolio-dashboard.html';
import * as _ from '@proftit/lodash';
import { dashboardSettings } from '../portfolio-table/dashboard-settings';
import { portfolioPositionsTableSettings } from '../portfolio-positions-table/portfolio-positions-table.settings';
import * as rx from '@proftit/rxjs';
import { BrandsPnlStatsSocketService } from '~/source/common/services/brands-pnl-stats-socket.service';
import {
  Mt4BrandStatsSocketService,
  buildMt4BrandsStatsChannel,
} from '~/source/common/services/mt4-brand-stats-socket.service';
import { BundleStatsSocketService } from '~/source/common/services/bundle-stats-socket.service';
import { BrandsService } from '~/source/management/brand/services/brands';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { CurrencyRatesService } from '~/source/common/services/currency-rates.service';
import { BrandPnlStats } from '~/source/common/models/brand-pnl-stats';
import { BrandMt4PnlStats } from '~/source/common/models/brand-mt4-stats';
import { CurrencyRate } from '~/source/common/models/currency-rate';
import { CfdPlatformBrandsService } from '~/source/management/integrations/risk-manager/services/cfd-platform-brands.service';
import { IHttpService } from 'angular';
import { PlatformConnection } from '~/source/common/models/platform-connection';
import UserSettingsService from '~/source/common/services/user-settings';
import { Brand, Platform } from '@proftit/crm.api.models.entities';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { UserBrandPlatformSession } from '~/source/common/models/user-brand-platform-session';
import { PlatformSessionInfo } from '~/source/common/service-directives/platform-session-info';
import { PlatformTypeOption } from '@proftit/crm.api.models.entities';

const styles = require('./portfolio-dashboard.scss');

interface Stats {
  totalBalance: number;
  totalEquity: number;
  totalOpenPnl: number;
  totalMargin: number;
  totalFreeMargin: number;
}

function generateStats(): Stats {
  return {
    totalBalance: 0,
    totalEquity: 0,
    totalOpenPnl: 0,
    totalMargin: 0,
    totalFreeMargin: 0,
  };
}

enum PortfolioViewMode {
  Account,
  Positions,
}

export class Controller {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  MT4_PLATFORMS = MT4_PLATFORMS;

  tableColumns = dashboardSettings.dashboardTable.cols;

  hasBroadcastedFilterUpdate: boolean = false;

  hasBroadcastedFilterAdd: boolean = false;

  broadcastedColumnRemove: boolean = false;

  prfCurrentPlatformSession$ = new rx.BehaviorSubject<
    CurrentPlatformSessionStoreServiceDirectiveController
  >(null);

  portfolioSearchTerm$: rx.BehaviorSubject<string> = new rx.BehaviorSubject<
    string
  >(null);

  platformConnection: string;

  stats$ = new rx.BehaviorSubject<Stats>(generateStats());

  tblNumOfRows: any;

  rates: CurrencyRate[];

  opBrandChosen$ = new rx.Subject<Brand>();

  platformUserSettings$ = new rx.BehaviorSubject<{
    id: number;
    value: PlatformConnection;
  }>(null);

  opPlatformChosen$ = new rx.Subject<PlatformConnection>();

  brandUserSettings$ = new rx.BehaviorSubject<{ id: number; value: Brand }>(
    null,
  );

  selectedBrand$: rx.BehaviorSubject<Brand> = new rx.BehaviorSubject<Brand>(
    null,
  );

  selectedPlatform: Platform;

  selectedPlatformConnection$ = new rx.BehaviorSubject<PlatformConnection>(
    null,
  );

  brandSelectDataFetched$ = new rx.BehaviorSubject<Brand[]>(null);

  platformSelectDataFetched$ = new rx.BehaviorSubject<PlatformConnection[]>(
    null,
  );

  opPlatformChosenAsFirst$ = new rx.Subject<PlatformConnection>();

  portfolioViewMode = PortfolioViewMode;

  portfolioViewModeCtrl = new FormControl<PortfolioViewMode>(
    this.portfolioViewMode.Account,
  );

  tableKey$ = this.streamTableKey();

  triggerForFiltersShow$ = this.streamTriggerForFilterShow();

  quickFilters$ = this.streamQuickFilters();

  selectedPlatformType: PlatformTypeOption;

  displayPlatformTypeSelector: boolean = false;

  brandList: Brand[];

  excludedBrandIds: number[];

  /*@ngInject */
  constructor(
    readonly $scope: angular.IScope,
    readonly brandsService: () => BrandsService,
    readonly brandsPnlStatsSocketService: BrandsPnlStatsSocketService,
    readonly currencyRatesService: CurrencyRatesService,
    readonly $http: IHttpService,
    readonly cfdPlatformBrandsService: CfdPlatformBrandsService,
    readonly mt4BrandStatsSocketService: Mt4BrandStatsSocketService,
    readonly userSettingsService: UserSettingsService,
    readonly bundleStatsSocketService: BundleStatsSocketService,
  ) {
    this.$scope.$on('table:filter:updated', (event, args) => {
      if (this.hasBroadcastedFilterUpdate) {
        return;
      }
      this.hasBroadcastedFilterUpdate = true;
      this.$scope.$broadcast('table:filter:updated', args);
      this.hasBroadcastedFilterUpdate = false;
    });

    this.$scope.$on('table:filter:add', (event, args) => {
      if (this.hasBroadcastedFilterAdd) {
        return;
      }
      this.hasBroadcastedFilterAdd = true;
      this.$scope.$broadcast('table:filter:add', args);
      this.hasBroadcastedFilterAdd = false;
    });
    this.$scope.$on('table:column:removed', (event, args) => {
      if (this.broadcastedColumnRemove) {
        return;
      }
      this.broadcastedColumnRemove = true;
      this.$scope.$broadcast('table:column:removed', args);
      this.broadcastedColumnRemove = false;
    });
    this.currencyRatesService.getCurrencyRates().then((res) => {
      this.rates = res;
    });

    this.$scope.$watch('$ctrl.selectedPlatformType', () => {
      if (this.selectedPlatformType) {
        this.updateExcludedBrands(this.selectedPlatformType.code);
      }
    });
  }

  $onInit() {
    this.tblNumOfRows = {
      count: dashboardSettings.dashboardTable.ngTable.parameters.count,
    };
    useStreams(
      [
        this.portfolioViewModeCtrl.value$,
        this.streamLoginToPlatformSession(),
        this.streamCachedPlatformConnection(),
        this.streamCachedBrand(),
        this.streamPlatformConn(),
        this.streamBrand(),
        this.streamBrandMetrices(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  /**
   * Setter for ngModel portfolioSearchTerm.
   *
   * Used as rxjs behavior subject encapsulation.
   */
  set portfolioSearchTerm(val: string) {
    if (_.isNil(this.portfolioSearchTerm$)) {
      return;
    }
    this.portfolioSearchTerm$.next(val);
  }

  /**
   * Getter for ngModel portfolioSearchTerm.
   *
   * Used as rxjs behaviour subject encapsulation.
   */
  get portfolioSearchTerm() {
    if (_.isNil(this.portfolioSearchTerm$)) {
      return null;
    }

    return this.portfolioSearchTerm$.getValue();
  }

  set selectedBrand(brand) {
    this.opBrandChosen$.next(brand);
  }

  get selectedBrand() {
    return this.selectedBrand$.getValue();
  }

  set selectedPlatformConnection(platformConnection: PlatformConnection) {
    this.opPlatformChosen$.next(platformConnection);
  }

  get selectedPlatformConnection() {
    return this.selectedPlatformConnection$.getValue();
  }

  onTableColumnsChanged({ newCols }) {
    this.tableColumns = newCols;
  }

  sumProp(arr: any[], prop) {
    if (arr.length === 1 && arr[0].symbol === 'USD') {
      return arr[0][prop];
    }
    // build array out of the property in USD
    const propArr = arr.map(
      (pair) =>
        pair[prop] * this.currencyRatesService.getRate(this.rates, pair.symbol),
    );
    // sum and return it
    return propArr.reduce((a, b) => {
      return a + b;
    }, 0);
  }

  streamLoginToPlatformSession() {
    const actionStream = rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.selectedPlatformConnection$.pipe(
            rx.filter((conn) => !_.isNil(conn)),
          ),
          this.prfCurrentPlatformSession$.pipe(
            rx.filter((service) => !_.isNil(service)),
          ),
        ),
      rx.switchMap(([brandPlatform, sessionService]) => {
        return sessionService.login(brandPlatform);
      }),
      rx.catchError((err) => {
        log.warn('error streamLoginToPlatformSession', err);
        return actionStream(null);
      }),
    );

    return actionStream(null);
  }

  /**
   * stream cache platform and brand
   * save to local storage every user change
   */
  streamCachedPlatformConnection() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          rx.obs.merge(this.opPlatformChosen$, this.opPlatformChosenAsFirst$),
          this.platformUserSettings$,
        ),
      rx.tap(([platform, platformUserSettings]) => {
        if (platform && platformUserSettings) {
          this.userSettingsService.setSettingValue(platformUserSettings.id, {
            platformConnectionId: _.get(['id'], platform),
          });
        }
      }),
      rx.catchError((err) => {
        log.warn('error streamCachedPlatformConnection', err);
        return rx.obs.EMPTY;
      }),
    )(null);
  }

  streamCachedBrand() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.opBrandChosen$, this.brandUserSettings$),
      rx.tap(([brand, brandUserSettings]) => {
        if (brand && brandUserSettings) {
          this.userSettingsService.setSettingValue(brandUserSettings.id, {
            brandId: _.get(['id'], brand),
          });
        }
      }),
      rx.catchError((err) => {
        log.warn('error streamCachedBrand', err);
        return rx.obs.EMPTY;
      }),
    )(null);
  }

  streamPlatformConn() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.lifecycles.onInit$.pipe(
            rx.switchMap(() =>
              rx.obs.combineLatest(
                rx.obs
                  .from(
                    this.userSettingsService.getSetting(
                      UserSettingsKeyCode.PortfolioPlatform,
                      {
                        platformConnectionId: null,
                      },
                    ),
                  )
                  .pipe(
                    rx.tap((platformCache) =>
                      this.platformUserSettings$.next(platformCache),
                    ),
                  ),
                this.platformSelectDataFetched$.pipe(
                  rx.filter((x) => !_.isNil(x)),
                ),
                this.selectedBrand$.pipe(rx.filter((x) => !_.isNil(x))),
              ),
            ),
            rx.map(([platformCache, selectorPlatformsConns, selectedBrand]) => {
              return selectorPlatformsConns.find(
                (conn) =>
                  conn.id ===
                  _.get(['value', 'platformConnectionId'], platformCache),
              );
            }),
          ),
          this.opPlatformChosen$,
          this.opBrandChosen$.pipe(
            rx.switchMap(() => this.platformSelectDataFetched$),
            rx.map((selectorPlatformsConns) => _.head(selectorPlatformsConns)),
            rx.tap((conns) => this.opPlatformChosenAsFirst$.next(conns)),
          ),
        ),
      rx.tap((op) => this.selectedPlatformConnection$.next(op)),
      rx.catchError((err) => {
        log.warn('error streamPlatformConn', err);
        return rx.obs.EMPTY;
      }),
    )(null);
  }

  /**
   * stream brand
   */
  streamBrand() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.lifecycles.onInit$.pipe(
            rx.switchMap(() =>
              rx.obs.combineLatest(
                rx.obs
                  .from(
                    this.userSettingsService.getSetting(
                      UserSettingsKeyCode.PortfolioBrandUserSettings,
                      {
                        brandId: null,
                      },
                    ),
                  )
                  .pipe(
                    rx.tap((brandCache) =>
                      this.brandUserSettings$.next(brandCache),
                    ),
                  ),
                this.brandSelectDataFetched$.pipe(
                  rx.filter((x) => !_.isNil(x)),
                ),
              ),
            ),
            rx.map(([brandCache, selectorBrands]) => {
              this.brandList = selectorBrands;
              let hasProp = selectorBrands.some(
                (brand) => brand.platformType.code === PlatformTypeCode.Prop,
              );
              let hasForex = selectorBrands.some(
                (brand) => brand.platformType.code === PlatformTypeCode.Forex,
              );
              this.displayPlatformTypeSelector = hasProp && hasForex;
              return selectorBrands.find(
                (brand) => brand.id === _.get(['value', 'brandId'], brandCache),
              );
            }),
          ),
          this.opBrandChosen$,
        ),
      rx.tap((brand) => this.selectedBrand$.next(brand)),
      rx.catchError((err) => {
        log.warn('error streamBrand', err);
        return rx.obs.EMPTY;
      }),
    )(null);
  }

  subStreamStatsPerPlatform(sessionInfo: PlatformSessionInfo) {
    if (_.isNil(sessionInfo)) {
      return rx.obs.from([]);
    }

    if (!sessionInfo.isLoggedIn) {
      return rx.obs.from([]);
    }

    if (sessionInfo.platform.code === 'CFD') {
      return rx.obs
        .from(
          this.cfdPlatformBrandsService.getCfdPlatformBrand(
            sessionInfo.session.apiUrl,
            sessionInfo.session.token,
          ),
        )
        .pipe(
          rx.pluck('data'),
          rx.map((httpResponse) => httpResponse[0]),
          rx.switchMap((statsBrand) =>
            this.buildCfdBrandsStats(statsBrand.id, sessionInfo.session),
          ),
          rx.catchError((err) => {
            log.error('error gettings cradentails for platform CFD', err);
            return rx.obs.EMPTY;
          }),
        );
    }
    if (sessionInfo.platform.code === 'BUNDLE') {
      return rx.obs
        .from(
          this.cfdPlatformBrandsService.getCfdPlatformBrand(
            sessionInfo.session.apiUrl,
            sessionInfo.session.token,
          ),
        )
        .pipe(
          rx.pluck('data'),
          rx.map((httpResponse) => httpResponse[0]),
          rx.switchMap((statsBrand) =>
            this.buildBundleBrandsStats(statsBrand.id, sessionInfo.session),
          ),
          rx.catchError((err) => {
            log.error('error gettings cradentails for platform bundle', err);
            return rx.obs.EMPTY;
          }),
        );
    }

    if (MT4_PLATFORMS.includes(sessionInfo.platform.code)) {
      return this.buildMt4BrandsStats(
        sessionInfo.session,
        sessionInfo.senderCompId,
      ).pipe(
        rx.catchError((err) => {
          log.error('error gettings cradentails for platform MT4', err);
          return rx.obs.EMPTY;
        }),
      );
    }

    return rx.obs.from([]);
  }

  /**
   * stream brand metric according to platform type (CFD, MT4 ..)
   */
  streamBrandMetrices() {
    const actionStream = rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.selectedPlatformConnection$,
          this.prfCurrentPlatformSession$.pipe(
            rx.filter((service) => !_.isNil(service)),
            rx.switchMap((service) => service.sessionS.stream$),
          ),
          this.selectedBrand$,
        ),
      rx.tap(() => this.stats$.next(generateStats())),
      rx.switchMap(([conn, sessionInfo, brand]) => {
        if ([conn, sessionInfo, brand].some((x) => _.isNil(x))) {
          return rx.obs.EMPTY;
        }

        return this.subStreamStatsPerPlatform(sessionInfo);
      }),
      rx.filter(({ currency }) => !_.isNil(currency)),
      rx.map(({ currency }) => {
        return currency.filter((curr) => curr['symbol'] !== '');
      }),
      rx.withLatestFrom(this.selectedPlatformConnection$),
      rx.map(([currency, platformConn]) => {
        if (
          platformConn.platform.code === 'CFD' ||
          platformConn.platform.code === 'BUNDLE'
        ) {
          return {
            totalBalance: this.sumProp(
              currency,
              'brandTotalBalancePerCurrency',
            ),
            totalOpenPnl: this.sumProp(
              currency,
              'brandTotalOpenPnlPerCurrency',
            ),
            totalEquity: this.sumProp(currency, 'brandTotalEquityPerCurrency'),
            totalVolume: this.sumProp(currency, 'brandTotalVolumePerCurrency'),
          };
        }
        return {
          totalBalance: getDollarSumOfAllStatsForField(
            'balance',
            currency,
            this.currencyRatesService,
            this.rates,
          ),
          totalOpenPnl: getDollarSumOfAllStatsForField(
            'pnl',
            currency,
            this.currencyRatesService,
            this.rates,
          ),
          totalMargin: getDollarSumOfAllStatsForField(
            'margin',
            currency,
            this.currencyRatesService,
            this.rates,
          ),
          totalEquity: calcDollarTotalEquity(
            currency,
            this.currencyRatesService,
            this.rates,
          ),
          totalFreeMargin: getDollarSumOfAllStatsForField(
            'freemargin',
            currency,
            this.currencyRatesService,
            this.rates,
          ),
        };
      }),
      rx.withLatestFrom(this.stats$),
      rx.map(([itemUpdates, stats]) => {
        return {
          ...stats,
          ...itemUpdates,
        };
      }),
      rx.tap((stats) => this.stats$.next(stats as Stats)),
      rx.catchError((err) => {
        log.error('error in streamBrandMetrices', err);
        return actionStream(null);
      }),
    );

    return actionStream(null);
  }

  /**
   * builds Cfd Brands Stats
   * statsBrandId - stats brand id
   */
  buildCfdBrandsStats(trcBrandId: number, session: UserBrandPlatformSession) {
    const serviceInstance = this.brandsPnlStatsSocketService;
    serviceInstance.setToken(session.token);
    serviceInstance.setStreamerUrl(session.streamerUrl);

    const channel = this.brandsPnlStatsSocketService.buildBrandPnlStatsChannel(
      trcBrandId,
    );

    return observeChannel<BrandPnlStats>(serviceInstance, channel);
  }

  /**
   * buildMt4BrandsStats
   */
  buildMt4BrandsStats(session: UserBrandPlatformSession, senderCompId: string) {
    const serviceInstance = this.mt4BrandStatsSocketService;
    serviceInstance.setStreamerUrl(session.streamerUrl);

    const channel = buildMt4BrandsStatsChannel(senderCompId);
    return observeChannel<BrandMt4PnlStats>(serviceInstance, channel);
  }

  /**
   * build Bundle Brands Stats
   * statsBrandId - stats brand id
   */
  buildBundleBrandsStats(
    statsBrandId: number,
    session: UserBrandPlatformSession,
  ) {
    const serviceInstance = this.bundleStatsSocketService;
    serviceInstance.setToken(session.token);
    serviceInstance.setStreamerUrl(session.streamerUrl);

    const channel = this.bundleStatsSocketService.buildBundleStatsChannel(
      statsBrandId,
    );
    return observeChannel<BrandPnlStats>(serviceInstance, channel);
  }

  $onChanges() {}

  $onDestroy() {}

  streamTableKey() {
    return rx.pipe(
      () => this.portfolioViewModeCtrl.value$,
      rx.map((viewMode) =>
        viewMode === this.portfolioViewMode.Account
          ? UserFilterTypeCode.Portfolio
          : UserFilterTypeCode.PortfolioPositions,
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamQuickFilters() {
    return rx.pipe(
      () => this.portfolioViewModeCtrl.value$,
      rx.map((viewMode) =>
        viewMode === this.portfolioViewMode.Account
          ? (<any>dashboardSettings).dashboardTable.quickFilters
          : (<any>portfolioPositionsTableSettings).quickFilters,
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamTriggerForFilterShow() {
    return rx.pipe(
      () => this.tableKey$,
      rx.map(() => ({})),
      rx.startWith({}),
      shareReplayRefOne(),
    )(null);
  }

  updateExcludedBrands(platformTypeCode: PlatformTypeCode) {
    this.excludedBrandIds = this.brandList
      .filter((brand) => {
        return platformTypeCode === PlatformTypeCode.Prop
          ? brand.platformType.code !== PlatformTypeCode.Prop
          : brand.platformType.code === PlatformTypeCode.Prop;
      })
      .map(({ id }) => id);
  }
}

interface StatsPerCurrency {
  symbol: string;
  pnl: number;
  balance: number;
  margin: number;
  freemargin: number;
  credit: number;
}

interface Rate {
  baseCurrencyCode: string;
  rate: number;
}

function getDollarSumOfAllStatsForField(
  fieldName: string,
  statsList: StatsPerCurrency[],
  currencyRatesService: CurrencyRatesService,
  rates: Rate[],
): number {
  return statsList.reduce((acc, stat) => {
    const currencyCode = stat.symbol;
    const rateForDollar = currencyRatesService.getRate(rates, currencyCode);
    const baseValue = stat[fieldName];
    const convertedValue = baseValue * rateForDollar;
    const newAcc = acc + convertedValue;

    return newAcc;
  }, 0);
}

function calcDollarTotalEquity(
  statsList: StatsPerCurrency[],
  currencyRatesService: CurrencyRatesService,
  rates: Rate[],
) {
  const balance = getDollarSumOfAllStatsForField(
    'balance',
    statsList,
    currencyRatesService,
    rates,
  );

  const pnl = getDollarSumOfAllStatsForField(
    'pnl',
    statsList,
    currencyRatesService,
    rates,
  );

  const credit = getDollarSumOfAllStatsForField(
    'credit',
    statsList,
    currencyRatesService,
    rates,
  );

  const totalEquity = balance + pnl + credit;

  return totalEquity;
}

export const PortfolioDashboardComponent = {
  template,
  controller: Controller,
};

export default PortfolioDashboardComponent;
