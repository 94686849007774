import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './challenges.component.html';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { Brand } from '@proftit/crm.api.models.entities';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { PreselectType } from '~/source/common/components/dropdowns/base/component';
import BrandsService from '~/source/management/brand/services/brands';
import BrandChallengesGroupsService from '~/source/management/challenges/services/brand-challenges-groups.service';
import {
  CHALLENGE_TYPES,
  ChallengeGroup,
} from '@proftit/crm.api.models.entities/src';
import platformTypesMap from '~/source/common/constants/platform-types-map';

const styles = require('./challenges.component.scss');

const LAST_SELECTED_BRAND_ID_KEY =
  'prf.custom.management.integration.ewallet.cryptoEwallet.lastSelectedBrandId';

type BrandOption = Brand & { label: string };

export class ChallengesController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  PreselectType = PreselectType;
  reloadChallengesAction = new rx.Subject<void>();
  brandOptions$ = this.streamBrandsOptions();
  brandFormControl = new FormControl<BrandOption>(null);
  challengesForBrand$ = this.streamBrandChallenges();
  challengeTypes = CHALLENGE_TYPES;
  uiEnabled = false;

  /* @ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly brandChallengesGroupsService: BrandChallengesGroupsService,
  ) {
    useStreams(
      [
        this.brandFormControl.value$,
        this.challengesForBrand$,
        this.brandOptions$,
        this.streamSaveSelectedBrandIdToLocalStorage(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamBrandsOptions() {
    return rx.pipe(
      () => rx.obs.from(this.fetchPropBrands()),
      rx.map((brands) => {
        return [...brands] as BrandOption[];
      }),
      rx.tap((brands) => {
        const lastSelectedBrandId = this.getLastSelectedBrandId();
        if (lastSelectedBrandId) {
          const option = brands.find(
            (brand) => brand.id === lastSelectedBrandId,
          );
          if (option) {
            this.brandFormControl.setValue(option);
          }
        }
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamBrandChallenges(): rx.Observable<ChallengeGroup[]> {
    return rx.pipe(
      () =>
        rx.obs.merge(this.brandFormControl.value$, this.reloadChallengesAction),
      rx.withLatestFrom(this.brandFormControl.value$),
      rx.filter(([a, brand]) => !_.isNil(brand)),
      rx.switchMap(([a, brand]) => {
        return rx.obs
          .from(this.fetchChallengeGroups(brand.id))
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSaveSelectedBrandIdToLocalStorage() {
    return rx.pipe(
      () => this.brandFormControl.value$,
      rx.filter((value) => !_.isNil(value)),
      rx.tap((value) => {
        localStorage.setItem(LAST_SELECTED_BRAND_ID_KEY, String(value.id));
      }),
      shareReplayRefOne(),
    )(null);
  }

  async fetchChallengeGroups(brandId: number) {
    const response = await this.brandChallengesGroupsService.getChallengeGroupsByBrandId(
      brandId,
    );
    const challengeGroup = response.plain();
    return challengeGroup.map((group: ChallengeGroup, index: number) => {
      return {
        ...group,
        order: index,
      };
    });
  }

  async fetchPropBrands() {
    const platformTypeId = Number(
      Object.keys(platformTypesMap).find((k) => platformTypesMap[k] == 'prop'),
    );
    const response = await this.brandChallengesGroupsService.getBrandsByPlatformType(
      platformTypeId,
    );
    const brands = response.plain();
    return brands.map((brand: Brand) => {
      return {
        ...brand,
        label: brand.name,
      };
    }) as BrandOption[];
  }

  getLastSelectedBrandId() {
    const storedValue = localStorage.getItem(LAST_SELECTED_BRAND_ID_KEY);
    if (_.isNil(storedValue)) {
      return null;
    }

    return Number(storedValue);
  }
}

export const ChallengesComponent = {
  template,
  controller: ChallengesController,
};
