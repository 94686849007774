import BaseController from '~/source/common/controllers/base';
import { IScope } from 'angular';
import CustomersService from '~/source/contact/common/services/customers';
import TradingAccountFormFormatter from '~/source/contact/common/services/trading-account-form-formatter.service';
import {
  TradingAccount,
  Customer,
  Platform,
  BrandPlatform,
} from '@proftit/crm.api.models.entities';
import { MT4_PLATFORMS, PlatformTypeCode } from '@proftit/crm.api.models.enums';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { ACCOUNT_ADDED } from '~/source/common/constants/general-pubsub-keys';
import { ALLOWED_PLATFORMS_TYPE_CODES } from '@proftit/crm.api.models.entities/src';

class AddAccountPopupController extends BaseController {
  formFormatter: TradingAccountFormFormatter;
  customerServiceInst: CustomersService;
  account: TradingAccount;
  customerInfo: Customer;
  $close: () => void;
  MT4_PLATFORMS = MT4_PLATFORMS;
  allowedPlatforms: string[] = ALLOWED_PLATFORMS_TYPE_CODES;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly customersService: () => CustomersService,
    readonly tradingAccountFormFormatter: () => TradingAccountFormFormatter,
    readonly tradingAccountTypesService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
    readonly toggleAddAccountButton: (show: boolean) => void,
  ) {
    super();

    Object.assign(this, {
      formFormatter: this.tradingAccountFormFormatter(),
      account: {},
      accountTypes: this.tradingAccountTypesService()
        .setConfig({ blockUiRef: 'accountTypes' })
        .getListWithQuery().$object,
      customerServiceInst: this.customersService(),
    });
  }

  /**
   * Called when form is valid
   */
  onValidated() {
    // format data before sending it to server
    const formattedAccount = this.formFormatter.format(this.account);
    const platformType = this.customerInfo.brand.platformType.code;
    const expandsByType = {
      binary: ['tradingAccountType'],
      forex: ['tradingAccountGroup'],
      prop: ['tradingAccountGroup'],
    };
    // send request to the server
    this.customerServiceInst
      .getAccountsResource(this.customerInfo.id, platformType)
      .expand(['platform', 'currency'].concat(expandsByType[platformType]))
      .postWithQuery<IElementRestNg<TradingAccount>>(formattedAccount)
      .then((account) => {
        // "expand" the status code, to get it selected in the dropdown
        (account.statusCode as any) = { code: account.statusCode };
        this.onAddAccountSuccess(account);
      });
  }

  /**
   *
   * @param {object} account
   */
  onAddAccountSuccess(account) {
    this.prfClientGeneralPubsub.publish(ACCOUNT_ADDED, account);
    this.toggleAddAccountButton(false);
    this.$close();
  }

  /**
   * Check if Prop customer
   */
  isProp() {
    return this.customerInfo.brand.platformType.code === PlatformTypeCode.Prop;
  }
}

export default AddAccountPopupController;
