import ng from 'angular';

import BaseController from '~/source/common/controllers/base';
import relatedWithdrawalsSettings from './related-withdrawals-settings.json';
import template from './related-withdrawals.html';
import ApprovedWithdrawals from '~/source/common/models/approved-withdrawals';
import {
  TradingAccount,
  Customer,
  TradingAccountWithdrawal,
} from '@proftit/crm.api.models.entities';
import { NgTableParams } from 'ng-table';
import Withdrawal from '~/source/common/models/withdrawal';
import CustomersService from '~/source/contact/common/services/customers';
import ICollectionRestNg from '~/source/common/models/icollection-rest-ng';
import wrapNgPermissionValidatePromise from '~/source/common/utilities/wrap-ng-permission-validate-promise';
import FeaturesFlagsService from '~/source/common/services/features-flags.service';

enum ColumnRenderes {
  Regular = 'regular',
  Method = 'method',
  Actions = 'actions',
  None = 'none',
  TransactionId = 'transactionId',
}

interface ActionsMenuItem {
  labelCode: string;
  actionCode: string;
}

interface DisplayedItem extends TradingAccountWithdrawal {
  _transactionLink: string;
}

class WithdrawalAdditonalInfo {}

class RelatedWithdrawalsController extends BaseController {
  static $inject = [
    '$scope',
    'NgTableParams',
    'PermPermissionStore',
    'customersService',
    'featuresFlags',
    ...BaseController.$inject,
  ];

  account: TradingAccount;
  customer: Customer;
  withdrawals: TradingAccountWithdrawal[];
  method: any;

  NgTableParams: typeof NgTableParams;
  PermPermissionStore: ng.permission.PermissionStore;
  customersService: () => CustomersService;
  featuresFlags: FeaturesFlagsService;

  settings = { ...relatedWithdrawalsSettings };
  cols = relatedWithdrawalsSettings.tableColumns;
  tableParams: NgTableParams<DisplayedItem>;
  actionsMenuItems: ActionsMenuItem[] = [];
  additionals = {};

  /**
   * Component Life cycle event - on init
   *
   * @return {void}
   */
  $onInit() {
    this.calcActionsMenuItems().then((items: ActionsMenuItem[]) => {
      this.actionsMenuItems = items;
    });

    this.additionals = this.calcInitialAdditionals(this.withdrawals);
  }

  /**
   * add currency object into query results. because this data is required for 'amount' field value formatting
   * defined in related-withdrawals-settings.json.
   *
   * @param {Array} data query results
   * @return {Array} returns formatted query results
   */
  parseLoadedData(data: TradingAccountWithdrawal[]): DisplayedItem[] {
    return data.map((item) => {
      const newItem: DisplayedItem = {
        ...item,
        currency: null,
        _transactionLink: null,
      };
      newItem.currency = this.account.currency;

      newItem._transactionLink = this.calcEwalletWithdrawalUrl(newItem);

      return newItem;
    });
  }

  calcEwalletWithdrawalUrl(withdrawal: TradingAccountWithdrawal) {
    if (!withdrawal?.currency?.isCrypto) {
      return null;
    }

    if (this.account.currency.code === 'BTC') {
      return `https://www.blockchain.com/btc/tx/${withdrawal.ewalletTransaction?.transactionId}`;
    }

    return `https://etherscan.io/tx/${withdrawal.ewalletTransaction?.transactionId}`;
  }

  /**
   * @param {object} col - column info.
   * @returns {ColumnRenderes} value representing the renderer to use.
   */
  colRendererToShow(col) {
    if (col.field === 'method' && this.method !== null) {
      return ColumnRenderes.Method;
    }

    if (col.isActionsColumn === true) {
      return ColumnRenderes.Actions;
    }

    if (col.field === 'transactionId') {
      return ColumnRenderes.TransactionId;
    }

    if (col.field !== 'method') {
      return ColumnRenderes.Regular;
    }

    return ColumnRenderes.None;
  }

  /**
   * Calculated menu items for the withdrawal action menu.
   *
   * @returns {[object]} menu definition
   */
  calcActionsMenuItems() {
    const items = [];
    return Promise.resolve(items);
  }

  /**
   * Event handler - of withdrawal menu item click
   *
   * @param {string} action code - Action that was clicked in the menu.
   * @param {object} row - specific withdrawal information
   * @returns {void}
   */
  onDropDownAction(actionCode, row) {
    switch (actionCode) {
      default: {
        break;
      }
    }
  }

  /**
   * Calculate the accompaning info for the withdrawal list.
   *
   * @param {[Withdrawal]} withdrawals - list of withdrawals.
   * @return {object} Map of withdrawal additional info per withdrawal id.
   */
  calcInitialAdditionals(withdrawals) {
    return this.withdrawals.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: new WithdrawalAdditonalInfo(),
      }),
      {},
    );
  }

  /**
   * Compnent Event handler - when withdrawal is change through component input change.
   *
   * It recalculate the additonal info array related to withdrawals.
   *
   * @param {[Withdrawal]} current value
   * @param {[Withdrawal]} previous value
   * @return {void}
   */
  onWithdrawalsChange(curr, prev) {
    this.tableParams = new this.NgTableParams(
      {},
      {
        dataset: this.parseLoadedData(this.withdrawals),
      },
    );
    this.additionals = this.calcInitialAdditionals(curr);
  }
}

export default {
  template,
  controller: RelatedWithdrawalsController,
  bindings: {
    customer: '<',
    account: '<',
    withdrawals: '<',
    wire: '<',
  },
};
