import template from './challenge-preview.component.html';
import { StateParams, StateService } from '@uirouter/core';
import {
  Challenge,
  ChallengeAccountBalanceConfig,
  ChallengeGroup,
  ChallengePhase,
  ChallengePhasePayload,
} from '@proftit/crm.api.models.entities';
import BrandChallengesGroupsService from '~/source/management/challenges/services/brand-challenges-groups.service';
import {
  CALCULATION_BASE,
  CHALLENGE_TYPES,
} from '@proftit/crm.api.models.entities/src';
import { parseChallengesResponse } from '~/source/management/challenges/components/challenge-editor/challenges-editor-uils';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import { BrandsService } from '~/source/management/brand/services/brands';
import { ApiMt4AssetsService } from '~/source/common/api-cfd-platform/api-mt4-assets.service';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

const styles = require('./challenge-preview.scss');

export class ChallengePreviewController {
  lifecycles = observeComponentLifecycles(this);
  styles = styles;
  challenge: Challenge;
  challengeGroup: ChallengeGroup;
  accountBalances: ChallengeAccountBalanceConfig[][];
  phases: ChallengePhasePayload[] | ChallengePhase[];
  challengeTypes = CHALLENGE_TYPES;
  brandPlatforms$ = new rx.Subject<any[]>();
  challengeFetched$ = new rx.Subject<boolean>();
  CALCULATION_BASE = CALCULATION_BASE;

  /* @ngInject */
  constructor(
    readonly $state: StateService,
    readonly $stateParams: StateParams,
    readonly brandChallengesGroupsService: BrandChallengesGroupsService,
    readonly brandsService: () => BrandsService,
    readonly prfApiMt4AssetsService: ApiMt4AssetsService,
  ) {
    useStreams([this.streamBrandPlatforms()], this.lifecycles.onDestroy$);
  }

  async $onInit() {
    this.challengeGroup = await this.fetchChallenge(
      this.$stateParams.brandId,
      this.$stateParams.challengeId,
    );
    this.accountBalances = parseChallengesResponse(
      this.challengeGroup.challenges as Challenge[],
      'update',
    );
    this.phases = this.challengeGroup.challenges[0].phases as ChallengePhase[];

    this.mapPhaseAssets();
  }

  $onDestroy() {}

  $onChanges() {}

  async fetchChallenge(
    brandId: number,
    challengeGroupId: number,
  ): Promise<ChallengeGroup> {
    try {
      const data = await this.brandChallengesGroupsService.getChallengesGroupById(
        brandId,
        challengeGroupId,
      );
      this.challengeFetched$.next(true);
      return data.plain() as ChallengeGroup;
    } catch (error) {
      console.error(error);
    }
  }

  streamBrandPlatforms() {
    return rx.pipe(
      () => this.challengeFetched$,
      rx.tap((challengeFetched) => {
        if (challengeFetched) {
          this.brandsService()
            .getPlatformsResource(this.$stateParams.brandId)
            .getListWithQuery()
            .then((result) => {
              this.brandPlatforms$.next(result.plain());
            });
        }
      }),
      shareReplayRefOne(),
    )(null);
  }

  mapPhaseAssets() {
    for (let phase of this.phases) {
      phase.fields.assets = phase.fields.assets.map((item) => ({
        value: item,
        name: item,
        label: item,
      }));
    }
  }
}

export const ChallengePreviewComponent = {
  template,
  controller: ChallengePreviewController,
};
