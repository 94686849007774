import template from './contacts-stats.html';
import BaseController from '~/source/common/controllers/base';
import angular, { IScope } from 'angular';
import CustomerStats from '~/source/contact/common/services/stats.service';
import { PlatformType } from '@proftit/crm.api.models.entities';
import { PlatformTypeCode } from '@proftit/crm.api.models.enums';

class ContactsStatsController extends BaseController {
  summary;
  platformType: PlatformType;
  platformTypeCode = PlatformTypeCode;

  /*@ngInject*/
  constructor(readonly $scope: IScope, readonly stats: () => CustomerStats) {
    super();
  }

  $onInit() {
    this.getData();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.platformType && changes.platformType.currentValue) {
      this.getData();
    }
  }

  getData() {
    if (!this.platformType) {
      return;
    }

    this.summary = (this.stats()
      .setConfig({ blockUiRef: 'customersSummary' })
      .filter({ platformTypeId: this.platformType.id })
      .getOneWithQuery('customers') as any).$object;
  }
}

export default {
  template,
  controller: ContactsStatsController,
  controllerAs: 'vm',
  bindings: {
    platformType: '<',
  },
};
