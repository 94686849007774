import angular from 'angular';

import CallsModule from './calls/module';
import { SidebarModule } from './sidebar-tabs/module';
import TilesModule from './tile-list/module';
import TradingAccountModule from './trading-account/module';
import AccountsSummaryComponent from './accounts-summary/accounts-summary.component';
import SendEmailPopupController from './send-email/send-email-popup.controller';
import ContactPageComponent from './contact-page/contact-page.component';
import { contactPageRouting } from './contact-page.routing';
import { ChallengeAccountModule } from '~/source/contact/contact-page/prop-account/module';

export default angular
  .module('contact.contactPage', [
    CallsModule,
    SidebarModule.name,
    TilesModule,
    TradingAccountModule,
    ChallengeAccountModule,
  ])
  .component('prfContactPage', ContactPageComponent)
  .component('prfAccountsSummary', AccountsSummaryComponent)
  .controller('SendEmailPopupController', SendEmailPopupController)
  .config(contactPageRouting).name;
