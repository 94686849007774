import ng from 'angular';
import Restangular from 'restangular';
import tablePopupTemplate from '~/source/common/components/table/table-popup.html';
import withdrawalSettings from '../withdrawal-settings.json';
import TableLiveController from '~/source/common/components/table/table-live.controller';
import CustomersService from '~/source/contact/common/services/customers';
import TokensService from '~/source/auth/services/tokens';
import SocketService from '~/source/common/services/socket';
import addWithdrawalReqNotifierService from '~/source/common/services/add-withdrawal-request-notifier.service';
import { User, Desk } from '@proftit/crm.api.models.entities';
import Withdrawal from '~/source/common/models/withdrawal';
import WithdrawalRequest from '~/source/common/models/withdrawal-request';
import UsersService from '~/source/management/user/services/users';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import isWithdrawalDeletable from '~/source/common/models/withdrawal-request/is-withdrawal-deletable';
import template from './withdrawal-table.html';
import withdrawalTablePopupTemplate from '../withdrawal-table-popup.html';
import addWithdrawalPopupTemplate from '../add-withdrawal-request-popup/add-withdrawal-request-popup.html';
import withdrawalStatusUpdatePopupTemplate from '../withdrawal-status-update-popup.html';
import { PopupService } from '~/source/common/components/modal/popup.service';
import { WITHDRAWAL_STATUS_UPDATE } from '~/source/common/constants/general-pubsub-keys';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { useStream } from '~/source/common/utilities/use-stream';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  TradingAccountTransactionStatusCode,
  PlatformTypeCode,
} from '@proftit/crm.api.models.enums';

enum ColumnRenderers {
  Regular = 'regular',
  User = 'user',
  Actions = 'actions',
  None = 'none',
  Note = 'note',
}

export interface MenuItem {
  labelCode: string;
  actionCode: string;
}

class DataAdditional {
  menuItems: MenuItem[] = [];
  showActionWarning: boolean = false;
}

class Controller extends TableLiveController {
  static $inject = [
    '$scope',
    'popupService',
    'withdrawalsSocketService',
    'customersService',
    'highlightEntityService',
    'tokensService',
    'featuresFlags',
    'usersService',
    'PermPermissionStore',
    'addWithdrawalReqNotifierService',
    'prfClientGeneralPubsub',
    ...TableLiveController.$inject,
  ];

  // ** Injects **
  $scope: ng.IScope;
  popupService: PopupService;
  withdrawalsSocketService: any;
  customersService: any;
  highlightEntityService: any;
  tokensService: TokensService;
  addWithdrawalReqNotifierService: addWithdrawalReqNotifierService;
  featuresFlags: Object;
  usersService: () => UsersService;
  PermPermissionStore: ng.permission.PermissionStore;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  // *****************

  // ** Bindings **
  account: any;
  customer: any;
  config: any;

  blockUiId = 'withdrawalsTable';
  dataServiceInstance: CustomersService;
  usersServiceInstance: UsersService;

  // @ts-ignore
  settings = { ...withdrawalSettings, ...(this.config || {}) };
  cols = [...withdrawalSettings.tableColumns];

  withdrawals: Restangular.ICollection;
  dataAdditionals: { [id: number]: DataAdditional } = {};
  unsub$: rx.Subject<any> = new rx.Subject<null>();

  $onInit() {
    super.$onInit();
    this.dataServiceInstance = this.customersService();
    this.usersServiceInstance = this.usersService();
    this.initTable();
    // subscribe to user changes in withdraw list, unsub when component destroy
    this.addWithdrawalReqNotifierService
      .getObservable()
      .pipe(rx.takeUntil(this.unsub$))
      .subscribe(() => this.addWithdrawalRequest());
    useStream(this.streamWithdrawalUpdateStatus(), this.unsub$);
  }

  get pageKey(): string {
    return 'withdrawal';
  }

  /**
   * Returns true in notification directive is in use for this table
   *
   * @returns {boolean}
   */
  isUpdateNotification() {
    return true;
  }

  /**
   * Returns the component label
   *
   * @returns {string}
   */
  getTitleLabel(): string {
    return this.customer.brand.platformType.code === PlatformTypeCode.Prop
      ? 'contact.tradingAccount.WITHDRAWALS_PROP'
      : 'contact.tradingAccount.WITHDRAWALS';
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Service}
   */
  get socketService(): SocketService {
    return this.withdrawalsSocketService;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string}
   */
  get liveEntitiesVarName(): string {
    return 'vm.withdrawals';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection}
   */
  get entitiesContainer() {
    return this.withdrawals;
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  streamWithdrawalUpdateStatus() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === WITHDRAWAL_STATUS_UPDATE),
      rx.tap(() => {
        this.reloadTable();
      }),
    )(null);
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .getWithdrawalRequestsResource(this.customer.id, this.account.id)
      .setConfig({ blockUiRef: this.blockUiId })
      .embed(['withdrawals'])
      .expand([
        'currency',
        'withdrawalType',
        'user',
        'ownerDesk',
        'transactionTransferWire',
        'transactionTransferWire.country:bankCountry', // expanded-expand with mapping. who knew that was possible
        'handler',
        'withdrawals.handler',
        'withdrawals.deposit',
        'withdrawals.transactionTransferCreditCard',
        'withdrawals.transactionTransferCreditCard.clearingCompany',
        'withdrawals.transactionTransferWire',
        'ewalletTransaction',
        'ewalletTransaction.ewallet',
        'withdrawals.ewalletTransaction',
        'withdrawals.ewalletTransaction.ewallet',
        'withdrawals.mobileTransaction',
        'withdrawals.mobileTransaction.clearingCompany',
        'withdrawals.deposit.transactionTransferCreditCard',
        'withdrawals.deposit.transactionTransferCreditCard.clearingCompany',
        'deposit',
        'deposit.transactionTransferCreditCard',
      ])
      .sort({ requestedAt: 'desc' });
  }

  parseLoadedData(data) {
    this.withdrawals = data;
    // reset stale data infos.
    this.dataAdditionals = this.generateDataAdditionals();

    return data;
  }

  /**
   * Generate data infos accompaning the data. Ui state...
   *
   * @return {object[]} the generated data infos
   */
  generateDataAdditionals() {
    return this.withdrawals.reduce((acc, withdrawal) => {
      const info = new DataAdditional();
      this.calcMenuItemsForWithdrawalRow(withdrawal).then((menuItems) => {
        info.menuItems = menuItems;
      });

      return {
        ...acc,
        [withdrawal.id]: info,
      };
    }, {});
  }

  /**
   * When data is reloaded, clear and referesh the accompaning data additional
   * info array. Ex. For each deposit the system saves a ui state of the action
   * warning line open status.
   *
   * on user Paging Event
   *
   * can be override by a different logic
   *
   * @returns {void}
   */
  onUserPaging(): void {
    this.dataAdditionals = this.generateDataAdditionals();
  }

  /**
   * Called when new withdrawal request was successfully added
   *
   * @param {event} e
   * @param {object} response - new withdrawal request
   */
  addWithdrawalRequest(): void {
    // reload table data
    this.reloadTable();
  }

  /**
   * Determines whether request is cancelable. Only request in status
   * "requested" can be canceled
   *
   * @param {object} withdrawalRequest
   * @returns {boolean}
   */
  isCancelable(withdrawalRequest: WithdrawalRequest): boolean {
    return withdrawalRequest.transactionStatusCode === 'requested';
  }

  /**
   * Determines whether request is cancelable. Only request in status
   * "partial" can be closed
   *
   * @param {object} withdrawalRequest
   * @returns {boolean}
   */
  isCloseable(withdrawalRequest: WithdrawalRequest): boolean {
    return withdrawalRequest.transactionStatusCode === 'partial';
  }

  /**
   * Determines whether request is confirmable. Only request in status
   * "partial" and "requested" can be confirmed
   *
   * @param {object} withdrawalRequest
   * @returns {boolean}
   */
  isConfirmable(withdrawalRequest: WithdrawalRequest): boolean {
    // on those statuses request is confirmable
    const statuses = ['partial', 'requested'];

    return statuses.includes(withdrawalRequest.transactionStatusCode);
  }

  isDisabled(withdrawalRequest: WithdrawalRequest) {
    const status = ['canceled', 'closed', 'approved'];
    return status.includes(withdrawalRequest.transactionStatusCode);
  }

  openWithdrawalStatusUpdatePopup(
    withdrawalRequest: WithdrawalRequest,
    newStatus: string,
  ) {
    // open popup
    this.popupService.open({
      controller: 'WithdrawalStatusUpdatePopupController',
      template: withdrawalStatusUpdatePopupTemplate,
      scope: this.$scope,
      data: {
        withdrawalRequest,
        withdrawalRequestStatus: newStatus,
      },
    });
  }

  /**
   * Open "withdrawal confirm" popup
   *
   * @param {object} withdrawalRequest request data
   * @returns {void}
   */
  openRequestConfirmDialog(withdrawalRequest: WithdrawalRequest) {
    this.popupService.open({
      component: 'prfConfirmWithdrawalPopup',
      resolve: {
        withdrawalRequest,
        accountId: this.account.id,
        account: this.account,
        contactId: this.customer.id,
        customer: this.customer,
      },
    });
  }

  /**
   * Open the "add withdrawal" popup
   * @returns {void}
   */
  openAddWithdrawalPopup() {
    this.popupService.open({
      component: 'prfAddWithdrawalPopupComponent',
      resolve: {
        account: this.account,
        customer: this.customer,
      },
    });
  }

  /**
   * Open the "withdrawals log" popup
   * @returns {void}
   */
  openWithdrawalTablePopup() {
    // open popup
    this.popupService.open({
      component: 'prfWithdrawalTablePopup',
      resolve: {
        account: this.account,
        customer: this.customer,
      },
    });
  }

  /**
   * don't show actions popup for sort and filter when mouse-over table columns
   * @override
   * @return {boolean} - inidication whether to show colum actions.
   */
  showColumnActions() {
    return false;
  }

  // calculate the owner desk ID - depends on the existence of owner
  calcOwnerDeskId(owner, desk) {
    if (owner && desk) {
      return desk.id;
    }

    return null;
  }

  /**
   * sign owner to withdrawal
   *
   * @param {User} owner - Owner
   * @param {Desk} desk - Desk
   * @param {Withdrawal} withdrawal - Withdrawal
   * @return {promise} The server request promise.
   */
  assignOwnerToWithdrawal(owner: User, desk: Desk, withdrawal: Withdrawal) {
    return this.customersService()
      .getWithdrawalRequestResource(
        this.customer.id,
        this.account.id,
        withdrawal.id,
      )
      .setConfig({ blockUiRef: 'assignToPopup' })
      .patchWithQuery({
        userId: owner ? owner.id : null,
        ownerDeskId: this.calcOwnerDeskId(owner, desk),
      })
      .then(() => {
        withdrawal.user = owner;
        this.prfClientGeneralPubsub.publish(WITHDRAWAL_STATUS_UPDATE, '');
      });
  }

  /**
   * Calculate what type of column to show. Mainly use to let the view know
   * if to render regular column or action column instead of relying on last column.
   *
   * @param {object} col - column info.
   * @returns {ColumnRenderers} value representing the renderer to use.
   */
  colRendererToShow(col) {
    if (col.isActionsColumn === true) {
      return ColumnRenderers.Actions;
    }

    if (col.field === 'user') {
      return ColumnRenderers.User;
    }

    if (col.field === 'note') {
      return ColumnRenderers.Note;
    }

    return ColumnRenderers.Regular;
  }

  /**
   * Calculate menu items for specific withdrawal.
   *
   * Stateless function.
   *
   * @param {WithdrawalRequest} withdrawalReq - withdrawal
   * @return {Promise} promise of menu items
   */
  calcMenuItemsForWithdrawalRow(
    withdrawalReq: WithdrawalRequest,
  ): Promise<MenuItem[]> {
    const updatePromise = this.PermPermissionStore.getPermissionDefinition(
      'contacts.transactions.withdrawalsrequest_U',
    )
      .validatePermission()
      .then(
        () => {
          return true;
        },
        () => {
          return false;
        },
      );

    const createPromise = this.PermPermissionStore.getPermissionDefinition(
      'contacts.transactions.withdraw_C',
    )
      .validatePermission()
      .then(
        () => {
          return true;
        },
        () => {
          return false;
        },
      );

    return Promise.all([updatePromise, createPromise]).then(
      ([hasReqUpdate, hasWithdrawalCreate]) => {
        let menuItems = [];

        if (hasReqUpdate && this.isCancelable(withdrawalReq)) {
          menuItems = [
            ...menuItems,
            {
              labelCode: 'common.CANCEL',
              actionCode: 'cancel',
            },
          ];
        }

        if (hasReqUpdate && this.isCloseable(withdrawalReq)) {
          menuItems = [
            ...menuItems,
            {
              labelCode: 'common.CLOSE',
              actionCode: 'close',
            },
          ];
        }

        if (hasWithdrawalCreate && this.isConfirmable(withdrawalReq)) {
          menuItems = [
            ...menuItems,
            {
              labelCode: 'common.CONFIRM',
              actionCode: 'confirm',
            },
          ];
        }

        if (isWithdrawalDeletable(withdrawalReq)) {
          menuItems = [
            ...menuItems,
            {
              labelCode: 'common.DELETE',
              actionCode: 'delete',
            },
          ];
        }

        return menuItems;
      },
    );
  }

  onDropDownAction(actionCode, withdrawalReq) {
    const actionsDispatch = {
      cancel: () =>
        this.openWithdrawalStatusUpdatePopup(withdrawalReq, 'canceled'),
      close: () =>
        this.openWithdrawalStatusUpdatePopup(withdrawalReq, 'closed'),
      confirm: () => this.openRequestConfirmDialog(withdrawalReq),
      delete: () =>
        (this.dataAdditionals[withdrawalReq.id].showActionWarning = true),
    };

    const actionFn = _.defaultTo(() => {}, actionsDispatch[actionCode]);
    actionFn();
  }

  deleteWithdrawal(withdrawalReq: IElementRestNg<WithdrawalRequest>) {
    // delete request
    withdrawalReq
      .patch({
        transactionStatusCode: TradingAccountTransactionStatusCode.Deleted,
      })
      .then(() => {
        // update table data on success
        withdrawalReq.transactionStatusCode = 'deleted';
        // reset data infos
        this.dataAdditionals = this.generateDataAdditionals();
      });
  }

  $onDestroy() {
    super.$onDestroy();

    this.unsub$.next(null);
    this.unsub$.complete();
  }

  /**
   * Method to allow doing work after entity was updated from socket.
   *
   * @param {object} entity - updated entity
   * @return {void}
   */
  onAfterEntityUpdate(entity) {
    // reset and regenrated data infos;
    this.dataAdditionals = this.generateDataAdditionals();
  }

  patchNoteAction({
    note,
    withdrawalRequestId,
  }: {
    note: string;
    withdrawalRequestId: number;
  }) {
    return this.dataServiceInstance
      .setConfig({ blockUiRef: this.blockUiId })
      .patchWithdrawalRequest(
        this.customer.id,
        this.account.id,
        withdrawalRequestId,
        { note },
      )
      .then((newWithdrawalRequest) => {
        this.onWithdrawalRequestNoteChange(
          withdrawalRequestId,
          newWithdrawalRequest.note,
        );
      })
      .catch((e) => {});
  }

  onWithdrawalRequestNoteChange(withdrawalRequestId, note) {
    const changedWithdrawalRequest = this.withdrawals.find(
      (withdrawalRequest) => withdrawalRequest.id === withdrawalRequestId,
    );
    if (_.isNil(changedWithdrawalRequest)) {
      return;
    }
    changedWithdrawalRequest.note = note;
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    account: '<',
    customer: '<',
    config: '<',
  },
};
