import * as rx from '@proftit/rxjs';
import template from './tile-list.html';
import { PlatformTypesService } from '~/source/common/services/platforms-types';
import { PlatformTypeCode } from '@proftit/crm.api.models.enums';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { Brand, Customer } from '@proftit/crm.api.models.entities';

const PROP_PLATFORM_TYPE_CODE = PlatformTypeCode.Prop;

class TileList {
  lifecycles = observeComponentLifecycles(this);
  customer: Customer;
  isPropPlatformType: boolean = false;

  constructor(
    readonly appConfig: any,
    readonly platformTypesService: PlatformTypesService,
  ) {}

  $onInit() {
    this.setIsPropPlatformType();
  }

  $onChanges() {
    this.setIsPropPlatformType();
  }

  $onDestroy() {}

  setIsPropPlatformType() {
    this.isPropPlatformType =
      this.customer.brand.platformType.code === PROP_PLATFORM_TYPE_CODE;
  }
}

export default {
  template,
  controller: TileList,
  bindings: {
    customer: '<',
  },
  require: {
    prfCrmAppStoreProvider: '^',
    prfCustomerStoreProvider: '^',
    prfTranslationsProvider: '^',
  },
};
