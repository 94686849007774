import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import template from './challenge-erning.component.html';
import {
  CalculationMethod,
  ChallengeAsset,
  ChallengeEarning,
} from '@proftit/crm.api.models.entities/src';

// @ts-ignore
import styles from './challenge-erning.component.scss';

export class ChallengeEarningController {
  styles = styles;
  model: ChallengeEarning;
  lifecycles = observeComponentLifecycles(this);
  onUpdate: (value: any) => void;

  earningMethodOptions = [
    {
      value: 'percentage',
      label: 'Profit Split',
    },
    {
      value: 'fixed',
      label: 'Fixed prize',
    },
  ];
  /**
   * Updates the calculation type of the challenge earning model and triggers an update.
   * This method is responsible for setting the calculation type of the model based on the selected option
   * and then notifying any subscribers about the change through the onUpdate callback.
   *
   * @param {CalculationMethod} calculationType - The selected calculation method for the challenge earning.
   */
  onSelectCalculationType(calculationType: CalculationMethod) {
    this.model.calculationType = calculationType;
    this.onUpdate({ value: this.model });
  }

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const ChallengeEarningComponent = {
  controller: ChallengeEarningController,
  template: template,
  bindings: {
    model: '=',
    onUpdate: '&',
  },
};
