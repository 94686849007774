import * as _ from '@proftit/lodash';

import ModalsCacheService from '~/source/common/services/modals-cache.service';
import ModalInfo from '~/source/common/models/modal-info';
import ModalOpenState from '~/source/common/models/modal-open-state';
import CustomerComplianceRemovedClientPubsubService from '~/source/common/services/customer-compliance-removed-client-pubsub.service';

import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import {
  INTERNAL_TRANSFER_ADDED,
  COMMUNICATION_ADDED,
  CUSTOMER_LINK_CHANGED,
  CUSTOMER_UPDATED,
  ATTACHMENT_ADDED_TO_DEPOSIT,
  ATTACHMENT_REMOVED_FROM_DEPOSIT,
  CUSTOMER_REGULATION_FILE_UPDATED,
  COMMUNICATION_UPDATED,
  CALL_ONGOING,
} from '~/source/common/constants/general-pubsub-keys';
import { CommunicationTypesService } from '~/source/common/services/communication-types.service';
import { CommunicationsSubjectsService } from './communications-subjects.service';
import { IPromise } from 'angular';
import { Email } from '~/source/common/models/email';
import { EmailPreview } from '~/source/common/models/email-preview';
import { TradingAccountCashierDetails } from './trading-account-cashier-details';
import {
  TradingAccountDeposit,
  AccountType,
  Customer,
  TradingAccount,
  InternalTransfer,
  CustomerRegulationFile,
  ContentTemplate,
  TransactionTransferBonus,
  Credit,
  CustomerCommunication,
  CustomerSiteTrackingLocation,
  CustomerWeather,
} from '@proftit/crm.api.models.entities';
import RestService from '~/source/common/services/rest';
import { CommunicationMethodCode } from '@proftit/crm.api.models.enums';
import WithdrawalRequest from '~/source/common/models/withdrawal-request';
import { Communication } from '~/source/common/models/communication';
import download from 'downloadjs';
import { PrivateEwallet } from '~/source/contact/contact-page/trading-account/forex/private-ewallet-popup/private-ewallet.model';
type AllAccountType = AccountType | 'all';

const BATCH_ACTION_INDICATION = '~';
const SERVICE_RESOURCE = 'customers';
const TRADING_ACCOUNTS_RESOURCES = {
  binary: 'tradingAccountsBinary',
  forex: 'tradingAccountsForex',
  prop: 'tradingAccountsProp',
  all: 'tradingAccounts',
};
const STATS_RESOURCE = 'stats';
const WITHDRAWAL_REQUESTS_RESOURCE = 'withdrawalRequests';
const INTERNAL_TRANSFERS_RESOURCE = 'internalTransfers';
const FEE_RESOURCE = 'fees';
const INACTIVITY_FEE_RESOURCE = 'inactivity';
const WITHDRAWALS_RESOURCE = 'withdrawals';
const COMMUNICATIONS_RESOURCE = 'communications';
const LOGIN_HISTORY_RESOURCE = 'activityLogs';
const DEPOSITS_RESOURCE = 'deposits';
const INFO_AUDIT_LOG_RESOURCE = 'infoAuditLog';
const ATTACHMENT_AUDIT_LOG_RESOURCE = 'attachmentAuditLog';
const TRANSACTION_ASSIGNMENT_AUDIT_LOG_RESOURCE =
  'transactionAssignmentAuditLog';
const TRADING_ACCOUNT_AUDIT_LOG_RESOURCE = 'accountsAuditLog';
const CUSTOMER_PAGE_VIEWS_AUDIT_LOG_RESOURCE = 'userActivityAuditLog';
const CUSTOMER_ASSIGNMENT_AUDIT_LOG_RESOURCE = 'customerAssignmentAuditLog';
const BONUSES_RESOURCE = 'bonuses';
const RISK_FREE_RESOURCE = 'riskFreePositions';
const CREDIT_RESOURCE = 'credits';
const POSITIONS_RESOURCES = {
  binary: 'positionsBinary',
  forex: 'positionsForex',
  prop: 'positionsProp',
  all: 'positions',
};
const BUNDLES_RESOURCE = 'bundles';

const PENDING_ORDERS_RESOURCES = {
  forex: 'pendingOrders',
  prop: 'pendingOrders',
  all: 'pendingOrders',
};
const CREDIT_CARDS_RESOURCE = 'creditcards';
const ATTACHMENTS_RESOURCE = 'attachments';
const DOWNLOADS = 'downloads';
const ORIGINAL = 'original';
const COMPLIANCE_DOC_TYPE = 'complianceDocType';
const EMAILS_RESOURCE = 'emails';
const CASHIER_DETAILS_RESOURCE = 'cashierDetails';
const EMAIL_PREVIEW_RESOURCE = 'emailPreview';
const CONTENT_TEMPLATES_RESOURCE = 'contentTemplates';
const LINKS_RESOURCE = 'links';
const PHONE_VERIFICATION_RESOURCE = 'phoneVerification';
const LOCATIONS = 'locations';
const SWAP_PHONE_NUMBER_RESOURCE = 'swapPhoneNumbers';
const DOWNLOAD_COMMUNICATION_ARCHIVE = 'archivedCommunications';
const PROMO_CODES_RESOURCES = 'promoCodes';
const PRIVATE_EWALLET_RESOURCE = 'ewallets';
const GENERATE_ADDRESS = 'generateAddress';
const CHALLENGES_RESOURCE = 'challenges';
const PROFIT_SHARE_HISTORY_LOG = 'profitShareHistoryLogs';
export class CustomersService extends RestService {
  static $inject = [
    ...RestService.$inject,
    'modalsCacheService',
    'prfCustomerComplianceRemovedClientPubsub',
    'prfClientGeneralPubsub',
    'communicationTypesService',
    'communicationsSubjectsService',
  ];

  modalsCacheService: ModalsCacheService;
  prfCustomerComplianceRemovedClientPubsub: CustomerComplianceRemovedClientPubsubService;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  communicationTypesService: CommunicationTypesService;
  communicationsSubjectsService: CommunicationsSubjectsService;

  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource(): string {
    return SERVICE_RESOURCE;
  }

  /**
   * Build url to list of communications
   *
   * @param {int} customerId
   * @returns {RestService}
   */
  getCommunicationsResource(customerId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedCollection(COMMUNICATIONS_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Build url for download archive file
   *
   * @param {int} customerId
   * @returns {RestService}
   */

  getDownloadCommunicationArchiveResource(customerId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedCollection(DOWNLOAD_COMMUNICATION_ARCHIVE)
      .resourceBuildEnd();
  }

  getDownloadCommunicationArchive(customerId: number) {
    this.getDownloadCommunicationArchiveResource(customerId)
      .customGetWithConfig({ responseType: 'blob' })
      .then((res) => {
        this.downloadFile(res);
      });
  }

  downloadFile(data: object) {
    const fileName = this.lastResponseHeaders['content-disposition'];
    return download(
      data as any,
      fileName.match(/\s*=\s*([\S\s]+)/)[1],
      this.lastResponseHeaders['content-type'],
    );
  }

  /**
   * Get communication resource helper function.
   *
   * A wrapper to handle config, extand... so stubing is easier for specs.
   *
   * @param customer
   * @param config
   * @param expand
   * @param sort
   * @param filter
   * @return configurued rest service.
   */
  getCommunicationsResourceHelper(
    customer: Customer,
    config,
    expand,
    sort,
    filter,
  ) {
    return this.getCommunicationsResource(customer.id)
      .setConfig(config)
      .expand(expand)
      .filter(filter)
      .sort(sort);
  }

  swapPrimaryAndSecondaryPhoneNumbers(customerId: number) {
    return this.resourceBuildStart()
      .getCustomerResource(customerId, false)
      .getNestedCollection(SWAP_PHONE_NUMBER_RESOURCE)
      .resourceBuildEnd()
      .postWithQuery({})
      .then((res) => res.plain());
  }

  /**
   * Update customer's isActive property
   *
   * @param customerId
   * @param isActive
   * @returns
   */
  updateIsActive(customerId: number, isActive: boolean) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .resourceBuildEnd()
      .patchWithQuery({
        isActive,
      })
      .then((data) => data.plain())
      .then((newCustomer) => {
        this.prfClientGeneralPubsub.publish(CUSTOMER_UPDATED, newCustomer);
        return newCustomer;
      });
  }

  /**
   * End call by setting its 'isActive' property to 'false'
   * @param {object} call - call to end
   * @return {Promise} resolved to the server response
   */
  endCall(call: any) {
    return this.resourceBuildStart()
      .getElement(call.customer.id)
      .getNestedElement(COMMUNICATIONS_RESOURCE, call.id)
      .resourceBuildEnd()
      .patchWithQuery({
        isActive: false,
      });
  }

  /**
   * Add new call to customer
   *
   * @param {int} customerId
   * @param {object} call
   * @returns {*}
   */
  addCall(customerId: number, call: any) {
    return this.communicationTypesService
      .getCommTypeCall()
      .then((commTypeCall) => {
        return this.rest.one(customerId).post(COMMUNICATIONS_RESOURCE, {
          ...call,
          typeId: commTypeCall.id,
        });
      });
  }

  /**
   * Add new communication to customer
   *
   * @param {int} customerId
   * @param {object} call
   * @returns {*}
   */
  addCommunication(customerId: number, call: any) {
    return this.rest.one(customerId).post(COMMUNICATIONS_RESOURCE, {
      ...call,
    });
  }

  /**
   * Perform a new VoIP call
   * @param {number} customerId - customer id
   * @param {string} userPhoneNum - phone to call
   * @return {Promise} - resolves to created call record
   */
  makeVoipCall(
    customerId: number,
    voipProviderId: number,
    userPhoneNum: string,
    shouldUsePrimaryNumber: boolean,
  ) {
    return Promise.all([this.communicationTypesService.getCommTypeCall()]).then(
      ([typeCall]) => {
        const params: {
          method: string;
          userPhoneNum?: string;
          typeId: number;
          providerId: number;
          shouldUsePrimaryNumber: boolean;
        } = {
          shouldUsePrimaryNumber,
          providerId: voipProviderId,
          method: CommunicationMethodCode.Voip,
          typeId: typeCall.id,
        };

        if (userPhoneNum) {
          params.userPhoneNum = userPhoneNum;
        }

        return this.expand([
          'customer',
          'customer.country',
          'communicationStatus',
          'communicationSubject',
        ])
          .getCommunicationsResource(customerId)
          .postWithQuery(params)
          .then((createdCall) => {
            // Set modal as opened when it is newly created and window is the original handler.
            if (
              _.isNil(this.modalsCacheService.get(createdCall.id as string))
            ) {
              this.modalsCacheService.put(
                createdCall.id as string,
                ModalInfo.create({ openState: ModalOpenState.Open }),
              );
            }
            this.prfClientGeneralPubsub.publish(CALL_ONGOING, createdCall);
          });
      },
    );
  }

  /**
   * Sens sms communication.
   *
   * @param customerId
   * @param message
   * @param userPhoneNum - user phone number to be send from
   * @return promise of the action
   */
  sendSms(
    customerId: number,
    smsProviderId: number,
    message: string,
    userPhoneNum: string,
    shouldUsePrimaryNumber: boolean,
  ) {
    return Promise.all([this.communicationTypesService.getCommTypeSms()]).then(
      ([typeSms]) =>
        this.getCommunicationsResource(customerId)
          .postWithQuery({
            userPhoneNum,
            shouldUsePrimaryNumber,
            providerId: smsProviderId,
            typeId: typeSms.id,
            method: CommunicationMethodCode.Sms,
            details: message,
          })
          .then((comm) => {
            this.prfClientGeneralPubsub.publish(COMMUNICATION_ADDED, comm);
            return comm;
          }),
    );
  }

  /**
   * Add new deposit
   *
   * @param {int} customerId
   * @param {int} accountId
   * @param {object} deposit
   */
  addDeposit(customerId: number, accountId: number, deposit: any) {
    return this.getDepositsResource(customerId, accountId).rest.post(deposit);
  }

  /**
   * Attachments Linked To Deposit Resource
   */
  depositAttachmentsResource(
    customerId: number,
    accountId: number,
    depositId: number,
  ) {
    return this.getDepositResource(customerId, accountId, depositId, false)
      .getNestedCollection(ATTACHMENTS_RESOURCE)
      .resourceBuildEnd();
  }

  depositAttachmentResource(
    customerId: number,
    accountId: number,
    depositId: number,
    attachmentId: number,
  ) {
    return this.getDepositResource(customerId, accountId, depositId, false)
      .getNestedElement(ATTACHMENTS_RESOURCE, attachmentId)
      .resourceBuildEnd();
  }

  /**
   * Add file to Attachments with docType deposit
   * and Add Record to Deposit Attachments pivot table
   */
  attachFileToDeposit(
    customerId: number,
    accountId: number,
    depositId: number,
    fileId: number,
  ) {
    return this.depositAttachmentsResource(customerId, accountId, depositId)
      .postWithQuery({ fileId })
      .then((data) => {
        this.prfClientGeneralPubsub.publish(ATTACHMENT_ADDED_TO_DEPOSIT, data);
        return data;
      });
  }

  /**
   * Add Record to Deposit Attachments pivot table
   */
  addLinkAttachmentToDeposit(
    customerId: number,
    accountId: number,
    depositId: number,
    attachmentId: number,
  ) {
    return this.depositAttachmentsResource(customerId, accountId, depositId)
      .postWithQuery({ attachmentId })
      .then((data) => {
        this.prfClientGeneralPubsub.publish(ATTACHMENT_ADDED_TO_DEPOSIT, data);
        return data;
      });
  }

  /**
   * Delete Record from Deposit Attachments pivot table
   */
  deleteLinkAttachmentToDeposit(
    customerId: number,
    accountId: number,
    depositId: number,
    attachmentId: number,
  ) {
    return this.depositAttachmentResource(
      customerId,
      accountId,
      depositId,
      attachmentId,
    )
      .removeWithQuery()
      .then((data) => {
        this.prfClientGeneralPubsub.publish(
          ATTACHMENT_REMOVED_FROM_DEPOSIT,
          data,
        );
        return data;
      });
  }

  /**
   * Add new bonus
   *
   * @param {int} customerId
   * @param {int} accountId
   * @param {object} bonus
   */
  addBonus(customerId: number, accountId: number, bonus: any) {
    return this.getBonusesResource(customerId, accountId).rest.post(bonus);
  }

  /**
   * Cancels bonus by sending status code to server
   *
   * @param {int} customerId
   * @param {int} accountId
   * @param {int} bonusId
   * @returns {Promise}
   */
  cancelBonus(customerId: number, accountId: number, bonusId: number) {
    return this.getBonusResource(customerId, accountId, bonusId).patchWithQuery(
      {
        statusCode: 'canceled',
      },
    );
  }

  /**
   * Approve bonus by sending status code to server
   *
   * @param {int} customerId
   * @param {int} accountId
   * @param {int} bonusId
   * @returns {Promise}
   */
  approveBonus(customerId: number, accountId: number, bonusId: number) {
    return this.getBonusResource(customerId, accountId, bonusId).patchWithQuery(
      {
        statusCode: 'approved',
      },
    );
  }

  /**
   * Delete a withdrawal. Unlink cancel of a pending withdrawal, this cancel an
   * approved withdrawal.
   *
   * @param {number} cusotmerId: customer
   * @param {number} accountId: account
   * @param {number} withdrawalId: withdrawal
   * @return {Promise} promise of the server delete action
   */
  deleteWithdrawal(
    customerId: number,
    accountId: number,
    withdrawalId: number,
  ) {
    return this.getWithdrawalResource(
      customerId,
      accountId,
      withdrawalId,
    ).patchWithQuery({
      status: 'deleted',
    });
  }

  /**
   * Delete a fee.
   *
   * @param {number} cusotmerId: customer
   * @param {number} accountId: account
   * @param {number} feeId: fee
   * @return {Promise} promise of the server delete action
   */
  deleteFee(customerId: number, accountId: number, feeId: number) {
    return this.getFeeResource(customerId, accountId, feeId).patchWithQuery({
      statusCode: 'deleted',
    });
  }

  /**
   * Build url to list of trading account related to given customer
   *
   * @param {int} customerId
   * @param {string} type trading account type (binary/forex)
   * @return {RestService}
   */
  getAccountsResource(customerId: number, type: AllAccountType) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedCollection(TRADING_ACCOUNTS_RESOURCES[type])
      .resourceBuildEnd();
  }

  /**
   * Build url to list of a specific trading account type, related to given customer and account
   *
   * @param {number} customerId customer id
   * @param {string} type trading account type (binary/forex)
   * @param {number} accountId trading account id
   * @return {RestService} self instance, for chaining
   */
  getAccountResourceByType(
    customerId: number,
    type: AllAccountType,
    accountId: number,
  ) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedElement(TRADING_ACCOUNTS_RESOURCES[type], accountId)
      .resourceBuildEnd();
  }

  /**
   * Build url to list stats related to given customer
   *
   * @param {int} customerId
   * @param {string} statId
   * @return {RestService}
   */
  getStatsResource(customerId: number, statId: string) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedElement(STATS_RESOURCE, statId)
      .resourceBuildEnd();
  }

  /**
   * Build url for list of customer credit cards
   *
   * @param {int} customerId
   * @returns {RestService}
   */
  getCreditCardsResource(customerId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedCollection(CREDIT_CARDS_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Returns resource for specific account
   *
   * @param {int} customerId
   * @param {int} accountId
   * @param {boolean} endBuild - should resourceBuildEnd be called
   * @returns {RestService}
   */
  getAccountResource(
    customerId: number,
    accountId: number,
    endBuild: boolean = true,
    accountType: string = 'all',
  ) {
    const resource = this.resourceBuildStart()
      .getElement(customerId)
      .getNestedElement(TRADING_ACCOUNTS_RESOURCES[accountType], accountId);

    if (endBuild) {
      resource.resourceBuildEnd();
    }

    return resource;
  }

  /**
   * Returns resource for specific customer
   *
   * @param {int} customerId
   * @param {boolean} endBuild - should resourceBuildEnd be called
   * @returns {RestService}
   */
  getCustomerResource(customerId: number, endBuild: boolean = true) {
    const resource = this.resourceBuildStart().getElement(customerId);

    if (endBuild) {
      resource.resourceBuildEnd();
    }

    return resource;
  }

  /**
   * Build url to list of withdrawal requests related to given trading account
   *
   * @param {int} customerId
   * @param {int} accountId
   * @returns {RestService}
   */
  getWithdrawalRequestsResource(customerId: number, accountId: number) {
    return this.getAccountRelatedResource(
      customerId,
      accountId,
      WITHDRAWAL_REQUESTS_RESOURCE,
    );
  }

  /**
   * Build url to list of bundle related to given trading account
   *
   * @param {int} customerId
   * @param {int} accountId
   * @returns {RestService}
   */
  getBundlesResource(customerId: number, accountId: number) {
    return this.getAccountRelatedResource(
      customerId,
      accountId,
      BUNDLES_RESOURCE,
    );
  }

  getInternalTransfersResource(customerId: number, accountId: number) {
    return this.getAccountRelatedResource(
      customerId,
      accountId,
      INTERNAL_TRANSFERS_RESOURCE,
    );
  }

  /**
   * Build url to list of login logs requests
   *
   * @param {int} customerId
   * @returns {RestService}
   */
  getLoginHistoryRequestsResource(customerId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedCollection(LOGIN_HISTORY_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Build url for a list of all the withdrawals linked to a specific request
   * @param {int} customerId
   * @param {int} accountId
   * @param {int} withdrawalRequestId
   */
  getLinkedWithdrawalsResource(
    customerId: number,
    accountId: number,
    withdrawalRequestId: number,
  ) {
    return this.getAccountResource(customerId, accountId, false)
      .getNestedElement(WITHDRAWAL_REQUESTS_RESOURCE, withdrawalRequestId)
      .getNestedCollection(WITHDRAWALS_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Build url for a specific withdrawal request
   *
   * @param {int} customerId
   * @param {int} accountId
   * @param {int} withdrawalRequestId
   * @param {int} deskId
   */
  getWithdrawalRequestResource(
    customerId: number,
    accountId: number,
    withdrawalRequestId: number,
    deskId?: number,
  ) {
    return this.getAccountResource(customerId, accountId, false)
      .getNestedElement(WITHDRAWAL_REQUESTS_RESOURCE, withdrawalRequestId)
      .resourceBuildEnd();
  }

  /**
   * Build url for a specific withdrawal request
   *
   * @param {int} customerId
   * @param {int} accountId
   * @param {int} withdrawalRequestId
   */
  getInternalTransferResource(
    customerId: number,
    accountId: number,
    internalTransferId: number,
  ) {
    return this.getAccountResource(customerId, accountId, false)
      .getNestedElement(INTERNAL_TRANSFERS_RESOURCE, internalTransferId)
      .resourceBuildEnd();
  }

  /**
   * Build url for a specific withdrawal request
   *
   * @param {int} customerId
   * @param {int} accountId
   * @param {int} withdrawalRequestId
   */
  getBundleResource(customerId: number, accountId: number, bundleId: number) {
    return this.getAccountResource(customerId, accountId, false)
      .getNestedElement(BUNDLES_RESOURCE, bundleId)
      .resourceBuildEnd();
  }

  getWithdrawalResource(
    customerId: number,
    accountId: number,
    withdrawalId: number,
  ) {
    return this.getAccountResource(customerId, accountId, false)
      .getNestedElement(WITHDRAWALS_RESOURCE, withdrawalId)
      .resourceBuildEnd();
  }

  /**
   * build url to list of fees related to given trading account
   *
   * @param {int} customerId
   * @param {int} accountId
   */
  getFeesResource(customerId: number, accountId: number) {
    return this.getAccountRelatedResource(customerId, accountId, FEE_RESOURCE);
  }

  /**
   * Build url for a specific fee.
   *
   * @param {int} customerId
   * @param {int} accountId
   * @param {int} feeId
   * @return {Promise} rest service
   */
  getFeeResource(customerId: number, accountId: number, feeId: number) {
    return this.getAccountResource(customerId, accountId, false)
      .getNestedElement(FEE_RESOURCE, feeId)
      .resourceBuildEnd();
  }

  /**
   * Build url for a specific inactivity fee.
   *
   * The resource is a collection resource that as 0-1 items in the server.
   *
   * @param customerId
   * @param accountId
   * @param feeId
   * @return rest service promise
   */
  getInactivityFeeResource(
    customerId: number,
    accountId: number,
    feeId: number,
  ) {
    return this.getAccountResource(customerId, accountId, false)
      .getNestedElement(FEE_RESOURCE, feeId)
      .getNestedCollection(INACTIVITY_FEE_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Build url to list of positions related to given trading account
   *
   * @param {int} customerId
   * @param {string} type - account type: forex/binary
   * @param {int} accountId
   * @returns {RestService}
   */
  getPositionsResource(
    customerId: number,
    type: AllAccountType,
    accountId: number,
  ) {
    return this.getAccountRelatedResource(
      customerId,
      accountId,
      POSITIONS_RESOURCES[type],
    );
  }

  /**
   * Build url to list of pending positions related to given trading account
   *
   * @param {int} customerId
   * @param {string} type - account type: forex/binary
   * @param {int} accountId
   * @returns {RestService}
   */
  getPendingOrdersResource(
    customerId: number,
    type: AllAccountType,
    accountId: number,
  ) {
    return this.getAccountRelatedResource(
      customerId,
      accountId,
      PENDING_ORDERS_RESOURCES[type],
    );
  }

  /**
   * Build url to list of deposits related to given trading account
   *
   * @param {int} customerId
   * @param {int} accountId
   * @returns {RestService}
   */
  getDepositsResource(customerId: number, accountId: number) {
    return this.getAccountRelatedResource(
      customerId,
      accountId,
      DEPOSITS_RESOURCE,
    );
  }

  /**
   * Build url to list of info audit logs related to given customer
   *
   * @param {int} customerId
   * @returns {RestService}
   */
  getInfoAuditLogResource(customerId: number) {
    return this.getCustomerRelatedResource(customerId, INFO_AUDIT_LOG_RESOURCE);
  }

  /**
   * Build url to list of attachment audit logs related to given customer
   *
   * @param {int} customerId
   * @returns {RestService}
   */
  getAttachmentAuditLogResource(customerId: number) {
    return this.getCustomerRelatedResource(
      customerId,
      ATTACHMENT_AUDIT_LOG_RESOURCE,
    );
  }

  /**
   * Build url to list of trading account audit logs related to given customer
   *
   * @param {int} customerId
   * @returns {RestService}
   */
  getTradingAccountAuditLogResource(customerId: number) {
    return this.getCustomerRelatedResource(
      customerId,
      TRADING_ACCOUNT_AUDIT_LOG_RESOURCE,
    );
  }

  /**
   * Build url to list of customer page views audit logs related to given customer
   *
   * @param {int} customerId
   * @returns {RestService}
   */
  getCustomerPageViewsAuditLogResource(customerId: number) {
    return this.getCustomerRelatedResource(
      customerId,
      CUSTOMER_PAGE_VIEWS_AUDIT_LOG_RESOURCE,
    );
  }

  /**
   * Build url to list of assignment audit logs related to given customer
   *
   * @param {int} customerId
   * @returns {RestService}
   */
  getAssignmentAuditLogResource(customerId: number) {
    return this.getCustomerRelatedResource(
      customerId,
      CUSTOMER_ASSIGNMENT_AUDIT_LOG_RESOURCE,
    );
  }

  /**
   * Build url to list of withdrawal audit logs related to given customer
   *
   * @param {int} customerId
   * @returns {RestService}
   */
  getWithdrawalAuditLogResource(customerId: number) {
    return this.getCustomerRelatedResource(
      customerId,
      TRANSACTION_ASSIGNMENT_AUDIT_LOG_RESOURCE,
    ).filter({ model: 'TradingAccountWithdrawal' });
  }

  /**
   * Build url to list of desposit audit logs related to given customer
   *
   * @param {int} customerId
   * @returns {RestService}
   */
  getDepositAuditLogResource(customerId: number) {
    return this.getCustomerRelatedResource(
      customerId,
      TRANSACTION_ASSIGNMENT_AUDIT_LOG_RESOURCE,
    ).filter({ model: 'TradingAccountDeposit' });
  }

  /**
   * Build url to list of bonuses related to given trading account
   *
   * @param {int} customerId
   * @param {int} accountId
   * @returns {RestService}
   */
  getBonusesResource(customerId: number, accountId: number) {
    return this.getAccountRelatedResource(
      customerId,
      accountId,
      BONUSES_RESOURCE,
    );
  }

  /**
   * Build url to list of risk-frees related to given trading account
   *
   * @param {int} customerId
   * @param {int} accountId
   * @returns {RestService}
   */
  getRiskFreePositionsResource(customerId: number, accountId: number) {
    return this.getBinaryAccountRelatedResource(
      customerId,
      accountId,
      RISK_FREE_RESOURCE,
    );
  }

  /**
   * Build url to list of credits related to given trading account
   *
   * @param {int} customerId
   * @param {int} accountId
   * @returns {RestService}
   */
  getCreditsResource(customerId: number, accountId: number) {
    return this.getAccountResource(customerId, accountId, false)
      .getNestedCollection(CREDIT_RESOURCE)
      .resourceBuildEnd();
  }

  getCreditResource(customerId: number, accountId: number, creditId: number) {
    return this.getAccountResource(customerId, accountId, false)
      .getNestedElement(CREDIT_RESOURCE, creditId)
      .resourceBuildEnd();
  }

  /**
   *
   * @param {int} customerId
   * @param {int} accountId
   * @param {int} depositId
   */
  getDepositResource(
    customerId: number,
    accountId: number,
    depositId: number,
    endBuild: boolean = true,
  ) {
    const resource = this.resourceBuildStart()
      .getElement(customerId)
      .getNestedElement(TRADING_ACCOUNTS_RESOURCES.all, accountId)
      .getNestedElement(DEPOSITS_RESOURCE, depositId);

    if (endBuild) {
      resource.resourceBuildEnd();
    }

    return resource;
  }

  /**
   * Get deposit
   *
   * @param {int} customerId
   * @param {int} accountId
   * @param {int} depositId
   */
  getDeposit(customerId: number, accountId: number, depositId: number) {
    return this.getDepositResource(customerId, accountId, depositId)
      .getOneWithQuery<IElementRestNg<TradingAccountDeposit>>()
      .then((data) => data.plain());
  }

  /**
   *
   * @param {int} customerId
   * @param {int} accountId
   * @param {int} bonusId
   */
  getBonusResource(customerId: number, accountId: number, bonusId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedElement(TRADING_ACCOUNTS_RESOURCES.all, accountId)
      .getNestedElement(BONUSES_RESOURCE, bonusId)
      .resourceBuildEnd();
  }

  /**
   *
   * Build url for related to trading account resources
   *
   * @param {int} customerId
   * @param {int} accountId
   * @param {string} resource
   * @returns {RestService}
   * @private
   */
  private getAccountRelatedResource(
    customerId: number,
    accountId: number,
    resource: string,
  ) {
    return this.getAccountResource(customerId, accountId, false)
      .getNestedCollection(resource)
      .resourceBuildEnd();
  }

  /**
   *
   * Build url for related to Customer resources
   *
   * @param {int} customerId
   * @param {string} resource
   * @returns {RestService}
   * @private
   */
  private getCustomerRelatedResource(customerId: number, resource: string) {
    return this.getCustomerResource(customerId, false)
      .getNestedCollection(resource)
      .resourceBuildEnd();
  }

  /**
   *
   * Build url for related to trading account resources
   *
   * @param {int} customerId
   * @param {int} accountId
   * @param {string} resource
   * @returns {RestService}
   * @private
   */
  private getBinaryAccountRelatedResource(
    customerId: number,
    accountId: number,
    resource: string,
  ) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedElement(TRADING_ACCOUNTS_RESOURCES.binary, accountId)
      .getNestedCollection(resource)
      .resourceBuildEnd();
  }

  /**
   * Makes a PATCH request to update the specific customer
   * @param {number} customerId
   * @param {object} data
   * @return {Promise}
   */
  updateCustomer(customerId: number, data: any): Promise<Customer> {
    return this.resourceBuildStart()
      .getElement(customerId)
      .resourceBuildEnd()
      .patchWithQuery<IElementRestNg<Customer>>(data)
      .then((data) => data.plain());
  }

  /**
   *  Build url for list of customer compliance existing doc types
   *
   * @param {int} customerId
   * @returns {RestService}
   */
  getCustomerComplianceDocTypes(customerId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedCollection(COMPLIANCE_DOC_TYPE)
      .resourceBuildEnd();
  }

  /**
   *  Build url for list of customer attachments
   *
   * @param {int} customerId
   * @returns {RestService}
   */
  getAttachmentsResource(customerId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedCollection(ATTACHMENTS_RESOURCE)
      .resourceBuildEnd();
  }

  getPrivateAttachmentResource(customerId: number, attachmentId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedElement(ATTACHMENTS_RESOURCE, attachmentId)
      .getNestedElement(DOWNLOADS, ORIGINAL)
      .resourceBuildEnd();
  }

  /**
   * Get weather for customer
   * @param {number} customerId
   * @return {Promise}
   */
  getWeather(customerId: number): Promise<CustomerWeather> {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getElement('weather')
      .resourceBuildEnd()
      .getOneWithQuery<IElementRestNg<CustomerWeather>>()
      .then((data) => data.plain());
  }

  /**
   * Generate login token for the given customer id
   * @param {number} customerId
   * @return {restPromise<restangular.IElement>}
   */
  generateToken(customerId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedCollection('token')
      .resourceBuildEnd()
      .postWithQuery<any>({});
  }

  /**
   * Get news for customer
   * @param {number} customerId
   * @return {Promise}
   */
  getNews(customerId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getElement('news')
      .resourceBuildEnd()
      .getOneWithQuery<any>();
  }

  /**
   * Get related cashier for the account.
   *
   * @param {number} customerId - Customer id
   * @param {number} tradingAccountId - Trading account id
   * @return {Promise} Promise that resolve to the cashier details retrival results.
   */
  getCashierDetails(customerId, tradingAccountId) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedElement(TRADING_ACCOUNTS_RESOURCES.all, tradingAccountId)
      .getElement(CASHIER_DETAILS_RESOURCE)
      .resourceBuildEnd()
      .getOneWithQuery<IElementRestNg<TradingAccountCashierDetails>>();
  }

  /**
   *  Build url for getting email preview per customer and template
   *
   * @param customerId
   * @param contentTemplateId
   * @returns {RestService}
   */
  getEmailPreviewResource(customerId: number, contentTemplateId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedElement(EMAIL_PREVIEW_RESOURCE, contentTemplateId)
      .resourceBuildEnd();
  }

  /**
   * Get email preview for customer and template.
   *
   * @param customerId
   * @param contentTemplateId
   * @return work promise of generated preview
   */
  getEmailPreview(customerId: number, contentTemplateId: number) {
    return this.getEmailPreviewResource(
      customerId,
      contentTemplateId,
    ).getOneWithQuery<IElementRestNg<EmailPreview>>();
  }

  /**
   *  Build url for list of customer attachments
   *
   * @param {int} customerId
   * @returns {RestService}
   */
  getEmailsResource(customerId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedCollection(EMAILS_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   *  Build url for getting email preview per customer and template
   *
   * @param customerId
   * @param contentTemplateId
   * @returns {RestService}
   */
  getContentTemplatesResource(customerId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedCollection(CONTENT_TEMPLATES_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Get links resource.
   *
   * @param customerId
   * @return configured rest instance
   */
  getLinksResource(customerId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedCollection(LINKS_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Get customer links list as customers
   *
   * @param customerId
   * @returns linked customers
   */
  getCustomerLinks(customerId: number) {
    return this.getLinksResource(customerId)
      .getListWithQuery<IElementRestNg<Customer[]>>()
      .then((data) => data.plain());
  }

  /**
   * Get is customer Has Links
   *
   * @param customerId
   * @returns indication if customer has links
   */
  getIsCustomerHasLinks(customerId: number) {
    return this.getLinksResource(customerId)
      .slice(0, 1, 5)
      .getListWithQuery<IElementRestNg<Customer[]>>()
      .then((data) => data.length > 0);
  }

  /**
   * Get content templates allowed for customer.
   *
   * @param customerId
   * @return work promise
   */
  getContentTemplates(customerId: number) {
    return this.getContentTemplatesResource(customerId)
      .getListWithQuery<IElementRestNg<ContentTemplate>>()
      .then((data) => data.plain());
  }

  /**
   * Get content templates allowed for customer by language.
   */
  getContentTemplatesByLanguage(customerId: number, languageId: number) {
    return this.getContentTemplatesResource(customerId)
      .filter({ languageId })
      .getListWithQuery<IElementRestNg<ContentTemplate>>()
      .then((data) => data.plain());
  }

  /**
   * Create email for customer
   *
   * @param customerId
   * @param email
   * @return work promise
   */
  createEmail(customerId: number, email: Email) {
    return this.getEmailsResource(customerId).postWithQuery<
      IElementRestNg<Email>
    >(email);
  }

  /**
   *
   */
  removeEntityCompliance(entity) {
    return entity
      .remove()
      .then(() =>
        this.prfCustomerComplianceRemovedClientPubsub.publish(entity),
      );
  }

  /**
   * Create internal transfer and notify system of creation.
   */
  createInternalTransfer(
    customerId: number,
    accountId: number,
    normalizedModel,
  ) {
    return this.getInternalTransfersResource(customerId, accountId)
      .postWithQuery<IElementRestNg<InternalTransfer>>(normalizedModel)
      .then((data) => {
        this.prfClientGeneralPubsub.publish(INTERNAL_TRANSFER_ADDED, data);
        return data;
      });
  }

  /**
   * get the last call made
   * @param customerId - customers id
   */
  getLastCall(customerId: number): Promise<string> {
    return this.communicationTypesService
      .getCommTypeCall()
      .then((commTypeCall) => {
        return this.getCommunicationsResource(customerId)
          .sort({ date: 'desc' })
          .filter({
            typeId: commTypeCall.id,
            method: CommunicationMethodCode.Voip,
          })
          .slice(0, undefined, 1)
          .getListWithQuery()
          .then((calls) => calls.plain())
          .then((calls) => {
            if (calls.length > 0) {
              return calls[0];
            }
            return null;
          });
      });
  }

  getLastCommunication(customerId: number): Promise<CustomerCommunication> {
    return this.getCommunicationsResource(customerId)
      .expand(['type'])
      .sort('date', 'desc')
      .slice(0, 1, 1)
      .getListWithQuery()
      .then((data) => data.plain())
      .then((communications) => {
        if (communications.length > 0) {
          return communications[0];
        }
        return null;
      });
  }

  /**
   *
   */
  createCustomer(customer: Customer) {
    return this.postWithQuery(customer);
  }

  /**
   * Update account.
   *
   * Helper function to do in functinal style the account instead of
   * rest duck typing patch method.
   *
   * @param customerId
   * @param accountType
   * @param accountId
   * @param newData
   */
  updateAccount(
    customerId: number,
    accountType: AccountType,
    accountId: number,
    newData: Partial<TradingAccount>,
  ) {
    return this.getAccountResourceByType(
      customerId,
      accountType,
      accountId,
    ).patchWithQuery(newData);
  }

  /**
   * Get customer
   *
   * @param customerId
   * @returns work promise
   */
  getCustomer(customerId: number) {
    return this.getOneWithQuery<IElementRestNg<Customer>>(
      customerId,
    ).then((data) => data.plain());
  }

  /**
   * Update customer links
   *
   * @param customerId
   * @param links
   * @returns work promise
   */
  updateCustomerLinks(customerId: number, links) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedElement(LINKS_RESOURCE, BATCH_ACTION_INDICATION)
      .resourceBuildEnd()
      .customPutWithQuery(links)
      .then((results) => {
        this.prfClientGeneralPubsub.publish(CUSTOMER_LINK_CHANGED, results);
        return results;
      });
  }

  getLastAttachmentData(
    customerId: number,
    growlId: string,
    blockUiId: string,
    complianceDocTypeId: number,
  ) {
    return this.getAttachmentsResource(customerId)
      .setConfig({
        growlRef: growlId,
        blockUiRef: blockUiId,
      })
      .slice(0, null, 1)
      .filter({
        complianceDocTypeId,
      })
      .sort({ uploadedAt: 'desc' })
      .getListWithQuery<IElementRestNg<CustomerRegulationFile>>();
  }

  addAttachment(
    customerId: number,
    fileId: number,
    complianceDocTypeId: number,
  ): Promise<IElementRestNg<CustomerRegulationFile>> {
    return this.getAttachmentsResource(customerId).postWithQuery<
      IElementRestNg<CustomerRegulationFile>
    >({
      fileId,
      complianceDocTypeId,
    });
  }

  patchDeposit(
    customerId: number,
    tradingAccountId: number,
    depositId: number,
    changes: Partial<TradingAccountDeposit>,
  ): Promise<TradingAccountDeposit> {
    return this.getDepositResource(customerId, tradingAccountId, depositId)
      .patchWithQuery<IElementRestNg<TradingAccountDeposit>>({
        id: depositId,
        ...changes,
      })
      .then((res) => res.plain());
  }

  patchWithdrawalRequest(
    customerId: number,
    tradingAccountId: number,
    withdrawalRequestId: number,
    changes: Partial<WithdrawalRequest>,
  ): Promise<WithdrawalRequest> {
    return this.getWithdrawalRequestResource(
      customerId,
      tradingAccountId,
      withdrawalRequestId,
    )
      .patchWithQuery({
        id: withdrawalRequestId,
        ...changes,
      })
      .then((res) => res.plain());
  }

  patchCredit(
    customerId: number,
    tradingAccountId: number,
    creditId: number,
    changes: Credit,
  ): Promise<Credit> {
    return this.getCreditResource(customerId, tradingAccountId, creditId)
      .patchWithQuery({
        id: creditId,
        ...changes,
      })
      .then((res) => res.plain());
  }

  patchBonus(
    customerId: number,
    tradingAccountId: number,
    bonusId: number,
    changes: Partial<TransactionTransferBonus>,
  ): Promise<TransactionTransferBonus> {
    return this.getBonusResource(customerId, tradingAccountId, bonusId)
      .patchWithQuery({
        id: bonusId,
        ...changes,
      })
      .then((res) => res.plain());
  }

  getCommunicationPreviewResource(customerId: number, communicationId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedElement('communicationPreviews', communicationId)
      .resourceBuildEnd();
  }

  getCommunicationPreview(customerId: number, communicationId: number) {
    return this.getCommunicationPreviewResource(
      customerId,
      communicationId,
    ).getOneWithQuery<IElementRestNg<Communication>>();
  }

  getPhoneVerificationResource() {
    return this.resourceBuildStart()
      .getElement(PHONE_VERIFICATION_RESOURCE)
      .resourceBuildEnd();
  }

  sendVerificationCode(customerId: number, sendMethod: string) {
    return this.getPhoneVerificationResource().customPostWithQuery({
      customerId,
      sendMethod,
    });
  }

  getCommunicationResource(
    customerId: number,
    communicationId: number,
    endBuild: boolean = true,
  ) {
    const resource = this.resourceBuildStart()
      .getElement(customerId)
      .getNestedElement(COMMUNICATIONS_RESOURCE, communicationId);

    if (endBuild) {
      resource.resourceBuildEnd();
    }

    return resource;
  }

  updateCommunication(
    customerId: number,
    communicationId: number,
    changes: Partial<CustomerCommunication>,
  ): Promise<CustomerCommunication> {
    return this.getCommunicationResource(customerId, communicationId)
      .patchWithQuery<IElementRestNg<CustomerCommunication>>({
        id: communicationId,
        ...changes,
      })
      .then((communicationResult) => {
        this.prfClientGeneralPubsub.publish(
          COMMUNICATION_UPDATED,
          communicationResult,
        );

        return communicationResult;
      });
  }

  getLastSiteTrackingLocations(
    customerId: number,
  ): Promise<CustomerSiteTrackingLocation[]> {
    return this.getCustomerRelatedResource(
      customerId,
      LOCATIONS,
    ).getListWithQuery<IElementRestNg<CustomerSiteTrackingLocation>>();
  }

  /**
   *
   * @param {int} customerIdNestedElement
   */
  getPromoCodesResource(customerId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedCollection(PROMO_CODES_RESOURCES)
      .resourceBuildEnd();
  }

  updatePromoCode(customerId: number, id: number, data) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedElement(PROMO_CODES_RESOURCES, id)
      .resourceBuildEnd()
      .patchWithQuery(data);
  }

  getAccountEwalletResource(customerId: number, accountId: number) {
    return this.getAccountRelatedResource(
      customerId,
      accountId,
      PRIVATE_EWALLET_RESOURCE,
    );
  }

  /**
   * Generates a new private eWallet address for a specific customer and account.
   *
   * @param {number} customerId - The ID of the customer for whom to generate a new eWallet address.
   * @param {number} accountId - The ID of the account for which to generate a new eWallet address.
   * @param {number} providerId - The ID of the eWallet provider.
   * @param {number} currencyId - The ID of the currency for the eWallet.
   * @returns {Promise} A promise that resolves to the server's response. The response should contain the generated eWallet address.
   */
  generatePrivateEwalletAddress(
    customerId: number,
    accountId: number,
    providerId: number,
    currencyId: number,
  ) {
    return this.getAccountRelatedResource(
      customerId,
      accountId,
      GENERATE_ADDRESS,
    ).postWithQuery({
      providerId,
      currencyId,
    });
  }

  createPrivateEwallet(
    customerId: number,
    accountId: number,
    privateEwallet: PrivateEwallet,
  ) {
    return this.getAccountEwalletResource(customerId, accountId).postWithQuery(
      privateEwallet,
    );
  }

  removePrivateEwallet(customerId: number, accountId: number, id: number) {
    return this.getAccountResource(customerId, accountId, false)
      .getNestedElement(PRIVATE_EWALLET_RESOURCE, id)
      .resourceBuildEnd()
      .removeWithQuery();
  }

  getCustomerChallenges(customerId: number) {
    return this.resourceBuildStart()
      .getElement(customerId)
      .getNestedCollection(CHALLENGES_RESOURCE)
      .embed(['phases'])
      .expand(['currency', 'phases.tradingAccount'])
      .resourceBuildEnd()
      .getListWithQuery();
  }

  getAccountProfitShareHistory(customerId: number, accountId: number) {
    return this.getAccountResource(customerId, accountId, false)
      .getNestedCollection(PROFIT_SHARE_HISTORY_LOG)
      .resourceBuildEnd()
      .getListWithQuery();
  }
}

export default CustomersService;
