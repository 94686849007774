import { Entity } from '@proftit/crm.api.models.general';
import { PlatformCode } from '@proftit/crm.api.models.enums';

export interface Platform extends Entity {
  typeId: number;
  code: PlatformCode;
  name: string;
  tradingCode: string;
  isMigratable: boolean;
  deletedAt: any;
}

export const ALLOWED_PLATFORMS_TYPE_CODES = ['forex', 'prop'];
