import template from './purchase-linked-documents-popup.component.html';

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import TableController from '~/source/common/components/table/table.controller';
import CustomersService from '~/source/contact/common/services/customers';
import { CustomerRegulationFile } from '@proftit/crm.api.models.entities';
import ICollectionRestNg from '~/source/common/models/icollection-rest-ng';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import type { ILightbox } from '~/source/types/ilightbox';
import lightBoxTemplate from 'angular-bootstrap-lightbox/src/lightbox.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { PrivateGoogleStorageFileService } from '~/source/common/services/private-google-storage-file.service';
import { purchaseLinkedDocumentsPopupSettings } from '~/source/contact/contact-page/prop-account/purchase-linked-documents-popup/purchase-linked-documents-popup.settings';
import { purchaseLinkedDocumentsPopupColumns } from '~/source/contact/contact-page/prop-account/purchase-linked-documents-popup/purchase-linked-documents-popup.cols';

interface ComponentBindings {
  customerId: number;
  purchaseId: number;
  accountId: number;
}

interface DataInfo {
  isEdit: boolean;
  viewItem: any;
  editItem?: any;
}

const defaultViewRenderer = {
  type: 'general',
};

export class PurchaseLinkedDocumentsPopupController extends TableController
  implements ComponentBindings {
  static $inject = [
    'customersService',
    'Lightbox',
    '$window',
    'privateGoogleStorageFileService',
    ...TableController.$inject,
  ];

  growlRefId = generateGrowlId();
  blockUiId = generateBlockuiId();

  lifecycles = observeComponentLifecycles(this);

  customerId: number;
  purchaseId: number;
  accountId: number;

  close: ($value: any) => void;
  dismiss: ($value: any) => void;

  customersService: () => CustomersService;

  // @ts-ignore
  dataServiceInstance = this.customersService();

  Lightbox: ILightbox;
  $window: ng.IWindowService;
  viewAttachmentAction = new rx.Subject<CustomerRegulationFile>();
  viewPDFAttachmentAction = new rx.Subject<CustomerRegulationFile>();
  viewImageAttachmentAction = new rx.Subject<CustomerRegulationFile>();
  privateGoogleStorageFileService: PrivateGoogleStorageFileService;

  attachments: ICollectionRestNg<CustomerRegulationFile>;
  dataInfos: {
    [key: string]: DataInfo;
  } = {};

  cols = [...purchaseLinkedDocumentsPopupColumns];
  settings = { ...purchaseLinkedDocumentsPopupSettings };

  constructor(...args) {
    super(...args);
    useStreams(
      [
        this.streamViewAttachment(),
        this.streamViewPDFAttachment(),
        this.streamViewImageAttachment(),
      ],
      this.lifecycles.onDestroy$,
    );

    this.initTable();
  }

  $onInit() {
    super.$onInit();
  }

  $onDestroy() {}

  $onChanges() {}

  /**
   * modal dialog component gets passed data by the 'resolve' binding parameter.
   * We use a setter to automatically extract the incoming value and destructur
   * it to the different values passed.
   */
  set resolve(val: ComponentBindings) {
    if (_.isNil(val)) {
      return;
    }
    this.customerId = val.customerId;
    this.accountId = val.accountId;
    this.purchaseId = val.purchaseId;
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  fetchFn() {
    return this.dataServiceInstance
      .depositAttachmentsResource(
        this.customerId,
        this.accountId,
        this.purchaseId,
      )
      .setConfig({
        growlRef: this.growlRefId,
        blockUiRef: this.blockUiId,
      })
      .expand([
        'attachment',
        'attachment.file',
        'attachment.complianceDocType',
      ]);
  }

  parseLoadedData(data) {
    this.attachments = data.map((row) => row.attachment);
    this.dataInfos = this.calcDataInfos(data.plain()) as any;

    return data;
  }

  calcDataInfos(data: { id: number; attachment: CustomerRegulationFile }[]) {
    return _.flow(
      () => _.keyBy((item) => item.id, data),
      (hash) => _.mapValues((item) => this.calcDataInfo(item.attachment), hash),
    )();
  }

  calcDataInfo(item: CustomerRegulationFile): DataInfo {
    const tempViewCols = this.cols.map((col) => {
      const modelInit = _.get('renderers.view.modelInit', col);
      let value;

      if (_.isNil(modelInit)) {
        value = item[col.fieldName];
      } else {
        value = modelInit(item[col.fieldName]);
      }

      return { value, name: col.fieldName };
    });
    const viewItem = _.flow(
      () => _.keyBy((x) => x.name, tempViewCols),
      (x) => _.mapValues((val) => val.value, x),
    )();

    return {
      viewItem,
      isEdit: false,
    };
  }

  getRowRenderer(row, col) {
    const editRenderer = this.dataInfos[row.id].isEdit
      ? _.get(['renderers', 'edit'], col)
      : null;
    let viewRenderer = _.get(['renderers', 'view'], col);
    if (_.isNil(viewRenderer)) {
      viewRenderer = defaultViewRenderer;
    }

    return editRenderer ? editRenderer : viewRenderer;
  }

  streamViewAttachment() {
    return rx.pipe(
      () => this.viewAttachmentAction,
      rx.tap((attachment: CustomerRegulationFile) => {
        if (attachment.file.type === 'application/pdf') {
          this.viewPDFAttachmentAction.next(attachment);
        } else {
          this.viewImageAttachmentAction.next(attachment);
        }
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamViewPDFAttachment() {
    return rx.pipe(
      () => this.viewPDFAttachmentAction,
      rx.switchMap((attachment) => {
        return this.privateGoogleStorageFileService.getAttachmentFileUrl(
          attachment.customerId,
          attachment.id,
          this.blockUiId,
        );
      }),
      rx.tap((attachmentFileUrl: string) => {
        this.$window.open(attachmentFileUrl, '_blank');
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamViewImageAttachment() {
    return rx.pipe(
      () => this.viewImageAttachmentAction,
      rx.map((attachment) => {
        const displayableAttachments = _.filter(
          (item: CustomerRegulationFile) =>
            item.file.type !== 'application/pdf',
          this.attachments,
        );
        const index = _.findIndex(
          (item: CustomerRegulationFile) => item.id === attachment.id,
          displayableAttachments,
        );
        return {
          displayableAttachments,
          index,
        };
      }),
      rx.filter(({ displayableAttachments, index }) => index >= 0),
      rx.switchMap(({ displayableAttachments, index }) => {
        const attachmentUrlObservables = displayableAttachments.map(
          (localAttachment) => {
            return this.privateGoogleStorageFileService.getAttachmentFileUrl(
              localAttachment.customerId,
              localAttachment.id,
              this.blockUiId,
            );
          },
        );
        return rx.obs.combineLatest(attachmentUrlObservables).pipe(
          rx.map((fileUrls) => {
            return {
              fileUrls,
              index,
            };
          }),
        );
      }),
      rx.tap(({ index, fileUrls }: { index: number; fileUrls: any[] }) => {
        this.Lightbox.openModal(fileUrls, index, {
          templateurl: null,
          template: lightBoxTemplate,
        });
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const PurchaseLinkedDocumentsPopupComponent = {
  controller: PurchaseLinkedDocumentsPopupController,
  template,
  controllerAs: 'vm',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
    modalInstance: '<',
  },
};
