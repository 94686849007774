import { MT4_PLATFORMS } from '@proftit/crm.api.models.enums';
import { Permissions } from '~/source/common/models/permission-structure';

export const dashboardSettings = {
  contactsTable: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        filter: {
          isDemo: false,
        },
        sorting: {
          registrationDate: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      newContacts: {},
      onlineContacts: {},
      realContacts: {
        state: {
          isActive: true,
        },
      },
      propFundedContacts: {},
    },
    colsList: [
      {
        title: '',
        field: 'isOnline',
        fieldName: 'isOnline',
        reportTitle: 'contact.IS_ONLINE',
        reportFields: ['isOnline'],
        sortable: 'isOnline',
        nonEditable: true,
        removable: false,
        show: true,
        type: 'common',
        className: 'table__activity',
      },
      {
        title: '',
        field: 'selector',
        fieldName: 'selector',
        nonEditable: true,
        removable: false,
        type: 'common',
        show: false,
        className: 'table__checkbox',
      },
      {
        title: 'contact.dashboard.titles.REGISTRATION_DATE',
        field: 'registrationDate',
        fieldName: 'registrationDate',
        reportFields: ['registrationDate'],
        filterable: true,
        sortable: 'registrationDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.ID',
        field: 'id',
        fieldName: 'id',
        reportFields: ['id'],
        filterable: false,
        sortable: 'id',
        removable: true,
        show: true,
        type: 'common',
        className: 'prf-nowrap',
      },
      {
        title: 'contact.dashboard.titles.FIRST_NAME',
        field: 'firstName',
        fieldName: 'firstName',
        reportFields: ['firstName'],
        filterable: false,
        sortable: 'firstName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_NAME',
        field: 'lastName',
        fieldName: 'lastName',
        reportFields: ['lastName'],
        filterable: false,
        sortable: 'lastName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.PHONE',
        field: 'phone',
        fieldName: 'phone',
        fieldFormat: '{{ countryPrefix }} {{ phone }}',
        reportFields: ['countryPrefix', 'phone'],
        filterable: false,
        sortable: 'phone',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.PHONE2',
        field: 'phone2',
        fieldName: 'phone2',
        fieldFormat: '{{ countryPrefix2 }} {{ phone2 }}',
        reportFields: ['countryPrefix2', 'phone2'],
        filterable: false,
        sortable: 'phone2',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.EMAIL',
        field: 'email',
        fieldName: 'email',
        reportFields: ['email'],
        filterable: false,
        sortable: 'email',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.COUNTRY',
        field: 'country',
        fieldName: 'country',
        reportFields: ['country.name'],
        filterable: true,
        sortable: 'countryId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.LANGUAGE',
        field: 'language',
        fieldName: 'language',
        fieldFormat:
          '{{ language.code2 ? (("lang." + language.code2) | translate) : "" }}',
        filterable: true,
        removable: true,
        show: true,
        sortable: 'languageId',
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.CAMPAIGN',
        field: 'campaign',
        fieldName: 'campaign',
        reportFields: ['campaign.name'],
        filterable: true,
        sortable: 'campaignId',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.OFFER_NAME',
        field: 'productName',
        fieldName: 'productName',
        reportFields: ['productName'],
        filterable: false,
        sortable: 'productName',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.MARKETING_INFO',
        field: 'marketingInfo',
        fieldName: 'marketingInfo',
        reportFields: ['marketingInfo'],
        filterable: false,
        sortable: 'marketingInfo',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.DESK',
        field: 'desk',
        fieldName: 'desk',
        reportFields: ['desk.name'],
        filterable: true,
        sortable: 'deskId',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.BRAND',
        field: 'contactDashboardBrandSingle',
        fieldName: 'brand',
        fieldFormat: '{{brand.name}}',
        reportFields: ['brand.name'],
        filterable: true,
        sortable: 'brandId',
        removable: true,
        show: true, // false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.COMMUNICATION_STATUS',
        field: 'communicationStatus',
        fieldName: 'communicationStatus',
        reportFields: ['communicationStatus.name'],
        filterable: true,
        sortable: 'communicationStatusId',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_COMMUNICATION_CALLER',
        field: 'lastCommunicationCaller',
        fieldName: 'lastCommunicationCaller',
        reportFields: [
          'lastCommunicationUser.firstName',
          'lastCommunicationUser.lastName',
        ],
        fieldFormat:
          '{{ lastCommunicationUser.firstName }} {{ lastCommunicationUser.lastName }}',
        filterable: true,
        sortable: 'lastCommunicationUserId',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_COMMUNICATION_DETAILS',
        field: 'lastCommunicationDetails',
        fieldName: 'lastCommunicationDetails',
        reportFields: ['lastCommunicationDetails'],
        fieldFormat: '{{ lastCommunicationDetails | prfLimitToDots:20 }}',
        filterable: false,
        sortable: false,
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.CALLS_COUNT',
        field: 'callsCount',
        fieldName: 'callsCount',
        reportFields: ['callsCount'],
        filterable: true,
        sortable: 'callsCount',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.UNIQUE_CALLERS_COUNT',
        field: 'callersCount',
        fieldName: 'callersCount',
        reportFields: ['callersCount'],
        filterable: true,
        sortable: 'callersCount',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.CUSTOMER_STATUS',
        field: 'customerStatus',
        fieldName: 'customerStatus',
        reportFields: ['customerStatus.name'],
        filterable: true,
        sortable: 'customerStatusId',
        removable: true,
        show: true,
        type: 'common',
        permissionsToRead: [Permissions.ContactsPersonalInfoStatus.Read],
        permissionsToUpdate: [Permissions.ContactsPersonalInfoStatus.Update],
      },
      {
        title: 'contact.dashboard.titles.CUSTOMER_COMPLIANCE_STATUS',
        field: 'customerComplianceStatus',
        fieldName: 'customerComplianceStatus',
        reportFields: ['customerComplianceStatus.name'],
        filterable: true,
        sortable: 'customerComplianceStatusId',
        removable: true,
        show: false,
        type: 'common',
        permissionKey: Permissions.ContactsPersonalInfoComplianceStatus.Read, // disable whole column when no Read permission
        permissionsToRead: [
          Permissions.ContactsPersonalInfoComplianceStatus.Read,
        ], // if no permissionsToRead and permissionKey not specified the text replaced to 'xxx'
        permissionsToUpdate: [
          Permissions.ContactsPersonalInfoComplianceStatus.Update,
        ], // disable edit button when no Update permission in contacts-statuses-edit template
      },
      {
        title: 'contact.dashboard.titles.CUSTOMER_ACCOUNT_STATUS',
        field: 'customerAccountStatus',
        fieldName: 'customerAccountStatus',
        reportFields: ['customerAccountStatus.name'],
        fieldFormat:
          '{{ customerAccountStatus ? ("customerAccountStatuses." + customerAccountStatus.code.toUpperCase() | translate) : "" }}',
        filterable: true,
        sortable: 'customerAccountStatusId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.USER',
        field: 'user',
        fieldName: 'user',
        reportFields: ['user.firstName', 'user.lastName'],
        filterable: true,
        sortable: 'userFirstName',
        removable: true,
        show: true,
        type: 'common',
        permissionsToRead: [Permissions.ContactsPersonalInfoAssignTo.Read],
        permissionsToUpdate: [Permissions.ContactsPersonalInfoAssignTo.Update],
      },
      {
        title: 'contact.dashboard.titles.LOCAL_TIME',
        field: 'timeZoneOffset',
        fieldName: 'timeZoneOffset',
        reportFields: ['timeZoneOffset'],
        filterable: true,
        sortable: 'timeZoneOffset',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.DEPOSIT_TOTAL',
        field: 'depositTotal',
        fieldName: 'depositTotal',
        reportFields: ['depositTotal'],
        fieldFormat: '{{depositTotal | baseCurrencyFormat : 2 : true}}',
        filterable: true,
        sortable: 'depositTotal',
        removable: true,
        show: true,
        type: 'forex',
      },
      {
        title: 'contact.dashboard.titles.TOTAL_CREDIT',
        field: 'creditTotal',
        fieldName: 'creditTotal',
        reportFields: ['creditTotal'],
        fieldFormat: '{{creditTotal | baseCurrencyFormat : 2 : true}}',
        filterable: true,
        sortable: 'creditTotal',
        removable: true,
        show: false,
        type: 'forex',
      },
      {
        title: 'contact.dashboard.titles.BONUS_TOTAL',
        field: 'bonusTotal',
        fieldName: 'bonusTotal',
        reportFields: ['bonusTotal'],
        fieldFormat: '{{bonusTotal | baseCurrencyFormat : 2 : true}}',
        filterable: true,
        sortable: 'bonusTotal',
        removable: true,
        show: false,
        type: 'forex',
      },
      {
        title: 'contact.dashboard.titles.WITHDRAWAL_TOTAL',
        field: 'withdrawalTotal',
        fieldName: 'withdrawalTotal',
        reportFields: ['withdrawalTotal'],
        fieldFormat: '{{withdrawalTotal | baseCurrencyFormat : 2 : true}}',
        filterable: true,
        sortable: 'withdrawalTotal',
        removable: true,
        show: true,
        type: 'common',
        transform: true,
      },
      {
        title: 'contact.dashboard.titles.OPEN_WITHDRAWAL_COUNT',
        field: 'openWithdrawalCountTotal',
        fieldName: 'openWithdrawalCountTotal',
        fieldFormat: '{{ openWithdrawalCountTotal }}',
        reportFields: ['openWithdrawalCountTotal'],
        filterable: true,
        sortable: 'openWithdrawalCountTotal',
        removable: true,
        show: true,
        type: 'common',
        transform: true,
      },
      {
        title: 'contact.dashboard.titles.BALANCE_TOTAL',
        field: 'balanceTotal',
        fieldName: 'balanceTotal',
        reportFields: ['balanceTotal'],
        fieldFormat: '{{balanceTotal | baseCurrencyFormat : 2 : true}}',
        filterable: true,
        sortable: 'balanceTotal',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.EQUITY_TOTAL',
        field: 'equityTotal',
        fieldName: 'equityTotal',
        reportFields: ['equityTotal'],
        fieldFormat: '{{equityTotal | baseCurrencyFormat : 2 : true}}',
        filterable: true,
        sortable: 'equityTotal',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.VOLUME',
        field: 'volumeTotal',
        fieldName: 'volumeTotal',
        reportFields: ['volumeTotal'],
        fieldFormat: '{{turnoverTotal | baseCurrencyFormat : 0 : true}}',
        filterable: true,
        sortable: 'turnoverTotal',
        removable: true,
        show: true,
        type: 'forex',
      },
      {
        title: 'contact.dashboard.titles.MARGIN_TOTAL',
        field: 'marginTotal',
        fieldName: 'marginTotal',
        reportFields: ['marginTotal'],
        fieldFormat: '{{ marginTotal | baseCurrencyFormat : 0 : true }}',
        filterable: true,
        sortable: 'marginTotal',
        removable: true,
        show: true,
        type: 'forex',
        platformCodes: MT4_PLATFORMS,
      },
      {
        title: 'contact.dashboard.titles.TRADING_PNL',
        field: 'pnlTotal',
        fieldName: 'pnlTotal',
        reportFields: ['pnlTotal'],
        fieldFormat: '{{pnlTotal | baseCurrencyFormat : 2 : true}}',
        filterable: true,
        sortable: 'pnlTotal',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.REAL_PNL',
        field: 'cashPnl',
        fieldName: 'cashPnl',
        reportFields: ['cashPnl'],
        fieldFormat: '{{cashPnl | baseCurrencyFormat : 2 : true}}',
        filterable: false,
        sortable: false,
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.WITHDRAWABLE_BALANCE',
        field: 'withdrawableBalance',
        fieldName: 'withdrawableBalance',
        reportFields: ['withdrawableBalance'],
        fieldFormat: '{{withdrawableBalance | baseCurrencyFormat : 2 : true}}',
        filterable: false,
        sortable: false,
        removable: true,
        show: false,
        type: 'common',
        transform: true,
      },
      {
        title: 'contact.dashboard.titles.SWAP',
        field: 'swapTotal',
        fieldName: 'swapTotal',
        reportFields: ['swapTotal'],
        fieldFormat: '{{swapTotal | baseCurrencyFormat : 2 : true}}',
        filterable: true,
        sortable: 'swapTotal',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.WIN_RATE_TOTAL',
        field: 'winRateTotal',
        fieldName: 'winRateTotal',
        reportFields: ['winRateTotal'],
        fieldFormat: '{{winRateTotal | number : 2}}%',
        filterable: false,
        sortable: 'winRateTotal',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.TRADE_REPUTATION',
        field: 'tradeReputation',
        fieldName: 'tradeReputation',
        reportFields: ['tradeReputation'],
        filterable: true,
        sortable: null,
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.FTD_DATE',
        field: 'ftdDate',
        fieldName: 'ftdDate',
        reportFields: ['ftdDate'],
        filterable: true,
        sortable: 'ftdDate',
        removable: true,
        show: true,
        type: 'forex',
      },
      {
        title: 'contact.dashboard.titles.APPROVED_DEPOSIT_COUNT',
        field: 'approvedDepositCount',
        fieldName: 'approvedDepositCount',
        reportFields: ['approvedDepositCount'],
        filterable: true,
        sortable: 'approvedDepositCount',
        removable: true,
        show: false,
        type: 'forex',
      },
      {
        title: 'contact.dashboard.titles.TOTAL_POSITION_COUNT',
        field: 'totalPositionCount',
        fieldName: 'totalPositionCount',
        reportFields: ['totalPositionCount'],
        filterable: true,
        sortable: 'totalPositionCount',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.OPEN_POSITIONS',
        field: 'openPositions',
        fieldName: 'openPositions',
        reportFields: ['openPositions'],
        filterable: true,
        sortable: 'openPositions',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_POSITION_DATE',
        field: 'lastPositionDate',
        fieldName: 'lastPositionDate',
        reportFields: ['lastPositionDate'],
        filterable: true,
        sortable: 'lastPositionDate',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_COMMUNICATION_DATE',
        field: 'lastCommunicationDate',
        fieldName: 'lastCommunicationDate',
        reportFields: ['lastCommunicationDate'],
        filterable: true,
        sortable: 'lastCommunicationDate',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_LOGIN_DATE',
        field: 'lastLoginDate',
        fieldName: 'lastLoginDate',
        reportFields: ['lastLoginDate'],
        filterable: true,
        sortable: 'lastLoginDate',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_DEPOSIT_DATE',
        field: 'lastDepositDate',
        fieldName: 'lastDepositDate',
        reportFields: ['lastDepositDate'],
        filterable: true,
        sortable: 'lastDepositDate',
        removable: true,
        show: false,
        type: 'forex',
      },
      {
        title: 'contact.dashboard.titles.LAST_FAILED_DEPOSIT_DATE',
        field: 'lastFailedDepositDate',
        fieldName: 'lastFailedDepositDate',
        reportFields: ['lastFailedDepositDate'],
        filterable: true,
        sortable: 'lastFailedDepositDate',
        removable: true,
        show: false,
        type: 'forex',
      },
      {
        title: 'contact.dashboard.titles.LAST_WITHDRAWAL_DATE',
        field: 'lastWithdrawalDate',
        fieldName: 'lastWithdrawalDate',
        reportFields: ['lastWithdrawalDate'],
        filterable: true,
        sortable: 'lastWithdrawalDate',
        removable: true,
        show: false,
        type: 'common',
        transform: true,
      },
      {
        title: 'contact.dashboard.titles.LAST_ASSIGNMENT_DATE',
        field: 'lastAssignmentDate',
        fieldName: 'lastAssignmentDate',
        reportFields: ['lastAssignmentDate'],
        filterable: true,
        sortable: 'lastAssignmentDate',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.MIGRATED_STATUS',
        field: 'migratedStatus',
        fieldName: 'migratedStatus',
        reportFields: ['migratedStatus'],
        filterable: true,
        sortable: 'migratedStatusId',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.CONTACTS_IMPORT_ID',
        field: 'contactsImport',
        fieldName: 'contactsImport',
        fieldFormat: '{{contactsImportId}}',
        reportFields: ['contactsImportId'],
        filterable: true,
        sortable: 'contactsImportId',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.COMMUNICATION',
        field: 'ctc',
        fieldName: 'ctc',
        __comment:
          "Don't allow adding/removing the column manually. it would happen automatically if ctc is enabled",
        nonEditable: true,
        removable: false,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.TOTAL_PURCHASES',
        field: 'totalPurchases',
        fieldName: 'totalPurchases',
        reportFields: ['totalPurchases'],
        fieldFormat: '{{totalPurchases | baseCurrencyFormat : 2 : true}}',
        filterable: true,
        sortable: 'totalPurchases',
        removable: true,
        show: false,
        type: 'prop',
      },
      {
        title: 'contact.dashboard.titles.PURCHASE_COUNT',
        field: 'purchaseCount',
        fieldName: 'purchaseCount',
        fieldFormat: '{{ purchaseCount }}',
        reportFields: ['purchaseCount'],
        filterable: true,
        sortable: 'purchaseCount',
        removable: true,
        show: false,
        type: 'prop',
      },
      {
        title: 'contact.dashboard.titles.LAST_PURCHASE_DATE',
        field: 'lastPurchaseDate',
        fieldName: 'lastPurchaseDate',
        fieldFormat: '{{ lastPurchaseDate }}',
        reportFields: ['lastPurchaseDate'],
        filterable: true,
        sortable: 'lastPurchaseDate',
        removable: true,
        show: false,
        type: 'prop',
      },
      {
        title: 'contact.dashboard.titles.FTP_DATE',
        field: 'ftpDate',
        fieldName: 'ftpDate',
        reportFields: ['ftpDate'],
        filterable: true,
        sortable: 'ftpDate',
        removable: true,
        show: true,
        type: 'prop',
      },
      {
        title: 'contact.dashboard.titles.LAST_FAILED_PURCHASE_DATE',
        field: 'lastFailedPurchaseDate',
        fieldName: 'lastFailedPurchaseDate',
        reportFields: ['lastFailedPurchaseDate'],
        filterable: true,
        sortable: 'lastFailedPurchaseDate',
        removable: true,
        show: false,
        type: 'prop',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
  dependenciesPerFilter: {
    desk: ['selectedBrand'],
  },
};
