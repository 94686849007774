import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import template from './challenge-period.component.html';
import { PeriodsUnits } from '@proftit/crm.api.models.entities/src';
import { IScope } from 'angular';

const styles = require('./challenge-period.component.scss');

export class ChallengePeriodController {
  label: string;
  model: any;
  styles = styles;
  onUpdate: () => void;
  minValue: number | null;
  constructor(readonly $scope: IScope) {}

  lifecycles = observeComponentLifecycles(this);
  $onInit() {
    this.minValue = this.minValue || 1;
    this.$scope.$watch('$ctrl.model.isActive', this.onActiveChange.bind(this));
    this.$scope.$watch('$ctrl.model.value', this.onValueChange.bind(this));
  }
  $onDestroy() {}
  $onChanges() {}

  /**
   * Updates the model's value based on the isActive state.
   * If isActive is true, sets the value to the maximum of its current value and minValue.
   * If isActive is false, sets the value to null.
   * Calls the onUpdate method after updating the value.
   */
  onActiveChange() {
    this.model.value = this.model.isActive
      ? Math.max(this.model.value, this.minValue)
      : null;
    this.onUpdate();
  }

  /**
   * Updates the model's isActive state based on the value.
   * If the value is not null, sets isActive to true.
   * Otherwise, sets isActive to false.
   * Calls the onUpdate method after updating the isActive state.
   */
  onValueChange() {
    this.model.isActive = this.model.value !== null;
    this.onUpdate();
  }

  change() {
    this.onUpdate();
  }
}

export const ChallengePeriodComponent = {
  template,
  controller: ChallengePeriodController,
  bindings: {
    label: '<',
    model: '<',
    onUpdate: '&',
    minValue: '<',
  },
};
