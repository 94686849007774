import { PermissionNormalized } from '../common/models/permission-structure';

export const permissionsCrud = [
  'accounting',
  PermissionNormalized.AssignmentPannel,
  'contacts',
  'contacts.accountstats',
  PermissionNormalized.ContactsAttachments,
  PermissionNormalized.ContactsFileUpload,
  PermissionNormalized.ContactsCommunications,
  'contacts.communications.favorite',
  PermissionNormalized.ContactsCommunicationsRecordings,
  PermissionNormalized.ContactsCommunicationsEmailSendFrom,
  'contacts.creditcards',
  PermissionNormalized.ContactsLinkCustomers,
  PermissionNormalized.ContactsContactImport,
  PermissionNormalized.ContactsTradingAccountResetMt4Password,
  'contacts.marketinginfo',
  'contacts.personalinfo',
  PermissionNormalized.ContactsPersonalInfoAssignTo,
  'contacts.personalinfo.compliancestatus',
  PermissionNormalized.ContactPersonalInfoEmail,
  'contacts.personalinfo.password',
  'contacts.personalinfo.loginas',
  PermissionNormalized.ContactPersonalInfoPhone,
  'contacts.personalinfo.sharedgroupfilters',
  PermissionNormalized.ContactsPersonalInfoStatus,
  PermissionNormalized.ContactsPersonalInfoName,
  PermissionNormalized.ContactPersonalInfoLoginAsCustomer,
  PermissionNormalized.ContactsWhatsappweb,
  'contacts.tradingaccount',
  'contacts.tradingaccount.positions',
  'contacts.tradingaccount.type',
  'contacts.transactions',
  'contacts.transactions.deposits',
  'contacts.transactions.deposits.depositsOwnerId',
  'contacts.transactions.withdraw',
  'contacts.transactions.withdrawalsrequest',
  'contacts.transactions.withdrawalsrequest.withdrawalOwnerId',
  'contacts.transactions.bonuses',
  'contacts.transactions.riskfreepositions',
  'contacts.transactions.credits',
  'contacts.transactions.fees',
  'contacts.transactions.internaltransfers',
  'contacts.auditlog',
  PermissionNormalized.ContactsCrmexport,
  PermissionNormalized.ContactsSend2faCode,
  PermissionNormalized.ContactsPersonalInfoDisable2fa,
  PermissionNormalized.ContactsTradingAccountType,
  'management',
  'management.brands.settings_3d',
  'management.brands',
  'management.brands.cashier',
  'management.brands.clearingcredentials',
  'management.brands.ewalletscredentials',
  'management.brands.notification_settings',
  PermissionNormalized.ManagementRiskmanager,
  'management.challenges',
  'management.desks',
  'management.iplist',
  PermissionNormalized.ManagementSystemConfiguration,
  PermissionNormalized.ManagementFieldsAndModules,
  'management.usergroups',
  'management.users',
  'management.users.activitylogs',
  'management.users.targets',
  PermissionNormalized.ManagementBrandCommunicationSettings,
  PermissionNormalized.ManagementEmailTemplate,
  'management.email_templates',
  'marketing',
  'marketing.campaigns',
  'reporting',
  PermissionNormalized.ReportingKibiExport,
  PermissionNormalized.ReportingExport,
  'tasks',
  'automation',
  PermissionNormalized.AutoAssignments,
  PermissionNormalized.AutomationInactivity,
  PermissionNormalized.AutomationAccountStatus,
  PermissionNormalized.AutomationAccountStatusPermissions,
  PermissionNormalized.AutomationReferFriend,
  PermissionNormalized.AutomationReferFriend,
  PermissionNormalized.AutomationBrandPlatformPromoCode,
  PermissionNormalized.AutomationDemoDeposit,
  PermissionNormalized.Portfolio,
  PermissionNormalized.BalanceLogExport,
  PermissionNormalized.Exposure,
  PermissionNormalized.ContactsCommunicationsExportCommunicationsHistory,
  PermissionNormalized.Support,
];
