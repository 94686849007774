import Component from './base/component';
import BrandsService from '~/source/management/brand/services/brands';
import { PlatformType } from '@proftit/crm.api.models.entities';

class ContactsDashboardBrandSelectController extends Component.controller {
  static $inject = ['brandsService', ...Component.controller.$inject];
  platformType: PlatformType;
  dataServiceInst: BrandsService;

  init() {
    this.$scope.$watch('vm.platformType', (newValue, oldValue, scope) => {
      if (!newValue) {
        return;
      }
      this.fetchData()
        .then(() => {
          this.model = this.nullableElement;
        })
        .catch(() => {});
    });
  }

  /**
   * Rewrite default behavior
   * Expand brand entity with default platform
   *
   * @returns {Promise}
   */
  query() {
    if (!this.platformType) {
      return;
    }
    return this.dataServiceInst
      .expand(['platformType', 'platformConnections.platform', 'file:logo'])
      .embed(['platformConnections'])
      .filter('platformTypeId', this.platformType.id)
      .getListWithQuery();
  }

  get nullableElement() {
    return {
      id: null,
      name: 'All Brands',
    };
  }

  get defaultPlaceholder() {
    return 'SELECT_BRAND';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export default Component.config({
  controller: ContactsDashboardBrandSelectController,
  bindings: {
    platformType: '<',
  },
});
