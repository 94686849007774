import template from './accounts-summary.html';
import config from './fields-config.json';
import BaseController from '~/source/common/controllers/base';
import { IScope } from 'angular';
import AccountSummarySocketService from '~/source/contact/common/services/accounts-summary-socket.service';
import { HighlightEntityService } from '~/source/common/services/highlight-entity';
import TokensService from '~/source/auth/services/tokens';
import { Customer, PlatformType } from '@proftit/crm.api.models.entities';
import { SocketListener } from '~/source/common/services/socket';

export interface AccountSummaryFieldConfig {
  title: string;
  field: string;
  type: 'monetary' | 'percentage' | 'number';
  fractionSize?: number;
}

class AccountsSummaryController extends BaseController {
  channel: string;
  onUpdatedBinded: SocketListener;
  summaryConfig: AccountSummaryFieldConfig[];

  // Bindings
  customer: Customer;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly accountSummarySocketService: AccountSummarySocketService,
    readonly highlightEntityService: HighlightEntityService,
    readonly tokensService: TokensService,
    readonly appConfig, // used in template
    readonly platformTypesMap: PlatformType,
  ) {
    super();
  }

  $onInit() {
    // callback for updates from streamer
    this.onUpdatedBinded = this.accountSummarySocketService.wrapListener(
      this.onUpdate.bind(this),
    );

    this.channel = this.buildChannel();

    this.listenTo();
  }

  /**
   * Called automagically by parent when the "customer" input changes.
   * Sets the summary config according to the customer's brand platform type
   * @return {void}
   */
  onCustomerChange() {
    if (this.customer.brand.platformType.id) {
      const platformType = this.platformTypesMap[
        this.customer.brand.platformType.id
      ];
      this.summaryConfig = config[platformType];
    }
  }

  /**
   * Channel for account statistics updates
   *
   * @returns {string}
   */
  buildChannel() {
    return `user.${this.tokensService.getCachedUser().id}.${
      this.accountSummarySocketService.channelRoot
    }.${this.customer.id}`;
  }

  /**
   * Subscribe for account stats updates
   */
  listenTo() {
    this.accountSummarySocketService.subscribe(
      this.channel,
      this.onUpdatedBinded,
    );
  }

  /**
   * Unsubscribe from account updates
   */
  stopListening() {
    this.accountSummarySocketService.unsubscribe(
      this.channel,
      this.onUpdatedBinded,
    );
  }

  /**
   * Unsubscribe from updates on account
   */
  $onDestroy() {
    this.stopListening();
  }

  onUpdate(propertiesToUpdate) {
    // update the account
    Object.assign(this.customer, propertiesToUpdate);
    // highlight the update
    this.highlightEntityService.highlightPure(() => this.customer);
  }
}

export default {
  template,
  controller: AccountsSummaryController,
  controllerAs: 'vm',
  bindings: {
    customer: '<',
  },
};
