import template from './purchase-link-document-popup.component.html';

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import TableController from '~/source/common/components/table/table.controller';
import { CustomerComplianceFileTypesStoreService } from '~/source/common/store-services/customer-compliance-file-types-store.service';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import CustomersService from '~/source/contact/common/services/customers';
import ICollectionRestNg from '~/source/common/models/icollection-rest-ng';
import { CustomerRegulationFile } from '@proftit/crm.api.models.entities';
import { purchaseLinkDocumentPopupColumns } from './purchase-link-document-popup.cols';
import { purchaseLinkDocumentPopupSettings } from './purchase-link-document-popup.settings';
import { useStreams } from '@proftit/rxjs.adjunct';
import log from 'loglevel';
import { GoogleStorageDomain } from '~/source/common/services/private-google-storage-file.service';

interface ComponentBindings {
  customerId: number;
  purchaseId: number;
  accountId: number;
}

interface DataInfo {
  isEdit: boolean;
  linked: boolean;
  depositAttachmentPivotId: number;
  viewItem: any;
  editItem?: any;
}

const defaultViewRenderer = {
  type: 'general',
};

export class PurchaseLinkDocumentPopupController extends TableController
  implements ComponentBindings {
  static $inject = [
    'customersService',
    'prfCustomerComplianceFileTypesStoreService',
    ...TableController.$inject,
  ];
  prfCustomerComplianceFileTypesStoreService: CustomerComplianceFileTypesStoreService;

  growlRefId = generateGrowlId();
  blockUiId = generateBlockuiId();

  lifecycles = observeComponentLifecycles(this);

  customerId: number;
  purchaseId: number;
  accountId: number;

  close: ($value: any) => void;
  dismiss: ($value: any) => void;

  complianceDocTypeId: number;

  customersService: () => CustomersService;

  // @ts-ignore
  dataServiceInstance = this.customersService();
  GoogleStorageDomain: any;

  attachments: ICollectionRestNg<CustomerRegulationFile>;
  dataInfos: {
    [key: string]: DataInfo;
  } = {};

  cols = [...purchaseLinkDocumentPopupColumns];
  settings = { ...purchaseLinkDocumentPopupSettings };

  linkedDocumentChangeAction = new rx.Subject<{
    attachmentId: number;
    depositAttachmentPivotId: number;
    linked: boolean;
  }>();

  constructor(...args) {
    super(...args);
    this.GoogleStorageDomain = GoogleStorageDomain;

    useStreams(
      [
        this.streamInitTable(),
        this.streamLinkDocumentToDeposit(),
        this.streamUnlinkDocumentToDeposit(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    super.$onInit();
  }

  $onDestroy() {}

  $onChanges() {}

  /**
   * modal dialog component gets passed data by the 'resolve' binding parameter.
   * We use a setter to automatically extract the incoming value and destructur
   * it to the different values passed.
   */
  set resolve(val: ComponentBindings) {
    if (_.isNil(val)) {
      return;
    }
    this.customerId = val.customerId;
    this.accountId = val.accountId;
    this.purchaseId = val.purchaseId;
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  streamInitTable() {
    return rx.pipe(
      () =>
        this.prfCustomerComplianceFileTypesStoreService
          .customerComplianceDepositFileTypeId$,
      rx.tap((complianceDocTypeId) => {
        this.complianceDocTypeId = complianceDocTypeId;

        this.initTable();
      }),
    )(null);
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .getAttachmentsResource(this.customerId)
      .setConfig({
        growlRef: this.growlRefId,
        blockUiRef: this.blockUiId,
      })
      .embed(['depositAttachments'])
      .expand(['file'])
      .filter({
        complianceDocTypeId: this.complianceDocTypeId,
      });
  }

  parseLoadedData(data) {
    this.attachments = data;
    this.dataInfos = this.calcDataInfos(data.plain()) as any;

    return data;
  }

  calcDataInfos(data: CustomerRegulationFile[]) {
    return _.flow(
      () => _.keyBy((item) => item.id, data),
      (hash) => _.mapValues((item) => this.calcDataInfo(item), hash),
    )();
  }

  calcDataInfo(item: CustomerRegulationFile): DataInfo {
    const tempViewCols = this.cols.map((col) => {
      const modelInit = _.get('renderers.view.modelInit', col);
      let value;

      if (_.isNil(modelInit)) {
        value = item[col.fieldName];
      } else {
        value = modelInit(item[col.fieldName]);
      }

      return { value, name: col.fieldName };
    });

    const viewItem = _.flow(
      () => _.keyBy((x) => x.name, tempViewCols),
      (x) => _.mapValues((val) => val.value, x),
    )();

    const linkedDeposits = item.depositAttachments.find(
      (deposit) => deposit.depositId === this.purchaseId,
    );

    return {
      viewItem,
      linked: linkedDeposits ? true : false,
      depositAttachmentPivotId: linkedDeposits ? linkedDeposits.id : null,
      isEdit: false,
    };
  }

  getRowRenderer(row, col) {
    const editRenderer = this.dataInfos[row.id].isEdit
      ? _.get(['renderers', 'edit'], col)
      : null;
    let viewRenderer = _.get(['renderers', 'view'], col);
    if (_.isNil(viewRenderer)) {
      viewRenderer = defaultViewRenderer;
    }

    return editRenderer ? editRenderer : viewRenderer;
  }

  streamLinkDocumentToDeposit() {
    return rx.pipe(
      () => this.linkedDocumentChangeAction,
      rx.filter(({ linked }) => linked),
      rx.switchMap(({ attachmentId }) => {
        return rx.obs
          .from(
            this.customersService().addLinkAttachmentToDeposit(
              this.customerId,
              this.accountId,
              this.purchaseId,
              attachmentId,
            ),
          )
          .pipe(
            rx.catchError((err, caught) => {
              log.error('error link document to deposit:', err);

              return rx.obs.EMPTY;
            }),
          );
      }),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  streamUnlinkDocumentToDeposit() {
    return rx.pipe(
      () => this.linkedDocumentChangeAction,
      rx.filter(({ linked }) => !linked),
      rx.switchMap(({ depositAttachmentPivotId }) => {
        return rx.obs
          .from(
            this.customersService().deleteLinkAttachmentToDeposit(
              this.customerId,
              this.accountId,
              this.purchaseId,
              depositAttachmentPivotId,
            ),
          )
          .pipe(
            rx.catchError((err, caught) => {
              log.error('error unlink document to purchase:', err);

              return rx.obs.EMPTY;
            }),
          );
      }),
      rx.tap(() => this.reloadTable()),
    )(null);
  }
}

export const PurchaseLinkDocumentPopupComponent = {
  template,
  controller: PurchaseLinkDocumentPopupController,
  controllerAs: 'vm',
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};
