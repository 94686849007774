import CustomersService from '~/source/contact/common/services/customers';
import { Customer } from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import template from './contact-challenges.html';

const styles = require('./contact-challenges.component.scss');

class ContactChallengesController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  customer: Customer;
  challenges$ = this.streamCustomerChallenges();
  currentPage: number = 0;

  /* @ngInject */
  constructor(readonly customersService: () => CustomersService) {
    useStreams([this.challenges$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  streamCustomerChallenges() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      rx.map(() => this.customer),
      rx.switchMap((customer) => this.fetchCustomerChallenges(customer.id)),
      rx.tap((data) => {
        this.currentPage = 0;
      }),
      shareReplayRefOne(),
    )(null);
  }

  async fetchCustomerChallenges(id: number) {
    const data = await this.customersService().getCustomerChallenges(id);
    return data.plain();
  }
}

export default {
  template,
  controller: ContactChallengesController,
  bindings: {
    customer: '<',
  },
};
