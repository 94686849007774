import ng from 'angular';

import ContactDropdownsModule from './common/dropdowns/module';
import ContactPageModule from './contact-page/module';
import ContactsDashboardModule from './contacts-dashboard/module';
import AccountSyncForexService from './common/services/accounts-sync-forex.service';
import BonusesSocketService from './common/services/bonuses-socket.service';
import CreditsSocketService from './common/services/credits-socket.service';
import FeesSocketService from './common/services/fees-socket.service';
import RiskFreesSocketService from './common/services/risk-frees-socket.service';
import CommunicationsStatusesService from './common/services/communications-statuses.service';
import CustomersService from './common/services/customers';
import CustomersSocket from './common/services/customers-socket';
import CustomerStatusesService from './common/services/customer-statuses.service';
import CustomerComplianceStatusesService from './common/services/customer-compliance-statuses.service';
import CustomerAccountStatusesService from './common/services/customer-account-statuses.service';
import ContactDashboardCacheService from './common/services/contact-dashboard-cache.service';
import CustomerIdTypeService from './common/services/customer-id-type.service';
import AccountSummarySocketService from './common/services/accounts-summary-socket.service';
import BinCheckerService from './common/services/bin-checker.service';
import BonusStatusesService from './common/services/bonus-statuses.service';
import { CommunicationsSubjectsService } from './common/services/communications-subjects.service';
import CreditCardTypesService from './common/services/credit-card-types.service';
import customerAllocationsService from './common/services/customer-allocations.service';
import CustomerComplianceTypeService from './common/services/customer-compliance-type.service';
import DepositStatusesService from './common/services/deposit-statuses.service';
import DepositsSocket from './common/services/deposits-socket.service';
import ExperienceRangesService from './common/services/experience-ranges.service';
import FeeTypesService from './common/services/fee-types.service';
import MigratedStatuses from './common/services/migrated-statuses.service';
import PlatformsService from './common/services/platforms-public.service';
import PositionsProducts from './common/services/positions-products.service';
import PositionsTradeAssets from './common/services/positions-trade-assets.service';
import CustomerStats from './common/services/stats.service';
import TradeReputationTypes from './common/services/trade-reputation-types.service';
import TradingAccountTypes from './common/services/trading-account-types.service';
import TransactionStatuses from './common/services/transaction-statuses.service';
import EwalletTransactionStatuses from './common/services/ewallet-transaction-statuses.service';
import TransferMethodTypes from './common/services/transfer-method-types.service';
import { ExportIndexesService } from './common/services/exportIndexes.service';
import WithdrawalCreditCardFormFormatter from './common/services/withdrawal-credit-card-form-formatter.service';
import WithdrawalTypes from './common/services/withdrawal-types.service';
import WithdrawalsSocket from './common/services/withdrawals-socket.service';
import TradingAccountFormFormatter from './common/services/trading-account-form-formatter.service';
import TradingAccountFormParser from './common/services/trading-account-form-parser.service';
import DepositCreditCardNewFormFormatter from './common/services/deposit-credit-card-new-form-formatter.service';
import DepositCreditCardExistFormFormatterService from './common/services/deposit-credit-card-exist-form-formatter.service';
import CustomerRegulationFileStatusesService from './common/services/customer-regulation-file-statuses.service';
import ProviderClearingCompaniesService from './common/services/provider-clearing-companies.service';
import ClickToCallComponent from './common/click-to-call/click-to-call.component';
import CustomerIdTypeSelectComponent from './common/dropdowns/customer-id-type-select';
import NationalitySelectComponent from './common/dropdowns/nationality-select';
import GeneralModalHeaderComponent from './common/components/general-modal-header/general-modal-header';
import RadioGroupComponent from './common/components/radio-group/radio-group.component';
import ResetPasswordFormComponent from './common/components/reset-password-form/reset-password-form.component';

import ResetPasswordDialogComponent from './common/containers/reset-password-dialog/reset-password-dialog.component';
import { ClickToSendDialogComponent } from './common/click-to-send-dialog/click-to-send-dialog.component';
import { ClickToSendFormComponent } from './common/click-to-send-form/click-to-send-form.component';
import { LinkCustomerDialogComponent } from './common/containers/link-customer-dialog/link-customer-dialog.component';
import { LinkCustomerItemComponent } from './common/containers/link-customer-item/link-customer-item.component';
import { LinkCustomerFormComponent } from './common/components/link-customer-form/link-customer-form.component';
import { CRMExportService } from '~/source/common/services/crm-export.service';
import CustomerSiteLocationTrackingSocket from '~/source/contact/common/services/customer-site-location-tracking-socket';
import CustomerSiteLocationKeepaliveSocket from '~/source/contact/common/services/customer-site-location-keepalive-socket';
import { ClickToChatComponent } from './common/click-to-chat/click-to-chat.component';
import { ClickToCallListComponent } from '~/source/contact/common/click-to-call/click-to-call-list/click-to-call-list.component';
import { DropdownArrowComponent } from '~/source/contact/common/dropdown-arrow/dropdown-arrow.component';
import { CustomerStoreProviderComponent } from './common/components/customer-store-provider/customer-store-provider.component';
import ReportsService from './common/services/reports.service';
import { ReportsExportService } from '../common/services/report-export.service';
import {
  ContactsPlatformTypeSelectorComponent,
  ContactsPlatformTypeSelectorController,
} from '~/source/contact/contacts-dashboard/contacts-platform-type-selector/contacts-platform-type-selector.component';

const ngModule = ng.module('crm.contact', [
  'ui.router',
  'crm.constants',
  'crm.common',
  ContactPageModule,
  ContactsDashboardModule,
  ContactDropdownsModule,
]);

ngModule
  .config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state('crm.contacts', {
        url: '/contacts',
        template: '<ui-view/>',
        data: {
          pageTitle: 'CONTACTS',
        },
      });
    },
  ])
  /**
   * Since we use $factorize here, the CustomersService will now actually be a function
   * which returns an instance, once invoked.
   *
   * @example
   * function (CustomersService) {
   *     var serviceInstance = CustomersService();
   *     serviceInstance.getListWithQuery();
   * }
   */
  .factory('customersService', CustomersService.$factorize())
  /*
   * @example
   * function (ReportsService) {
   *     var serviceInstance = ReportsService();
   *     serviceInstance.getListWithQuery();
   * }
   */
  .factory('reportsService', ReportsService.$factorize())
  .factory(
    'communicationsStatusesService',
    CommunicationsStatusesService.$factorize(),
  )
  .factory('customerStatusesService', CustomerStatusesService.$factorize())
  .factory(
    'customerComplianceStatusesService',
    CustomerComplianceStatusesService.$factorize(),
  )
  .factory(
    'customerAccountStatusesService',
    CustomerAccountStatusesService.$factorize(),
  )
  .service('accountsSyncForexService', AccountSyncForexService)
  .service('bonusesSocketService', BonusesSocketService)
  .service(
    'customerSiteLocationTrackingSocketService',
    CustomerSiteLocationTrackingSocket,
  )
  .service(
    'customerSiteLocationKeepaliveSocketService',
    CustomerSiteLocationKeepaliveSocket,
  )
  .service('creditsSocketService', CreditsSocketService)
  .service('feesSocketService', FeesSocketService)
  .service('riskFreesSocketService', RiskFreesSocketService)
  .service('customersSocketService', CustomersSocket)
  .service('contactDashboardCacheService', ContactDashboardCacheService)
  .service('customerIdTypeService', CustomerIdTypeService)
  .service('accountSummarySocketService', AccountSummarySocketService)
  .service('binCheckerService', BinCheckerService)
  .service('bonusStatusesService', BonusStatusesService)
  .service('communicationsSubjectsService', CommunicationsSubjectsService)
  .factory('creditCardTypesService', CreditCardTypesService.$factorize())
  .service('customerAllocationsService', customerAllocationsService)
  .service('providerClearingCompaniesService', ProviderClearingCompaniesService)
  .factory(
    'customerComplianceTypeService',
    CustomerComplianceTypeService.$factorize(),
  )
  .service('depositStatusesService', DepositStatusesService)
  .service('depositsSocketService', DepositsSocket)
  .factory(
    'depositCreditCardNewFormFormatter',
    DepositCreditCardNewFormFormatter.$factorize(),
  )
  .factory(
    'depositCreditCardExistFormFormatter',
    DepositCreditCardExistFormFormatterService.$factorize(),
  )
  .factory('experienceRangesService', ExperienceRangesService.$factorize())
  .factory('feeTypesService', FeeTypesService.$factorize())
  .service('migratedStatusesService', MigratedStatuses)
  .service('platformsPublicService', PlatformsService)
  .factory('positionsProductsService', PositionsProducts.$factorize())
  .service('positionsTradeAssetsService', PositionsTradeAssets)
  .factory('stats', CustomerStats.$factorize())
  .factory('tradeReputationTypesService', TradeReputationTypes.$factorize())
  .factory(
    'tradingAccountFormFormatter',
    TradingAccountFormFormatter.$factorize(),
  )
  .factory('tradingAccountFormParser', TradingAccountFormParser.$factorize())
  .factory('tradingAccountTypesService', TradingAccountTypes.$factorize())
  .service('transactionStatusesService', TransactionStatuses)
  .service('ewalletTransactionStatusesService', EwalletTransactionStatuses)
  .service('transferMethodTypesService', TransferMethodTypes)
  .service('exportIndexesService', ExportIndexesService)
  .factory(
    'withdrawalCreditCardFormFormatter',
    WithdrawalCreditCardFormFormatter.$factorize(),
  )
  .service('withdrawalTypesService', WithdrawalTypes)
  .service('withdrawalsSocketService', WithdrawalsSocket)
  .service(
    'customerRegulationFileStatusesService',
    CustomerRegulationFileStatusesService,
  )
  .factory('prfCRMExportService', CRMExportService.$factorize())
  .factory('prfReportsExportService', ReportsExportService.$factorize())
  .component('prfClickToCall', ClickToCallComponent)
  .component('prfContactIdTypeSelect', CustomerIdTypeSelectComponent)
  .component('prfNationalitySelect', NationalitySelectComponent)
  .component('prfResetPasswordDialog', ResetPasswordDialogComponent)
  .component('prfGeneralModalHeader', GeneralModalHeaderComponent)
  .component('prfRadioGroupWithOptions', RadioGroupComponent)
  .component('prfResetPasswordForm', ResetPasswordFormComponent)
  .component('prfClickToSendForm', ClickToSendFormComponent)
  .component('prfClickToSendDialog', ClickToSendDialogComponent)
  .component('prfLinkCustomerDialog', LinkCustomerDialogComponent)
  .component('prfLinkCustomerItem', LinkCustomerItemComponent)
  .component('prfLinkCustomerForm', LinkCustomerFormComponent)
  .component('prfDropdownArrow', DropdownArrowComponent)
  .component('prfClickToCallList', ClickToCallListComponent)
  .component('prfClickToChat', ClickToChatComponent)
  .component('prfCustomerStoreProvider', CustomerStoreProviderComponent)
  .component(
    'prfContactsPlatformTypeSelector',
    ContactsPlatformTypeSelectorComponent,
  );

export default ngModule.name;
