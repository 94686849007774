import Component from './base/component';
import * as _l from '@proftit/lodash';
import ItemLabelStrategy from '~/source/common/components/dropdowns/base/item-label-strategy';
import _ from 'underscore';
import { PlatformTypeCode } from '@proftit/crm.api.models.enums/src';

class WithdrawalTypesController extends Component.controller {
  static $inject = ['withdrawalTypesService', ...Component.controller.$inject];
  accountType: string;
  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'withdrawal.withdrawalTypes';
  }

  onDataReady(data: any[]): Promise<any[]> {
    this._data = this._data.filter((el) =>
      this.accountType === PlatformTypeCode.Prop
        ? el.code === PlatformTypeCode.Prop
        : el.code !== PlatformTypeCode.Prop,
    );

    if (this.nullable) {
      // add nullable element
      this.addNullableElement();
      /*
       * if select is nullable and the model is undefined,
       * this means that default element is selected
       */
      if (!this.model) {
        this.model = this.multiple ? [] : this.nullableElement;
      }
    }

    if (this.accountType !== PlatformTypeCode.Prop) {
      this.setSelectedValues();
    } else {
      this.model = this._data[0];
    }

    this._data.forEach((el) => {
      if (!_l.isObject(el)) {
        return;
      }

      el._isItemDisabled = this.isItemDisabledFn(el);
    });

    if (this.itemLabelStrategy === ItemLabelStrategy.FieldValue) {
      this._data.forEach((el) => {
        el[this.translateDest] = this.calcLabelForItem(el);
      });
      return Promise.resolve(this._data);
    }

    // return non translated output
    if (_.isEmpty(this.translateSource)) {
      if (!_.isEmpty(this.translateDest)) {
        /**
         * do not translate, but add an alias for the name attribute as the translated dest key.
         * This way in the template we can always show the "translated" key without "if"s
         */
        this._data.forEach((el) => {
          el[this.translateDest] = el.name;
        });
      }
      return Promise.resolve(this._data);
    }
    // Translation source exists. add translation

    return this.addTranslation();
  }
}

export default Component.config({
  controller: WithdrawalTypesController,
  bindings: {
    accountType: '<',
  },
});
