import RestService from '~/source/common/services/rest';

import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { IFieldsAndModulesService } from '~/source/management/fields-and-modules/i-fields-and-modules-service';
import Promise from 'bluebird';
import {
  Challenge,
  ChallengeBalanceGroupsCreate,
} from '@proftit/crm.api.models.entities/src';

const SERVICE_RESOURCE = 'challengeGroups';
const BALANCE_GROUPS = 'challengeBalanceGroups';
const CHALLENGES = 'challenges';
export class ChallengeGroupService extends RestService
  implements IFieldsAndModulesService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */

  get resource() {
    return SERVICE_RESOURCE;
  }

  getChallengeResource(id: number) {
    return this.resourceBuildStart().getElement(id).resourceBuildEnd();
  }

  getBalanceGroupResource(id: number) {
    return this.resourceBuildStart()
      .getElement(id)
      .getNestedCollection(BALANCE_GROUPS)
      .resourceBuildEnd()
      .getListWithQuery();
  }

  createBalanceGroup(groupId: number, data: ChallengeBalanceGroupsCreate) {
    return this.resourceBuildStart()
      .getElement(groupId)
      .getNestedCollection(BALANCE_GROUPS)
      .resourceBuildEnd()
      .postWithQuery(data);
  }

  updateBalanceGroup(
    groupId: number,
    balanceGroupId: number,
    data: ChallengeBalanceGroupsCreate,
  ) {
    return this.resourceBuildStart()
      .getElement(groupId)
      .getNestedElement(BALANCE_GROUPS, balanceGroupId)
      .resourceBuildEnd()
      .patchWithQuery(data);
  }

  deleteBalanceGroup(groupId: number, balanceGroupId: number) {
    return this.resourceBuildStart()
      .getElement(groupId)
      .getNestedElement(BALANCE_GROUPS, balanceGroupId)
      .resourceBuildEnd()
      .removeWithQuery();
  }

  getChallengesByGroupId(groupId: number) {
    return this.resourceBuildStart()
      .getElement(groupId)
      .getNestedCollection(CHALLENGES)
      .expand(['currency'])
      .embed(['phases'])
      .resourceBuildEnd()
      .getListWithQuery();
  }

  createChallenge(groupId: number, data: any) {
    return this.resourceBuildStart()
      .getElement(groupId)
      .getNestedCollection(CHALLENGES)
      .expand(['currency'])
      .embed(['phases'])
      .resourceBuildEnd()
      .postWithQuery(data);
  }

  updateChallenge(groupId: number, challengeId: number, data: Challenge) {
    return this.resourceBuildStart()
      .getElement(groupId)
      .getNestedElement(CHALLENGES, challengeId)
      .embed(['phases'])
      .resourceBuildEnd()
      .patchWithQuery(data);
  }

  updateChallengeMultiple(groupId: number, data: Challenge[]) {
    return this.resourceBuildStart()
      .getElement(groupId)
      .getNestedElement(CHALLENGES, '~')
      .resourceBuildEnd()
      .embed(['phases'])
      .patchWithQuery(data);
  }

  deleteChallenge(groupId: number, challengeId: number) {
    return this.resourceBuildStart()
      .getElement(groupId)
      .getNestedElement(CHALLENGES, challengeId)
      .resourceBuildEnd()
      .removeWithQuery();
  }

  updateIsDefault(modelId: number, isDefault: boolean): Promise<any> {
    return Promise.resolve(undefined);
  }
}

export default ChallengeGroupService;
