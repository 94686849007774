import UiRouter from '@uirouter/core';
import * as _ from '@proftit/lodash';
import { Customer } from '@proftit/crm.api.models.entities';
import BaseController from '../../../../common/controllers/base';
import template from './sidebar-tabs.html';
import icons from './sidebar-tabs.symbols.html';
import { PlatformTypeCode } from '@proftit/crm.api.models.enums';

const DEFAULT_TAB = 'info';

const TAB_NAMES = [
  'highlights',
  'info',
  'attachments',
  'marketing',
  'loginHistory',
  'auditLog',
];

class SidebarTabsController extends BaseController {
  static $inject = ['screenSize', '$stateParams'];

  // Services
  screenSize: any;
  $stateParams: UiRouter.StateParams;

  // Bindings
  customer: Customer;

  activeTab: string;
  prevTab: string;
  initialTab: string;
  symbols = icons;
  propPlatformTypeCode = PlatformTypeCode.Prop;

  $onInit() {
    this.initTabsByScreenSize();
  }

  /**
   * Called when a tab is selected.
   * @param {string} val
   */
  onTabSelect(val) {
    // on a small screen, if the selected tab is clicked again, hide it
    if (this.screenSize.is('prfSM') && this.prevTab === val) {
      this.activeTab = null;
      return;
    }

    this.activeTab = val;
    this.prevTab = this.activeTab;
  }

  /**
   * Calculates the initial tab by priorities:
   * First priority is from URL, 2nd is if 'turnoverTotal' is positive and 3rd is the default tab.
   * @return {string}
   */
  calcInitialTab(): string {
    // get the first key which has a true value.
    return _.findKeyEs({
      [this.$stateParams.tab]:
        _.isString(this.$stateParams.tab) &&
        TAB_NAMES.includes(this.$stateParams.tab),
      highlights: this.customer.turnoverTotal > 0,
      [DEFAULT_TAB]: true,
    });
  }

  initTabsByScreenSize() {
    this.initialTab = this.calcInitialTab();

    // Set initial tab as the active tab
    this.activeTab = this.initialTab;
    this.prevTab = this.activeTab;

    // If the screen is "small" when the page first loads, no tabs should be displayed
    if (this.screenSize.is('prfSM')) {
      this.activeTab = null;
    }

    this.screenSize.on('prfSM', this.onScreenSizeChange.bind(this));
  }

  /**
   * Called when the screen size changes.
   *
   * @param {bool} isSmall - is the screen size "small" ("prfSM") now?
   */
  onScreenSizeChange(isSmall) {
    if (!isSmall && !this.activeTab) {
      // When the screen size changes FROM small (to big), re-activate initial tab
      this.activeTab = this.initialTab;
    }
  }
}

export default {
  template,
  controller: SidebarTabsController,
  bindings: {
    customer: '<',
  },
};
