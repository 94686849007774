import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import template from './custom-email-template-general-form.component.html';
import { FormControl, FormGroup } from '@proftit/ng1.reactive-forms';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  isNotNilFormValidator,
  isNotEmptyArrayValidator,
} from '@proftit/ng1.reactive-forms.validators';
import {
  CustomContentTemplate,
  Brand,
  PlatformType,
} from '@proftit/crm.api.models.entities';
import { PlatformTypeCode } from '@proftit/crm.api.models.enums';

const styles = require('./custom-email-template-general-form.component.scss');

export class CustomEmailTemplateGeneralFormController {
  onChange: (a: { change }) => void;
  onIsValid: (a: { isValid: boolean }) => void;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  excludedBrands: number[] = [];
  platformType: PlatformTypeCode;
  brands$ = new rx.BehaviorSubject<any[]>(null);
  platformType$ = new rx.BehaviorSubject<any>(null);
  platformTypesData$ = new rx.BehaviorSubject<any>(null);
  model$ = observeShareCompChange<CustomContentTemplate>(
    this.lifecycles.onChanges$,
    'model',
  );

  form$ = this.streamForm();

  constructor() {
    useStreams(
      [
        this.model$,
        this.form$.pipe(rx.switchMap((f) => f.value$)),
        this.streamNotifyChange(),
        this.streamNotifyIsValid(),
        this.streamExcludedBrands(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamForm() {
    return rx.obs
      .combineLatest([this.model$, this.platformTypesData$, this.brands$])
      .pipe(
        rx.filter(
          ([model, platformTypesData, brandsData]) =>
            !!platformTypesData && !!brandsData,
        ),
        rx.map(([model, platformTypesData, brandsData]) => {
          if (model.brands.length > 0) {
            let defaultPlatformType = this.getPlatformTypeModelByBrand(
              model.brands[0],
              platformTypesData,
              brandsData,
            );
            model.platformType = defaultPlatformType;
            this.platformType$.next(defaultPlatformType);
          }
          return modelToForm(model);
        }),
        shareReplayRefOne(),
      );
  }

  streamNotifyChange() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) =>
        rx.obs.combineLatest(form.value$, form.isAtFirstValue$),
      ),
      rx.filter(([value, isAtFirstValue]) => !isAtFirstValue),
      rx.tap(([change, a]) => this.onChange({ change })),
    )(null);
  }

  streamNotifyIsValid() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.isValid$),
      rx.tap((isValid) => this.onIsValid({ isValid })),
    )(null);
  }

  streamExcludedBrands() {
    return rx.obs.combineLatest(this.brands$, this.platformType$).pipe(
      rx.filter(([brands, platformType]) => platformType && !!brands),
      rx.tap(([brands, platformType]) => {
        this.platformType = platformType;
        this.updateExcludedBrands(platformType.code, brands);
      }),
    );
  }

  updateExcludedBrands(platformTypeCode: PlatformTypeCode, brands: Brand[]) {
    this.excludedBrands = brands
      .filter((brand) => {
        return platformTypeCode === PlatformTypeCode.Prop
          ? brand.platformType.code !== PlatformTypeCode.Prop
          : brand.platformType.code === PlatformTypeCode.Prop;
      })
      .map(({ id }) => id);
  }

  getPlatformTypeModelByBrand(
    brand: Brand,
    platformTypesData: PlatformType[],
    BrandsData: Brand[],
  ) {
    let selectedBrand = BrandsData.find(
      (brandItem) => brand.id === brandItem.id,
    );
    return platformTypesData.find(
      (platformType) => platformType.code === selectedBrand.platformType.code,
    );
  }
}

function modelToForm(model: CustomContentTemplate) {
  return new FormGroup({
    brands: new FormControl(model.brands, {
      validators: [isNotNilFormValidator, isNotEmptyArrayValidator],
    }),
    departments: new FormControl(model.departments, {
      validators: [isNotNilFormValidator, isNotEmptyArrayValidator],
    }),
    platformType: new FormControl(model.platformType, {
      validators: [isNotNilFormValidator],
    }),
  } as any);
}

export const CustomEmailTemplateGeneralFormComponent = {
  template,
  controller: CustomEmailTemplateGeneralFormController,
  bindings: {
    model: '<',
    onChange: '&',
    onIsValid: '&',
    platformType: '=',
  },
};
