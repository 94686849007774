import template from './content-template-item.component.html';
const styles = require('./content-template-item.component.scss');

import ng, { IWindowService } from 'angular';
import log from 'loglevel';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import { switchOn, switchOnEx } from '@proftit/general-utilities';
import { getCompChange } from '~/source/common/utilities/rx-ng-one/operators/get-comp-change';
import { observeCompChange } from '~/source/common/utilities/observe-comp-change';
import { ModelNormalizerService } from '~/source/common/services/model-normalizer';
import { ContentTemplatesService } from '~/source/common/api-crm-server/services/content-templates.service';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import type { StateService } from '@uirouter/angularjs';
import * as _ from '@proftit/lodash';
import { ContentTemplatesPreviewsService } from '~/source/common/api-crm-server/services/content-templates-previews.service';
import { ExternalWindowService } from '~/source/common/services/external-window.service';
import { CrudOpeartion } from '~/source/common/models/crud-operation';
import { SubCrudOperation } from '~/source/common/models/sub-crud-operation';
import { EmailTemplatePreviewService } from '~/source/common/services/email-template-preview.service';
import {
  ContentTemplateTypes,
  ContentTemplateTypeCode,
} from '@proftit/crm.api.models.enums';
import {
  ContentTemplate,
  CustomContentTemplate,
} from '@proftit/crm.api.models.entities';
import { ContentTemplateStore } from '~/source/common/store-services/content-template-store.service';

const UPDATE_TITLE = 'emailTemplates.contentItem.TITLE_UPDATE';
const CREATE_TITLE = 'emailTemplates.contentItem.TITLE_CREATE';

interface ModelChange {
  fieldName: string;
  nextValue: any;
}

const cloneSystemRoute =
  'crm.management.tools.emails.contenttemplate.copy.system';
const cloneCustomRoute =
  'crm.management.tools.emails.contenttemplate.copy.custom';

export class ContentTemplateItemController {
  styles = styles;

  // TemplateType = TemplateType;

  CrudOpeartion = CrudOpeartion;

  ContentTemplateTypeCode = ContentTemplateTypeCode;

  lifecycles = observeComponentLifecycles(this);

  blockUiId = generateBlockuiId();

  growlId = generateGrowlId();

  action$ = observeShareCompChange<CrudOpeartion>(
    this.lifecycles.onChanges$,
    'action',
  );

  subAction$ = observeShareCompChange<SubCrudOperation>(
    this.lifecycles.onChanges$,
    'subAction',
  );

  id$ = observeShareCompChange<number>(this.lifecycles.onChanges$, 'id');

  parentId$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'parentId',
  );

  templateType$ = observeShareCompChange<ContentTemplateTypeCode>(
    this.lifecycles.onChanges$,
    'templateType',
  );

  setGeneralChange$ = new rx.Subject<ContentTemplate>();

  setDesignChange$ = new rx.Subject<ContentTemplate>();

  setContentChange$ = new rx.Subject<ContentTemplate>();

  setCustomChange$ = new rx.Subject<CustomContentTemplate>();

  setGeneralIsValid$ = new rx.Subject<boolean>();

  setDesignIsValid$ = new rx.Subject<boolean>();

  setContentIsValid$ = new rx.Subject<boolean>();

  setCustomIsValid$ = new rx.Subject<boolean>();

  opSave$ = new rx.Subject<void>();

  cancelAction = new rx.Subject<void>();

  contentTemplateStoreInst$ = this.streamContentTemplateStoreInst();

  pageTitle$ = this.streamPageTitle();

  contentTemplate$ = this.streamContentTemplate();

  isContentTemplateValid$ = this.streamIsContentTemplateValid();

  cloneLink$ = this.streamCloneLink();

  isCloneActionEnabled$ = this.streamIsCloneActionEnabled();

  opPreviewContent$ = new rx.Subject<void>();

  /*@ngInject */
  constructor(
    readonly modelNormalizer: ModelNormalizerService,
    readonly $state: StateService,
    readonly contentTemplatesPreviewsService: () => ContentTemplatesPreviewsService,
    readonly externalWindowService: ExternalWindowService,
    readonly emailTemplatePreviewService: EmailTemplatePreviewService,
    readonly prfContentTemplateStore: () => ContentTemplateStore,
  ) {
    useStreams(
      [
        this.action$,
        this.subAction$,
        this.id$,
        this.parentId$,
        this.templateType$,
        this.pageTitle$,
        this.streamCancelAction(),
        this.streamPreviewContent(),
        this.isContentTemplateValid$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamContentTemplateStoreInst() {
    return rx.pipe(
      () => rx.obs.from([this.prfContentTemplateStore()]),
      shareReplayRefOne(),
    )(null);
  }

  streamPageTitle() {
    return rx.pipe(
      () => this.action$,
      rx.map((action) => {
        return switchOn(
          {
            [CrudOpeartion.Create]: () => CREATE_TITLE,
            [CrudOpeartion.Update]: () => UPDATE_TITLE,
          },
          action,
          () => {
            throw new Error('unhandled action');
          },
        );
      }),
      rx.tap((title) => this.pageTitle$.next(title)),
    )(null);
  }

  streamContentTemplateFromStoreFromRetrieveOps() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.action$,
          this.subAction$,
          this.id$,
          this.parentId$.pipe(rx.startWith(null)),
          this.templateType$,
          this.contentTemplateStoreInst$,
        ),
      rx.filter(([action, subAction, id, parentId, templateType]) => {
        if ([action, templateType].some(_.isNil)) {
          return false;
        }

        if (action === CrudOpeartion.Update) {
          if (_.isNil(id)) {
            return false;
          }

          if (templateType === ContentTemplateTypeCode.System) {
            if (_.isNil(parentId)) {
              return false;
            }
          }
        }

        if (action === CrudOpeartion.Create) {
          if (subAction === SubCrudOperation.Copy) {
            if (_.isNil(id)) {
              return false;
            }

            if (templateType === ContentTemplateTypeCode.System) {
              if (_.isNil(parentId)) {
                return false;
              }
            }
          }
        }

        return true;
      }),
      rx.tap(([action, subAction, id, parentId, templateType, store]) => {
        if (action === CrudOpeartion.Create) {
          if (subAction === SubCrudOperation.Copy) {
            return store.clone(id, parentId, templateType);
          }

          return store.getNew(templateType, parentId);
        }

        if (action === CrudOpeartion.Update) {
          return store.getOne(id, parentId, templateType);
        }

        throw new Error('unintented');
      }),
      rx.switchMap(() => rx.obs.NEVER),
    )(null);
  }

  streamCreateUpdateTemplate(model, action, store) {
    const promise = switchOnEx(
      {
        [CrudOpeartion.Create]: () => store.create(model),
        [CrudOpeartion.Update]: () => store.update(model),
      },
      action,
    );

    return rx.obs.from(promise).pipe(
      rx.catchError((err) => {
        return rx.obs.NEVER;
      }),
    );
  }

  streamContentTemplateFromStoreFromSave(
    contentTemplate$: rx.Observable<ContentTemplate>,
  ) {
    return rx.pipe(
      () => this.opSave$,
      rx.withLatestFrom(
        contentTemplate$,
        this.action$,
        this.contentTemplateStoreInst$,
      ),
      rx.switchMap(([a, model, action, store]) => {
        return this.streamCreateUpdateTemplate(model, action, store);
      }),
      rx.catchError((err, caught) => {
        log.error('error update/create template', err);
        return caught;
      }),
      rx.withLatestFrom(this.templateType$),
      rx.tap(([a, templateType]) => {
        switchOnEx(
          {
            [ContentTemplateTypeCode.Custom]: () =>
              this.$state.go(
                'crm.management.tools.emails.dashboard.customemailtemplates',
              ),
            [ContentTemplateTypeCode.System]: () =>
              this.$state.go(
                'crm.management.tools.emails.dashboard.systememails',
              ),
          },
          templateType,
        );
      }),
      rx.switchMap(() => rx.obs.NEVER),
    )(null);
  }

  streamContentTemplateFromStore(
    contentTemplate$: rx.Observable<ContentTemplate>,
  ) {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamContentTemplateFromStoreFromRetrieveOps(),
          this.streamContentTemplateFromStoreFromSave(contentTemplate$),
        ),
      rx.switchMap(() => rx.obs.NEVER),
    )(null);
  }

  streamContentTemplateFromGeneralChange(
    contentTemplate$: rx.Observable<ContentTemplate>,
  ) {
    return rx.pipe(
      () => this.setGeneralChange$,
      rx.withLatestFrom(contentTemplate$),
      rx.map(([change, contentTemplate]) => {
        return {
          ...contentTemplate,
          ..._.pick(['name', 'isActive', 'subject', 'language'], change),
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamContentTemplateFromCustomChange(
    contentTemplate$: rx.Observable<CustomContentTemplate>,
  ) {
    return rx.pipe(
      () => this.setCustomChange$,
      rx.withLatestFrom(contentTemplate$),
      rx.map(([change, contentTemplate]) => {
        return {
          ...contentTemplate,
          ..._.pick(['brands', 'departments', 'platformType'], change),
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamContentTemplateFromDesignChange(
    contentTemplate$: rx.Observable<ContentTemplate>,
  ) {
    return rx.pipe(
      () => this.setDesignChange$,
      rx.withLatestFrom(contentTemplate$),
      rx.map(([change, contentTemplate]) => {
        return {
          ...contentTemplate,
          ..._.pick(['designTemplate'], change),
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamContentTemplateFromContentChange(
    contentTemplate$: rx.Observable<ContentTemplate>,
  ) {
    return rx.pipe(
      () => this.setContentChange$,
      rx.withLatestFrom(contentTemplate$),
      rx.map(([change, contentTemplate]) => {
        return {
          ...contentTemplate,
          ..._.pick(['content'], change),
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamContentTemplateFromUiChanges(
    contentTemplate$: rx.Observable<ContentTemplate>,
  ) {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamContentTemplateFromGeneralChange(contentTemplate$),
          this.streamContentTemplateFromDesignChange(contentTemplate$),
          this.streamContentTemplateFromContentChange(contentTemplate$),
          this.streamContentTemplateFromCustomChange(contentTemplate$),
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamContentTemplate() {
    const contentTemplate$ = new rx.BehaviorSubject<ContentTemplate>(null);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.contentTemplateStoreInst$.pipe(
            rx.switchMap((inst) => inst.contentTemplate$),
          ),
          this.streamContentTemplateFromStore(contentTemplate$),
          this.streamContentTemplateFromUiChanges(contentTemplate$),
          // contentTemplate$.pipe(rx.distinctUntilChanged()),
        ),
      rx.tap((contentTemplate) => contentTemplate$.next(contentTemplate)),
      shareReplayRefOne(),
    )(null);
  }

  streamIsContentTemplateValid() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.setGeneralIsValid$,
          this.setDesignIsValid$,
          this.templateType$.pipe(
            rx.filter((type) => type === ContentTemplateTypeCode.Custom),
            rx.switchMap(() => this.setContentIsValid$),
            rx.startWith(true),
          ),
          this.templateType$.pipe(
            rx.filter((type) => type === ContentTemplateTypeCode.System),
            rx.switchMap(() => this.setCustomIsValid$),
            rx.startWith(true),
          ),
        ),
      rx.map((results) => results.every((r) => r)),
      shareReplayRefOne(),
    )(null);
  }

  streamCloneLink() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.id$,
          this.parentId$.pipe(rx.startWith(null)),
          this.templateType$,
        ),
      rx.map(([id, parentId, templateType]) => {
        return switchOnEx(
          {
            [ContentTemplateTypeCode.System]: () => {
              return `${cloneSystemRoute}({ parentId: ${parentId}, id: ${id} })`;
            },
            [ContentTemplateTypeCode.Custom]: () => {
              return `${cloneCustomRoute}({ id: ${id} })`;
            },
          },
          templateType,
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamIsCloneActionEnabled() {
    return rx.pipe(
      () => this.action$,
      rx.map((action) => {
        return switchOnEx(
          {
            [CrudOpeartion.Create]: () => false,
            [CrudOpeartion.Update]: () => true,
          },
          action,
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamCancelAction() {
    return rx.pipe(
      () => this.cancelAction,
      rx.withLatestFrom(this.templateType$),
      rx.tap(([a, templateType]) => {
        switchOnEx(
          {
            [ContentTemplateTypeCode.Custom]: () =>
              this.$state.go(
                'crm.management.tools.emails.dashboard.customemailtemplates',
              ),
            [ContentTemplateTypeCode.System]: () =>
              this.$state.go(
                'crm.management.tools.emails.dashboard.systememails',
              ),
          },
          templateType,
        );
      }),
    )(null);
  }

  streamPreviewContent() {
    return rx.pipe(
      () => this.opPreviewContent$,
      rx.withLatestFrom(this.contentTemplate$),
      rx.switchMap(([a, model]) => {
        const brand = _.get(['system', 'brand'], model);

        return rx.obs.from(
          this.emailTemplatePreviewService.generateEmailPreview(model, brand),
        );
      }),
      rx.tap((content) =>
        this.externalWindowService.openContentInCenter(content),
      ),
      rx.catchError((e, c) => {
        log.error('return previewing template', e);
        return c;
      }),
    )(null);
  }
}

/*
function normalizeModelForUpdate(model: ContentTemplate) {
  return {
    ..._.pick(['id', 'name', 'subject', 'content'], model),
    designTemplateId: model.designTemplate.id,
    customEmailTemplates: model.customEmailTemplates,
    systemEmailTemplates: model.systemEmailTemplates,
  };
}
*/

export const ContentTemplateItemComponent = {
  template,
  controller: ContentTemplateItemController,
  bindings: {
    action: '<',
    subAction: '<',
    id: '<',
    parentId: '<',
    templateType: '<',
  },
};
