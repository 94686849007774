export const purchaseLinkedDocumentsPopupSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 3,
        page: 1,
        sorting: { uploadedAt: 'desc' },
      },
      settings: {
        counts: [],
      },
    },
  },
};
