import RestService from '~/source/common/services/rest';
import { PlatformTypeCode } from '@proftit/crm.api.models.enums';
import { PlatformType } from '@proftit/crm.api.models.entities';
const NOT_ALLOWED_PLATFORMS = [PlatformTypeCode.Binary];

export class PlatformTypesService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'platformTypes';
  }

  getPlatformTypes() {
    return this.getListWithQuery();
  }

  getAllowedPlatformTypes(): Promise<PlatformType[]> {
    return this.getPlatformTypes()
      .then((data) => data.plain() as PlatformType[])
      .then((data: PlatformType[]) =>
        data.filter((row) => !NOT_ALLOWED_PLATFORMS.includes(row.code)),
      );
  }

  /**
   * Cache the result
   *
   * @returns {boolean}
   */
  useCache() {
    return true;
  }
}
