import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import template from './challenge-phase.component.html';
import {
  ChallengeAsset,
  ChallengePhase,
  ChallengePhaseType,
} from '@proftit/crm.api.models.entities/src';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';

const styles = require('./challenge-phase.component.scss');

export class ChallengePhaseController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  phaseType: ChallengePhaseType;
  phaseModel: ChallengePhase;
  nameEditValue: string = '';
  isNameEditMode: boolean = false;

  phaseChange$ = new rx.Subject();
  assets: ChallengeAsset[] = [];
  invalidFields = {
    mandatory: {},
    losses: false,
    phaseInvalid: false,
    dependencies: {},
  };
  onUpdate: () => void;
  onDelete: () => void;

  /* @ngInject */
  constructor() {
    useStreams([this.streamPhaseChange()], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
  streamPhaseModel = () => {
    return rx.pipe(() => this.phaseModel)(null);
  };

  /**
   * Initiates the process to edit the phase name.
   * This method sets the `nameEditValue` with the current phase model's name and enables the name edit mode.
   */
  editName = () => {
    this.nameEditValue = this.phaseModel.name;
    this.isNameEditMode = true;
  };

  /**
   * Saves the edited phase name.
   * This method updates the phase model's name with the edited value and disables the name edit mode.
   */
  saveName = () => {
    this.phaseModel.name = this.nameEditValue;
    this.isNameEditMode = false;
  };

  /**
   * Streams changes to the phase model.
   * This method creates an observable stream that emits the current phase model whenever it changes,
   * logs the current phase model to the console, and validates the phase.
   * @returns An observable stream of the current phase model.
   */
  streamPhaseChange = () => {
    return this.phaseChange$.pipe(
      rx.map(() => this.phaseModel),
      rx.tap(() => {
        this.validatePhase();
      }),
      shareReplayRefOne(),
    );
  };

  /**
   * Validates the current phase of a challenge.
   *
   * This method initializes the `invalidFields` object to track mandatory fields, losses, and overall phase validity.
   * It then assigns a default name to the phase model if it doesn't already have one. Based on whether the phase is
   * funded or regular, it selects the appropriate set of validation rules for mandatory fields and losses.
   *
   * Each mandatory field is checked to ensure it has a value; if any are null, they are marked as   invalid in the
   * `invalidFields` object, and the phase is marked as invalid. Similarly, it checks if at least one of the loss fields
   * has a value; if not, the phase is marked as having invalid losses and overall as invalid.
   *
   * Note: The `@ts-ignore` directive is used to bypass TypeScript's static type checking for dynamic field access.
   */
  validatePhase() {
    this.invalidFields = {
      mandatory: {},
      losses: false,
      phaseInvalid: false,
      dependencies: {},
    };
    this.phaseModel.name = this.phaseModel.name || 'New Phase';

    const validationRules = {
      funded: {
        mandatoryFields: ['earning'],
        losses: ['maxDailyLoss', 'maxLoss'],
        dependencies: {
          profitTarget: {
            field: 'earning.calculationType',
            value: 'fixed',
            rule: 'notNull',
          },
        },
      },
      regular: {
        mandatoryFields: ['profitTarget'],
        losses: ['maxDailyLoss', 'maxLoss'],
        dependencies: null,
      },
    };

    const currentRules =
      validationRules[this.phaseModel.isFunded ? 'funded' : 'regular'];

    currentRules.mandatoryFields.forEach((field) => {
      if (
        (this.phaseModel.fields[field] as any).value === null ||
        (this.phaseModel.fields[field] as any).value === undefined
      ) {
        this.invalidFields.mandatory[field] = true;
        this.invalidFields.phaseInvalid = true;
      }
    });

    this.invalidFields.losses = !currentRules.losses.some(
      // @ts-ignore
      (field) => this.phaseModel.fields[field].value !== null,
    );

    const dependencyCheck = currentRules.dependencies;
    if (dependencyCheck) {
      Object.keys(dependencyCheck).forEach((field) => {
        const checkField = dependencyCheck[field];
        const fieldValue = checkField.field.split('.');
        const val = fieldValue.reduce(
          (acc, key) => acc[key],
          this.phaseModel.fields,
        );

        if (
          val === checkField.value &&
          checkField.rule === 'notNull' &&
          !this.phaseModel.fields[field].value
        ) {
          this.invalidFields.dependencies[field] = true;
          this.invalidFields.phaseInvalid = true;
        }
      });
    }
    this.invalidFields.phaseInvalid =
      this.invalidFields.phaseInvalid || this.invalidFields.losses;
    this.phaseModel.isValid = !this.invalidFields.phaseInvalid;
    this.onUpdate();
  }

  deletePhase() {
    this.onDelete();
  }
}

export const challengePhaseComponent = {
  template,
  controller: ChallengePhaseController,

  bindings: {
    phaseModel: '<',
    onUpdate: '&',
    onDelete: '&',
    assetsFromPlatform: '<',
  },
};
