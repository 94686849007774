export const purchaseLinkDocumentPopupSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 10,
        page: 1,
        sorting: { uploadedAt: 'desc' },
      },
      settings: {
        counts: [],
      },
    },
  },
};
