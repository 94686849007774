import { IPromise, IIntervalService } from 'angular';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import AccountsSyncForexService from '~/source/contact/common/services/accounts-sync-forex.service';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import template from './trading-account-prop.html';
import { propAccountTotalsConfig } from '../account-totals-config';
import {
  Customer,
  TradingAccount,
  User,
} from '@proftit/crm.api.models.entities';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import {
  PlatformCode,
  PlatformsWithPendingPositions,
  MT4_PLATFORMS,
} from '@proftit/crm.api.models.enums';
import { TimeInterval } from '@proftit/constants.time';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { useStreams, tapLog, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { BrandsService } from '~/source/management/brand/services/brands';
import { switchOn } from '@proftit/general-utilities';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import { buildCrmAccountChannelName } from '~/source/common/api-crm-server/build-crm-account-channel-name';
import { TokensService } from '~/source/auth/services/tokens';
import { TradingAccountStatuses } from '~/source/common/services/trading-account-statuses';
import log from 'loglevel';
import { HighlightEntityService } from '~/source/common/services/highlight-entity';
import UserTokenModel from '~/source/common/models/user-token-model';
import { PlatformSessionInfo } from '~/source/common/service-directives/platform-session-info';
import {
  CfdPlatformAccountOpenPnlSocketService,
  buildCfdAccountPnlChannel,
} from '~/source/common/api-cfd-platform/cfd-platform-account-open-pnl-socket.service';
import {
  buildMt4AccountPnlChannel,
  Mt4AccountPnlSocketService,
} from '~/source/common/services/mt4-account-pnl-socket.service';
import {
  buildBundleAccountPnlChannel,
  BundleAccountPnlSocketService,
} from '~/source/common/services/bundle-account-pnl-socket.service';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { TRADING_ACCOUNT_STREAMER_UPDATE } from '~/source/common/constants/general-pubsub-keys';
import TradingAccountForexSocketService from '~/source/contact/contact-page/trading-account/forex/trading-account-socket.service';
import TradingAccountBinarySocketService from '~/source/contact/contact-page/trading-account/binary/trading-account-socket.service';
import { CfdBundlePlatformAccountPnlSocketUpdates } from '~/source/contact/contact-page/trading-account/trading-accounts-container/cfd-platform-account-pnl-socket-updates';

const SAMPLE_ACCOUNT_FOREX_STATS_INTERVAL = 10 * TimeInterval.Minute;

const ACCOUNT_PNL_UPDATE_THROTTLE = 5000;

enum SocketSource {
  Crm = 'crm',
  Cfd = 'cfd',
  Mt = 'mt',
  Bundle = 'bundle',
}

class TradingAccountPropController {
  lifecycles = observeComponentLifecycles(this);

  statsCheck: any;

  customer: IElementRestNg<Customer>;

  account: IElementRestNg<TradingAccount>;

  totalsTilesConfig = propAccountTotalsConfig;

  MT4_PLATFORMS = MT4_PLATFORMS;

  prfCurrentPlatformSession$ = new rx.BehaviorSubject<
    CurrentPlatformSessionStoreServiceDirectiveController
  >(null);

  customer$ = observeShareCompChange<Customer>(
    this.lifecycles.onChanges$,
    'customer',
  );

  account$ = observeShareCompChange<TradingAccount>(
    this.lifecycles.onChanges$,
    'account',
  );

  tradingAccountStatuses$ = this.streamTradingAccountStatuses();

  user$ = this.streamUser();

  /*@ngInject*/
  constructor(
    readonly accountsSyncForexService: AccountsSyncForexService,
    readonly $interval: IIntervalService,
    readonly highlightEntityService: HighlightEntityService,
    readonly tokensService: TokensService,
    readonly brandsService: () => BrandsService,
    readonly tradingAccountForexSocketService: TradingAccountForexSocketService,
    readonly tradingAccountBinarySocketService: TradingAccountBinarySocketService,
    readonly tradingAccountStatusesService: () => TradingAccountStatuses,
    readonly cfdPlatformAccountOpenPnlSocketService: () => CfdPlatformAccountOpenPnlSocketService,
    readonly mt4AccountPnlSocketService: () => Mt4AccountPnlSocketService,
    readonly bundleAccountPnlSocketService: () => BundleAccountPnlSocketService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    useStreams([this.customer$, this.account$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    useStreams(
      [
        this.tradingAccountStatuses$,
        this.streamLoginToPlatformSession(),
        this.streamSyncAccountChangesFromSockets(),
      ],
      this.lifecycles.onDestroy$,
    );

    // request from server to sync stats from MT4
    this.syncStats();
    // to keep updated, keep sending the request once in a interval
    this.statsCheck = this.$interval(
      () => this.syncStats(),
      SAMPLE_ACCOUNT_FOREX_STATS_INTERVAL,
    );
  }

  $onChanges() {}

  /**
   * Update account stats
   */
  syncStats() {
    this.accountsSyncForexService
      .setConfig({
        // this is a background process. we don't want it to affect the UI
        suppressGrowl: true,
        suppressBlockUi: true,
      })
      .sync(this.account.id, this.customer.id);
  }

  /**
   * Called on component destroy
   */
  $onDestroy() {
    // clear the interval
    this.$interval.cancel(this.statsCheck);
  }

  isShowPendingPositionsTable() {
    return PlatformsWithPendingPositions.includes(
      this.account.platform.code as PlatformCode,
    );
  }

  getSocketServiceForType(type: string) {
    return switchOn(
      {
        //todo replace with prop service then backend is ready
        prop: () => this.tradingAccountForexSocketService,
      },
      type,
      () => {
        throw new Error('unexpected account type');
      },
    );
  }

  streamTradingAccountStatuses() {
    return rx.pipe(
      () =>
        rx.obs.from(this.tradingAccountStatusesService().getListWithQuery()),
      rx.map((statuses) => statuses.plain()),
      shareReplayRefOne(),
    )(null);
  }

  streamUser() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$,
      rx.filter((isInit) => isInit),
      rx.map(() => this.tokensService.getCachedUser()),
      shareReplayRefOne(),
    )(null);
  }

  streamLoginToPlatformSession() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.account$,
          this.prfCurrentPlatformSession$.pipe(
            rx.filter((service) => !_.isNil(service)),
          ),
          this.customer$,
        ),
      rx.switchMap(([account, _sessionService, customer]) => {
        return this.brandsService().getBrandPlatformPerPlatform(
          customer.brand.id,
          account.platform.code,
        );
      }),
      rx.withLatestFrom(this.prfCurrentPlatformSession$),
      rx.switchMap(([brandPlatform, sessionService]) => {
        if (_.isNil(brandPlatform)) {
          return rx.obs.NEVER;
        }
        return sessionService.login(brandPlatform);
      }),
    )(null);
  }

  streamCrmSocketAccountChanges(user: UserTokenModel, account: TradingAccount) {
    return rx.pipe(
      () => rx.obs.of(true),
      rx.switchMap(() => {
        const crmChannelName = buildCrmAccountChannelName(
          user.id as number,
          account.type,
          account.id,
        );

        const crmSocketService = this.getSocketServiceForType(account.type);
        return observeChannel<any>(crmSocketService, crmChannelName);
      }),
      rx.withLatestFrom(this.tradingAccountStatuses$),
      rx.map(([itemUpdates, tradingAccountStatuses]) => {
        const statusCode = tradingAccountStatuses.find(
          (s) => s.code === (itemUpdates as any).statusCode,
        );

        return {
          source: SocketSource.Crm,
          updates: {
            ...(itemUpdates as any),
            statusCode,
          },
        };
      }),
      rx.catchError((err) => {
        log.error(' error in observing crm account updates', err);
        return rx.obs.EMPTY;
      }),
    )(null);
  }

  streamPlatformSocketAccountChangesForCfd(
    sessionInfo: PlatformSessionInfo,
    user: UserTokenModel,
    account: TradingAccount,
  ) {
    return rx.pipe(
      () => rx.obs.of(true),
      rx.switchMap(() => {
        const serviceInstance = this.cfdPlatformAccountOpenPnlSocketService();
        serviceInstance.setToken(sessionInfo.session.token);
        serviceInstance.setStreamerUrl(sessionInfo.session.streamerUrl);

        const channel = buildCfdAccountPnlChannel(account.syncRemoteId);
        return observeChannel<CfdBundlePlatformAccountPnlSocketUpdates>(
          serviceInstance,
          channel,
        );
      }),
      rx.throttleTime(
        ACCOUNT_PNL_UPDATE_THROTTLE,
        rx.scheduler.asyncScheduler,
        {
          leading: true,
          trailing: true,
        },
      ),
      rx.map((itemUpdates: any) => ({
        id: account.id,
        _openPnl: itemUpdates.total,
      })),
      rx.map((updates) => ({ updates, source: SocketSource.Cfd })),
      rx.catchError((err) => {
        log.error(' error in observing cfd account updates', err);
        return rx.obs.EMPTY;
      }),
    )(null);
  }

  streamPlatformSocketAccountChangesForMt4(
    sessionInfo: PlatformSessionInfo,
    user: UserTokenModel,
    account: TradingAccount,
  ) {
    return rx.pipe(
      () => rx.obs.of(true),
      rx.switchMap(() => {
        const serviceInstance = this.mt4AccountPnlSocketService();
        serviceInstance.setToken(sessionInfo.session.token);
        serviceInstance.setStreamerUrl(sessionInfo.session.streamerUrl);

        const channel = buildMt4AccountPnlChannel(account.syncRemoteId);
        return observeChannel(serviceInstance, channel);
      }),
      rx.throttleTime(
        ACCOUNT_PNL_UPDATE_THROTTLE,
        rx.scheduler.asyncScheduler,
        {
          leading: true,
          trailing: true,
        },
      ),
      rx.map((itemUpdates: any) => ({
        id: account.id,
        _openPnl: itemUpdates.pnl,
      })),
      rx.map((updates) => ({ updates, source: SocketSource.Mt })),
      rx.catchError((err) => {
        log.error(' error in observing cfd account updates', err);
        return rx.obs.EMPTY;
      }),
    )(null);
  }

  streamPlatformSocketAccountChangesForBundles(
    sessionInfo: PlatformSessionInfo,
    user: UserTokenModel,
    account: TradingAccount,
  ) {
    return rx.pipe(
      () => rx.obs.of(true),
      rx.switchMap(() => {
        const serviceInstance = this.bundleAccountPnlSocketService();
        serviceInstance.setToken(sessionInfo.session.token);
        serviceInstance.setStreamerUrl(sessionInfo.session.streamerUrl);

        const channel = buildBundleAccountPnlChannel(account.syncRemoteId);
        return observeChannel(serviceInstance, channel);
      }),
      rx.throttleTime(
        ACCOUNT_PNL_UPDATE_THROTTLE,
        rx.scheduler.asyncScheduler,
        {
          leading: true,
          trailing: true,
        },
      ),
      rx.map((itemUpdates: any) => ({
        id: account.id,
        _openPnl: itemUpdates.total,
      })),
      rx.map((updates) => ({ updates, source: SocketSource.Bundle })),
      rx.catchError((err) => {
        log.error(' error in observing cfd account updates', err);
        return rx.obs.EMPTY;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamPlatformSocketAccountChanges(
    user: UserTokenModel,
    account: TradingAccount,
  ) {
    return rx.pipe(
      () => this.prfCurrentPlatformSession$,
      rx.filter((service) => !_.isNil(service)),
      rx.switchMap((service) => service.sessionS.stream$),
      rx.switchMap((sessionInfo) => {
        if (!sessionInfo.isLoggedIn) {
          return rx.obs.NEVER;
        }

        if (sessionInfo.platform.code === PlatformCode.Cfd) {
          return this.streamPlatformSocketAccountChangesForCfd(
            sessionInfo,
            user,
            account,
          );
        }

        if (MT4_PLATFORMS.includes(sessionInfo.platform.code)) {
          return this.streamPlatformSocketAccountChangesForMt4(
            sessionInfo,
            user,
            account,
          );
        }

        if (sessionInfo.platform.code === PlatformCode.Bundle) {
          return this.streamPlatformSocketAccountChangesForBundles(
            sessionInfo,
            user,
            account,
          );
        }

        return rx.obs.NEVER;
      }),
    )(null);
  }

  streamSyncAccountChangesFromSockets() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.user$, this.account$),
      rx.switchMap(([user, account]) => {
        return rx.obs.merge(
          this.streamCrmSocketAccountChanges(user, account),
          this.streamPlatformSocketAccountChanges(user, account),
        );
      }),
      rx.withLatestFrom(this.account$),
      rx.tap(([{ source, updates }, account]) => {
        /*
         * We udapte mutablty the account and accounts.
         * This is bescause currently the children components also update
         * some of the same ref account props maybe. Need further investigation.
         * There is a reliance that once a ref for account created, it does not change
         * for children components.
         */
        Object.assign(account, updates);

        if (source === SocketSource.Crm) {
          this.highlightEntityService.highlight(account);
        }

        this.prfClientGeneralPubsub.publish(TRADING_ACCOUNT_STREAMER_UPDATE, {
          updates,
          accountId: account.id,
        });
      }),
    )(null);
  }
}

export default {
  template,
  controller: TradingAccountPropController,
  bindings: {
    customer: '<',
    account: '<',
    phase: '<',
  },
};
