import template from './add-contact-dialog.component.html';
import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { ClientGeneralPubsub } from '../../services/client-general-pubsub';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { filterCompChange } from '../../utilities/rxjs/observables/filter-comp-change';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import { AddContactFormModel } from '../add-contact-form/add-contact-form-model';
import { CustomersService } from '~/source/contact/common/services/customers';
import { ModelNormalizerService } from '../../services/model-normalizer';
import {
  PlatformTypeCode,
  UserRolePositionCode,
} from '@proftit/crm.api.models.enums';
import { removeCustomerOnlyFields } from './remove-customer-only-fields';
import { addDefaultCustomerOnlyFields } from './add-default-customer-only-fields';
import { FORM_ACCOUNT_FIELDS } from './form-account-fields';
import { addAccountDataToCustomer } from './add-account-data-to-customer';
import { CONTACT_ADDED } from '~/source/common/constants/general-pubsub-keys';
import TokensService from '~/source/auth/services/tokens';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { User } from '@proftit/crm.api.models.entities';
import UsersService from '~/source/management/user/services/users';

const styles = require('./add-contact-dialog.component.scss');

interface AddContactDialogResolve {}

interface ModelChange {
  fieldName: string;
  nextValue: any;
}

const AssignedToShowingRole = [
  UserRolePositionCode.Manager,
  UserRolePositionCode.SuperAdmin,
  UserRolePositionCode.Admin,
];

export class AddContactDialogController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  close: () => void;

  title = 'contact.ADD_CONTACT_DIALOG_TITLE';
  blockUiId = 'addContactDialogBlockUi';
  growlId = 'addContactDialogGrowl';
  PlatformTypeCode = PlatformTypeCode;

  contactForm$ = new rx.BehaviorSubject<Partial<AddContactFormModel>>({
    isLead: true,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    country: null,
    brand: null,
    desk: null,
    user: null,
    accountPlatform: null,
    accountCurrency: null,
    accountGroup: null,
    accountStatus: null,
    accountIsDemo: null,
  });
  onUpdateContactForm$ = new rx.Subject<ModelChange>();
  isContactFormValid$ = new rx.BehaviorSubject<boolean>(false);
  opCreateCustomer$ = new rx.Subject<void>();
  formBrand$ = this.streamFormBrand();
  showAssignedTo$ = this.streamShowAssignedTo();

  /*@ngInject */
  constructor(
    readonly blockUI: ng.blockUI.BlockUIService,
    readonly growl: ng.growl.IGrowlService,
    readonly growlMessages: ng.growl.IGrowlMessagesService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
    readonly customersService: () => CustomersService,
    readonly modelNormalizer: ModelNormalizerService,
    readonly tokensService: TokensService,
    readonly usersService: () => UsersService,
  ) {
    useStreams(
      [
        this.streamInputResolve(),
        this.streamFormChanges(),
        this.streamCreateCustomer(),
        this.streamCalcDefaultUser(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamInputResolve() {
    return rx.pipe(
      () =>
        filterCompChange<AddContactDialogResolve>(
          'reslove',
          this.lifecycles.onChanges$,
        ),
      rx.map(({ currentValue }) => currentValue),
    )(null);
  }

  streamFormChanges() {
    return rx.pipe(
      () => this.onUpdateContactForm$,
      rx.withLatestFrom(this.contactForm$),
      rx.map(([change, contactForm]) => {
        let newForm = {};

        newForm = {
          ...contactForm,
          [change.fieldName]: change.nextValue,
        };

        if (change.fieldName === 'isLead') {
          if (change.nextValue) {
            newForm = removeCustomerOnlyFields(newForm);
          } else {
            newForm = addDefaultCustomerOnlyFields(newForm);
          }
        }

        return newForm;
      }),
      rx.tap((contactForm) => this.contactForm$.next(contactForm)),
    )(null);
  }

  streamCreateCustomer() {
    return rx.pipe(
      () => this.opCreateCustomer$,
      rx.withLatestFrom(this.contactForm$),
      rx.switchMap(([a, contactForm]) => {
        return rx.obs.from(this.createContactOrLead(contactForm)).pipe(
          rx.tap(() => this.close()),
          rx.catchError(() => rx.obs.EMPTY),
        );
      }),
    )(null);
  }

  /**
   *
   */
  createContactOrLead(form) {
    if (form.isLead || form.brand.platformType.code === PlatformTypeCode.Prop) {
      return this.createLead(form);
    }

    return this.createCustomer(form);
  }

  /**
   *
   */
  createLead(form) {
    const customer = this.modelNormalizer.normalize(form);

    return this.customersService()
      .setConfig({
        growlRef: this.growlId,
        blockUiRef: this.blockUiId,
      })
      .createCustomer(customer);
  }

  /**
   *
   */
  createCustomer(form) {
    const accountData = _.pick(FORM_ACCOUNT_FIELDS, form);
    const customerData = _.omit(FORM_ACCOUNT_FIELDS, form);
    const customerPlusAccountData = addAccountDataToCustomer(
      customerData,
      accountData,
    );
    const customer = this.modelNormalizer.normalize(customerPlusAccountData);

    return this.customersService()
      .setConfig({
        growlRef: this.growlId,
        blockUiRef: this.blockUiId,
      })
      .createCustomer(customer);
  }

  streamShowAssignedTo() {
    return rx.pipe(
      () => rx.obs.from([this.tokensService.getCachedUser()]),
      rx.map((user) => AssignedToShowingRole.includes(user.role.code)),
      shareReplayRefOne(),
    )(null);
  }

  streamFormBrand() {
    return rx.pipe(
      () => this.contactForm$,
      rx.map((contactForm) => contactForm.brand),
      shareReplayRefOne(),
    )(null);
  }

  streamCalcDefaultUser(): rx.Observable<any> {
    return rx.pipe(
      () => this.formBrand$,
      rx.distinctUntilChanged(),
      rx.withLatestFrom(this.showAssignedTo$),
      rx.filter(([brand, showAssignedTo]) => !showAssignedTo),
      rx.switchMap(([brand, b]) => {
        return this.usersService()
          .embed(['brands', 'brands.desks'])
          .getOneWithQuery<IElementRestNg<User>>(
            this.tokensService.getCachedUser().id,
          );
      }),
      rx.map((user) => user.plain()),
      rx.withLatestFrom(this.formBrand$),
      rx.filter(([user, formBrand]) => !_.isNil(formBrand)),
      rx.map(([user, formBrand]) => {
        const desk = user.brands.find((brand) => brand.id === formBrand.id)
          .desks[0];
        return { user, desk };
      }),
      rx.withLatestFrom(this.contactForm$),
      rx.tap(([{ user, desk }, contactForm]) => {
        const newForm = {
          ...contactForm,
          desk,
          user,
        };

        this.contactForm$.next(newForm);
      }),
    )(null) as rx.Observable<any>;
  }
}

export const AddContactDialogComponent = {
  template,
  controller: AddContactDialogController,
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};
