import angular from 'angular';
import ComplianceModule from './compliance/module';
import ContactInfoModule from './contact-info/module';
import HiglightsModule from './highlights/module';
import HiglightsPropsModule from './highlights-props/module';
import MarketingModule from './marketing-tab/module';
import { AuditLogsTab } from './audit-logs-tab/audit-logs-tab';
import LoginHistoryPopupController from './login-history-popup/login-history-popup.controller';
import LoginHistoryComponent from './login-history/login-history.component';
import { InfoAuditLogTable } from './audit-logs-tab/info-audit-log-table/info-audit-log-table';
import { CustomerAttachmentAuditLogTable } from './audit-logs-tab/customer-attachment-audit-log-table/customer-attachment-audit-log-table';
import { AssignmentAuditLogTable } from './audit-logs-tab/assignment-audit-logs-table/assignment-audit-log-table';
import { WithdrawalAuditLogTable } from './audit-logs-tab/withdrawal-audit-log-table/withdrawal-audit-log-table';
import { InfoAuditLogPopupTable } from './audit-logs-tab/info-audit-log-popup/info-audit-log-popup';
import { DepositAuditLogTable } from './audit-logs-tab/deposit-owner-audit-logs/deposit-audit-log-table';
import { WithdrawalAuditLogPopupTable } from './audit-logs-tab/withdrawal-audit-log-popup/withdrawal-audit-log-popup';
import { AssignmentAuditLogPopupTable } from './audit-logs-tab/assignment-audit-log-popup/assignment-audit-log-popup';
import { AttachmentAuditLogPopupTable } from './audit-logs-tab/customer-attachment-audit-log-popup/customer-attachment-audit-log-popup';
import { DepositAuditLogPopupTable } from './audit-logs-tab/deposit-audit-log-popup/deposit-audit-log-popup';
import SidebarTabsComponent from './side-bar-container/sidebar-tabs.component';
import { CustomerTradingAccountsAuditLogTableComponent } from './audit-logs-tab/customer-trading-accounts-audit-log-table/customer-trading-accounts-audit-log-table.component';
import { CustomerTradingAccountsAuditLogPopupComponent } from './audit-logs-tab/customer-trading-accounts-audit-log-popup/customer-trading-accounts-audit-log-popup.component';
import { CustomerPageViewAuditLogPopupComponent } from '~/source/contact/contact-page/sidebar-tabs/audit-logs-tab/customer-page-view-audit-log-popup/customer-page-view-audit-log-popup.component';
import { CustomerPageViewAuditLogTableComponent } from '~/source/contact/contact-page/sidebar-tabs/audit-logs-tab/customer-page-view-audit-log-table/customer-page-view-audit-log-table.component';

export const SidebarModule = angular.module('crm.contact.sidebar', [
  ComplianceModule,
  ContactInfoModule,
  HiglightsModule,
  HiglightsPropsModule,
  MarketingModule,
]);

SidebarModule.component('prfLoginHistory', LoginHistoryComponent)
  .controller('LoginHistoryPopupController', LoginHistoryPopupController)
  .component('prfAuditLogsTab', AuditLogsTab)
  .component('prfInfoAuditLogTable', InfoAuditLogTable)
  .component('prfAssignmentAuditLogTable', AssignmentAuditLogTable)
  .component('prfAssignmentAuditLogPopupTable', AssignmentAuditLogPopupTable)
  .component('prfAttachmentAuditLogPopupTable', AttachmentAuditLogPopupTable)
  .component('prfWithdrawalAuditLogTable', WithdrawalAuditLogTable)
  .component('prfInfoAuditTablePopup', InfoAuditLogPopupTable)
  .component('prfWithdrawalAuditLogPopupTable', WithdrawalAuditLogPopupTable)
  .component(
    'prfCustomerAttachmentAuditLogTable',
    CustomerAttachmentAuditLogTable,
  )
  .component('prfDepositAuditLogTable', DepositAuditLogTable)
  .component('prfDepositAuditLogPopupTable', DepositAuditLogPopupTable)
  .component(
    'prfCustomerTradingAccountsAuditLogTable',
    CustomerTradingAccountsAuditLogTableComponent,
  )
  .component(
    'prfCustomerTradingAccountsAuditLogPopup',
    CustomerTradingAccountsAuditLogPopupComponent,
  )
  .component(
    'prfCustomerPageViewAuditLogTable',
    CustomerPageViewAuditLogTableComponent,
  )
  .component(
    'prfCustomerPageViewAuditLogPopup',
    CustomerPageViewAuditLogPopupComponent,
  )
  .component('prfContactSidebarTabs', SidebarTabsComponent);
