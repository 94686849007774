import template from './profit-share-history-popup.component.html';

class ProfitShareHistoryPopupController {
  //bindings
  resolve: any;

  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const ProfitShareHistoryPopupComponent = {
  template,
  controller: ProfitShareHistoryPopupController,
  bindings: {
    close: '&',
    resolve: '<',
  },
};
