import ng from 'angular';

import apiUsersModule from './apiUsers/module';
import { FieldsAndModulesModule } from './fields-and-modules/fields-and-modules.module';
import { IntegrationsModule } from './integrations/integrations.module';
import IpListModule from './iplist/module';
import { QuestionnaireModule } from './questionnaire/questionnaire.module';
import SignupFormModule from './signup-form/module';
import NotificationSettingsModule from './notifications/module';
import DeskModule from './desk/module';
import CashierModule from './cashier/module';
import Clearing3dSettingsModule from './clearing-3d-settings/module';
import UserModule from './user/index';
import PriorityModule from './priority/module';
import SidebarComponent from './sidebar/sidebar.component';
import ManagementBrandModule from './brand/index';
import clearingCompanies from './clearing-companies/module';
import BrandEwalletModule from './brand-ewallet/brand-ewallet.module';
import CryptoEwalletModule from './crypto-ewallet/crypto-ewallet.module';
import { ManagementPermissionsModifierService } from './permissions/permissions-modifier.service';
import { ManagementPermissionsService } from './permissions/permissions.service';
import { GroupModule } from './group';
import { AutomationModule } from './automation/automation.module';
import { EmailsModule } from './emails/emails.module';
import { managementRouting } from './management.routing';
import { ContactsImportModule } from './contacts-import/contacts-import.module';
import { AboutComponent } from '~/source/management/about/about.component';
import { AutoAssignmentModule } from '../auto-assignment/auto-assignment.module';
import ChallengesModule from '~/source/management/challenges/challenges.module';

export const ManagementModule = ng.module('crm.management', [
  'crm.common',
  AutoAssignmentModule.name,
  clearingCompanies,
  ManagementBrandModule,
  UserModule,
  GroupModule.name,
  apiUsersModule,
  Clearing3dSettingsModule,
  PriorityModule,
  IpListModule,
  IntegrationsModule.name,
  NotificationSettingsModule,
  QuestionnaireModule.name,
  SignupFormModule,
  FieldsAndModulesModule.name,
  'ui.router',
  DeskModule,
  CashierModule,
  BrandEwalletModule,
  AutomationModule.name,
  EmailsModule.name,
  ContactsImportModule.name,
  CryptoEwalletModule,
  ChallengesModule,
]);

ManagementModule.config(managementRouting)
  .service(
    'managementPermissionsModifierService',
    ManagementPermissionsModifierService,
  )
  .service('managementPermissionsService', ManagementPermissionsService)
  .component('prfManagementSideMenu', SidebarComponent)
  .component('prfAbout', AboutComponent);
