import * as rx from '@proftit/rxjs';
import template from './challenge-account-info.component.html';
import { Challenge } from '@proftit/crm.api.models.entities/dist/chalenges-types';
import { Currency, Customer } from '@proftit/crm.api.models.entities';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';

//todo remove this, then backend support will be ready
const demoPurchase = {
  id: 25,
  amountRequested: 348,
  amountApproved: 348,
  amountApprovedBasic: 0,
  amountWithdrawable: 7,
  transferMethodTypeCode: 'WIRE_DEPOSIT',
  transactionStatusCode: 'requested',
  bankAccountId: null,
  bonusTransactionId: null,
  isOriginCustomer: true,
  requestedAt: '2024-08-19 00:00:00',
  receivedDate: null,
  rejectionReason: null,
  html3d: null,
  redirectUrl: null,
  is3dSale: false,
  metadata: null,
  showWidget: null,
  formHtml: null,
  transactionCode: null,
  note: 'gdgsdgdgdfgdhgd',
  isChargeBack: false,
  syncRemoteId: '',
  tradingAccountId: 7,
  customerId: 3,
  isDeletable: false,
  siteLanguage: null,
  currency: {
    id: 1,
    code: 'USD',
    name: 'United States dollar',
    number: 840,
    decimals: 2,
    isCrypto: false,
  },
  user: null,
  owner: null,
  ownerDesk: null,
  clearingCompany: null,
  transactionTransferWire: {
    id: 13,
    firstName: null,
    lastName: null,
    accountNumber: null,
    bankName: 'UniCredit Titiac Bank',
    bankAddress: '53/57 Grzybowska street, 2nd floor, Warsaw',
    bankCity: null,
    bankCountryId: 225,
    swiftCode: 'PKOPPLPW',
    iban: 'PL67 1240 6247 1787 0000 49774100',
    confirmedBy: null,
    transactionId: null,
    holderName: '',
    customFields: [],
  },
  transactionTransferCreditCard: null,
  transactionTransferApm: null,
  ewalletTransaction: null,
  mobileTransaction: null,
  attachments: [],
};
type ChallengePurchase = {
  id: number;
  amountRequested: number;
  amountApproved: number;
  amountApprovedBasic: number;
  amountWithdrawable: number;
  transferMethodTypeCode: string;
  transactionStatusCode: string;
  bankAccountId: number;
  bonusTransactionId: number;
  isOriginCustomer: boolean;
  requestedAt: string;
  receivedDate: string;
  rejectionReason: string;
  html3d: string;
  redirectUrl: string;
  is3dSale: boolean;
  metadata: string;
  showWidget: string;
  formHtml: string;
  transactionCode: string;
  note: string;
  isChargeBack: boolean;
  syncRemoteId: string;
  tradingAccountId: number;
  customerId: number;
  isDeletable: boolean;
  siteLanguage: string;
  currency: Currency;
  user: any;
  owner: any;
  ownerDesk: any;
  clearingCompany: any;
  transactionTransferWire: {
    id: number;
    firstName: string;
    lastName: string;
    accountNumber: string;
    bankName: string;
    bankAddress: string;
    bankCity: string;
    bankCountryId: number;
    swiftCode: string;
    iban: string;
    confirmedBy: any;
    transactionId: any;
    holderName: string;
    customFields: any[];
  };
  transactionTransferCreditCard: any;
  transactionTransferApm: any;
  ewalletTransaction: any;
  mobileTransaction: any;
  attachments: any[];
};

export class ChallengeAccountInfoController {
  lifecycles = observeComponentLifecycles(this);
  challenge: Challenge;
  customer: Customer;
  purchase$ = this.streamCustomerChallengePurchase();

  /*@ngInject*/
  constructor() {
    useStreams([this.purchase$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamCustomerChallengePurchase() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      rx.map(() => [this.customer.id, this.challenge.id]),
      rx.switchMap(([customerId, challengeId]) => {
        return rx.obs.from(
          this.fetchCustomerChallengePurchases(customerId, challengeId),
        );
      }),
      rx.map((data) => {
        return data;
      }),
      shareReplayRefOne(),
    )(null);
  }

  async fetchCustomerChallengePurchases(
    customerId: number,
    challengeId: number,
  ): Promise<ChallengePurchase> {
    //todo replace with real data
    //console.log('fetchCustomerChallengePurchases', customerId, challengeId);
    return Promise.resolve(demoPurchase);
  }
}

export const ChallengeAccountInfoComponent = {
  template,
  controller: ChallengeAccountInfoController,
  bindings: {
    challenge: '<',
    customer: '<',
  },
};
