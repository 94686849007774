import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { ContentTemplateTypeCode } from '@proftit/crm.api.models.enums';
import { ContentTemplatesService } from '~/source/common/api-crm-server/services/content-templates.service';
import { SystemEmailTemplatesService } from '~/source/common/api-crm-server/services/system-email-templates.service';
import { ModelNormalizerService } from '~/source/common/services/model-normalizer';
import { shareReplayRefOne, pipeLog } from '@proftit/rxjs.adjunct';
import { switchOn, switchOnEx, generateUuid } from '@proftit/general-utilities';
import {
  ContentTemplate,
  SystemContentTemplate,
  CustomContentTemplate,
} from '@proftit/crm.api.models.entities';

interface LoadAction {
  id: number;
  parentId: number;
  type: ContentTemplateTypeCode;
}

interface NewAction {
  parentId: number;
  type: ContentTemplateTypeCode;
}

enum EmissionType {
  Next,
  Erroring,
  Complete,
}

export class ContentTemplateStore {
  opLoad$ = new rx.Subject<LoadAction>();

  opLoadAsClone$ = new rx.Subject<LoadAction>();

  opGetNew$ = new rx.Subject<NewAction>();

  opCreate$ = new rx.Subject<{
    guid: string;
    template: SystemContentTemplate;
  }>();

  opUpdate$ = new rx.Subject<{
    guid: string;
    template: SystemContentTemplate;
  }>();

  opNotifyDone$ = new rx.Subject<{
    type: EmissionType;
    guid: string;
    payload: any;
  }>();

  _contentTemplate$ = this.streamContentTemplate();

  /* @ngInject */
  constructor(
    readonly contentTemplatesService: () => ContentTemplatesService,
    readonly prfSystemEmailTemplatesService: () => SystemEmailTemplatesService,
    readonly modelNormalizer: ModelNormalizerService,
  ) {}

  get contentTemplate$() {
    return this._contentTemplate$;
  }

  streamContentTemplateFromLoad() {
    return rx.pipe(
      () => this.opLoad$,
      rx.switchMap(({ id, parentId, type }) =>
        this.loadTemplate(id, parentId, type),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamContentTemplateFromLoadAsClone() {
    return rx.pipe(
      () => this.opLoadAsClone$,
      rx.switchMap(({ id, parentId, type }) =>
        this.loadTemplate(id, parentId, type),
      ),
      rx.map((template) =>
        switchOnEx(
          {
            [ContentTemplateTypeCode.Custom]: () =>
              cloneCustomTemplate(template),
            [ContentTemplateTypeCode.System]: () =>
              cloneSystemTemplate(template),
          },
          template.type,
        ),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamContentTemplateFromGetNew() {
    return rx.pipe(
      () => this.opGetNew$,
      rx.map(({ type, parentId }) => {
        return switchOnEx(
          {
            [ContentTemplateTypeCode.Custom]: () =>
              this.generateNewCustomTemplate(),
            [ContentTemplateTypeCode.System]: () =>
              this.generateNewSystemTempalte(parentId),
          },
          type,
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamContentTemplateFromCreate() {
    return observeAndNotifyGuidProcess(
      this.opCreate$,
      this.opNotifyDone$,
      rx.pipe(
        rx.switchMap(({ guid, template }) => {
          return switchOnEx(
            {
              [ContentTemplateTypeCode.Custom]: () =>
                this.createCustomTemplate(template),
              [ContentTemplateTypeCode.System]: () =>
                this.createSystemTemplate(template),
            },
            template.type,
          ).then((newTemplate) => ({
            newTemplate,
            guid,
            system: template.system,
          }));
        }),
        rx.switchMap(({ newTemplate, guid, system }) =>
          this.loadTemplate(
            newTemplate.id,
            _.get(['id'], system),
            newTemplate.type,
          ).then((template) => ({ template, guid })),
        ),
      ),
    );
  }

  streamContentTemplateFromUpdate() {
    return observeAndNotifyGuidProcess(
      this.opUpdate$,
      this.opNotifyDone$,
      rx.pipe(
        rx.switchMap(({ guid, template }) => {
          return switchOnEx(
            {
              [ContentTemplateTypeCode.Custom]: () =>
                this.updateCustomTemplate(template),
              [ContentTemplateTypeCode.System]: () =>
                this.updateSystemTemplate(template),
            },
            template.type,
          ).then((newTemplate) => ({
            newTemplate,
            guid,
            system: template.system,
          }));
        }),
        rx.switchMap(({ newTemplate, guid, system }) =>
          this.loadTemplate(
            newTemplate.id,
            _.get(['id'], system),
            newTemplate.type,
          ).then((template) => ({ template, guid })),
        ),
      ),
    );
  }

  streamContentTemplate(): rx.Observable<ContentTemplate> {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamContentTemplateFromLoad(),
          this.streamContentTemplateFromLoadAsClone(),
          this.streamContentTemplateFromGetNew(),
          this.streamContentTemplateFromCreate(),
          this.streamContentTemplateFromUpdate(),
        ),
      shareReplayRefOne(),
    )(null);
  }

  getOne(id: number, parentId: number, type: ContentTemplateTypeCode) {
    this.opLoad$.next({
      id,
      parentId,
      type,
    });
  }

  clone(id: number, parentId: number, type: ContentTemplateTypeCode) {
    this.opLoadAsClone$.next({
      id,
      parentId,
      type,
    });
  }

  getNew(type: ContentTemplateTypeCode, parentId: number = null) {
    this.opGetNew$.next({
      type,
      parentId,
    });
  }

  create(template: ContentTemplate) {
    return activateGuidAction(
      (guid) =>
        this.opCreate$.next({
          guid,
          template,
        }),
      this.opNotifyDone$,
    );
  }

  update(template: ContentTemplate) {
    return activateGuidAction(
      (guid) =>
        this.opUpdate$.next({
          template,
          guid,
        }),
      this.opNotifyDone$,
    );
  }

  private generateSubTemplate() {
    return {
      id: null,
      designTemplate: null,
      systemId: null,
      language: null,
      isActive: false,
      name: null,
      subject: null,
      content: null,
    };
  }

  private generateNewCustomTemplate() {
    const template = this.generateSubTemplate();

    return {
      ...template,
      type: ContentTemplateTypeCode.Custom,
      systemId: null,
      brands: [],
      departments: [],
      platformType: null,
    };
  }

  private createCustomTemplate(model: ContentTemplate) {
    const modelNorm = _.flow([
      (m) => _.omit(['system'], m),
      (m) => this.modelNormalizer.normalize(m),
    ])(model);

    return this.contentTemplatesService().createItem(modelNorm);
  }

  private createSystemTemplate(model: SystemContentTemplate) {
    const systemId = model.system.id;

    const modelNorm = _.flow([
      (m) => _.omit(['brands', 'departments', 'system'], m),
      (m) => this.modelNormalizer.normalize(m),
    ])(model);

    return this.prfSystemEmailTemplatesService().createTemplate(
      systemId,
      modelNorm,
    );
  }

  private updateSystemTemplate(model: SystemContentTemplate) {
    const id = model.id;
    const systemId = model.system.id;

    const modelNorm = _.flow([
      (m) => _.omit(['id', 'brands', 'departments', 'system'], m),
      (m) => this.modelNormalizer.normalize(m),
    ])(model);

    return this.prfSystemEmailTemplatesService().updateContentTemplate(
      systemId,
      id,
      modelNorm,
    );
  }

  private updateCustomTemplate(model: CustomContentTemplate) {
    console.log('updateCustomTemplate:', model);
    const id = model.id;

    const modelNorm = _.flow([
      (m) => _.omit(['id', 'system'], m),
      (m) => this.modelNormalizer.normalize(m),
    ])(model);

    return this.contentTemplatesService().updateItem(id, modelNorm);
  }

  private generateNewSystemTempalte(systemId: number) {
    const template = this.generateSubTemplate();

    return {
      ...template,
      systemId,
      type: ContentTemplateTypeCode.System,
      brand: null,
    };
  }

  private loadTemplate(
    id: number,
    parentId: number,
    type: ContentTemplateTypeCode,
  ): Promise<ContentTemplate> {
    return switchOnEx(
      {
        [ContentTemplateTypeCode.Custom]: () =>
          this.contentTemplatesService().getFullItem(id),
        [ContentTemplateTypeCode.System]: () =>
          this.prfSystemEmailTemplatesService().getSystemEmailTemplateContentTemplateFull(
            parentId,
            id,
          ),
      },
      type,
    );
  }
}

function observeAndNotifyGuidProcess(trigger$, opNotifyDone$, work$) {
  let guidList = [];

  return rx.pipe(
    () => trigger$,
    rx.tap(({ guid }) => guidList.push(guid)),
    work$,
    rx.tap(({ guid, ...obj }) => {
      guidList = _.reject((i) => i === guid, guidList);

      opNotifyDone$.next({
        guid,
        type: EmissionType.Next,
        payload: obj,
      });
    }),
    rx.map(({ guid, ...obj }) => obj),
    rx.catchError((err) => {
      try {
        guidList.forEach((guid) => {
          opNotifyDone$.next({
            guid,
            type: EmissionType.Erroring,
            payload: err,
          });
        });
      } finally {
        guidList = [];
      }

      throw err;
    }),
  )(null);
}

function activateGuidAction(action: (guid: string) => void, opNotifyDone$) {
  const inGuid = generateUuid();

  action(inGuid);

  return opNotifyDone$.pipe(
    rx.filter(({ guid }) => guid === inGuid),
    rx.tap(({ type, payload }) => {
      if (type === EmissionType.Erroring) {
        throw payload;
      }
    }),
    rx.take(1),
  );
}

function cloneGeneralTemlate(template: ContentTemplate) {
  return _.flow([
    (c) => ({ ...template }),
    (c) => ({
      ...c,
      name: `Copy of ${c.name}`,
    }),
    (c) => _.omit(['id'], c),
    (c) => {
      if (_.get(['designTemplate', 'isActive'], c)) {
        return c;
      }

      return {
        ...c,
        designTemplate: null,
      };
    },
  ])({});
}

function cloneSystemTemplate(template: SystemContentTemplate) {
  return _.flow([
    (c) => cloneGeneralTemlate(c),
    (c) => _.omit(['language'], c),
  ])(template);
}

function cloneCustomTemplate(template: ContentTemplate) {
  return _.flow([(c) => cloneGeneralTemlate(c)])(template);
}
